import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import store                                                     from '../store'

export const useMS = () => {
  const msalConfig = {
    auth: {
      clientId: 'c240d106-636a-443f-ab8d-241d5999aa6e',
      // authority: 'https://login.microsoftonline.com/common/',
      // redirectUri: 'http://192.168.1.12:8080',
      // redirectUri: 'http://localhost:8080',
      redirectUri: 'https://roucarnage.hmt-france.com',
    },
    cache: {
      cacheLocation: 'localStorage',
      // storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          // console.log(level)
          // console.log(message)
        }
      }
    }
  }

  const loginRequest = {
    scopes: [
      'User.Read',
      'mail.read',
      'mail.send',
      'Calendars.ReadWrite',
      'openid',
      'profile',
      'offline_access'
    ]
  }

  const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
    graphMailEndpoint: 'https://graph.microsoft.com/v1.0/me/messages'
  }

  const msalInstance = new PublicClientApplication(msalConfig)

  const login = () => {
    return new Promise((resolve, reject) => {
      msalInstance.loginPopup(loginRequest)
        .then(response => {
          // console.log(response)
          localStorage.setItem('msAccessToken', response.accessToken)
          store.commit('auth/SET_MSACCOUNT', response.account)

          let refreshTokenLocalStorage = Object.keys(localStorage).find(ls=>ls.includes("login.windows.net-refreshtoken"))
          if (refreshTokenLocalStorage !== undefined) {
            let refreshToken = JSON.parse(localStorage.getItem(refreshTokenLocalStorage)).secret
            localStorage.setItem('msRefreshToken', refreshToken)
          } else {
            localStorage.setItem('msRefreshToken', null)
          }

          resolve(response)
        })
        .catch(error => {
          console.error(error)

          resolve(error)
        })
    })

  }

  const logout = () => {
    // console.log(store.getters['auth/getMsAccount'])
    if (store.getters['auth/getMsAccount'] != null || store.getters['auth/getMsAccount'] != 'null') {
      msalInstance.logoutPopup({

        account: msalInstance.getAccountByUsername(store.getters['auth/getMsAccount'].username),
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
        mainWindowRedirectUri: msalConfig.auth.redirectUri
      })
    }

    store.commit('auth/SET_MSACCOUNT', null)
    localStorage.setItem('msAccessToken', null)
    localStorage.setItem('msRefreshToken', null)

    // console.log(Object.keys(localStorage))
    Object.keys(localStorage).forEach(key => {
      if (!['accessToken', 'msAccessToken', 'userData'].includes(key))
        localStorage.removeItem(key)
    })
    // console.log(Object.keys(sessionStorage))
    // Object.keys(sessionStorage).forEach(keyS => {
    //   if (keyS.startsWith("msal")) {
    //     sessionStorage.removeItem(keyS)
    //   }
    // })
  }

  const selectAccount = () => {
    const currentAccounts = msalInstance.getAllAccounts()

    if (currentAccounts.length === 0) {
      return
    } else if (currentAccounts.length > 1) {
      console.warn('Multiple accounts detected.')
    } else if (currentAccounts.length === 1) {
      store.commit('auth/SET_MSACCOUNT', currentAccounts[0])
      // console.log('Welcome ' + currentAccounts[0].username)
    }
  }

  const readMail = () => {
    getTokenPopup()
      .then(response => {
        // console.log(response)
        // console.log("token : ", response.accessToken)

        localStorage.setItem('msAccessToken', response.accessToken)

        callMSGraph('GET', graphConfig.graphMailEndpoint, response.accessToken, mailsCallback)
      }).catch(error => {
      console.error(error)
    })
  }

  const getTokenPopup = () => {
    let request = loginRequest
    request.account = msalInstance.getAccountByUsername(store.getters['auth/getMsAccount'].username)

    return msalInstance.acquireTokenSilent(request)
      .catch(error => {
        console.warn('silent token acquisition fails. acquiring token using popup')
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenPopup(request)
            .then(tokenResponse => {
              console.log(tokenResponse)
              return tokenResponse
            }).catch(error => {
              console.error(error)
            })
        } else {
          console.warn(error)
        }
      })
  }

  const mailsCallback = (data, endpoint) => {
    // console.log(data)
    // console.log(endpoint)
    console.log(data.value)
  }

  const callMSGraph = (method, endpoint, token, callback) => {
    const headers = new Headers()
    const bearer = `Bearer ${token}`

    headers.append('Authorization', bearer)

    const options = {
      method: method,
      headers: headers
    }

    // console.log('request made to Graph API at: ' + new Date().toString())

    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => callback(response, endpoint))
      .catch(error => console.log(error))
  }

  return {
    login,
    logout,
    readMail,
    selectAccount,
    getTokenPopup
  }
}