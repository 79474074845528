import i18n from '../../libs/i18n'

const translateTerms = (vatDeclaration) => {
  if ('paymentMethod' in vatDeclaration) {
    vatDeclaration.paymentMethod.name = i18n.t(vatDeclaration.paymentMethod.name)
  }
}

export default {
  SET_VATDECLARATIONS (state, vatDeclarations) {
    state.vatDeclarations = vatDeclarations
  },
  ADD_VATDECLARATION (state, vatDeclaration) {
    // state.vatDeclarations.push(vatDeclaration)
    state.vatDeclarations.unshift(vatDeclaration)
  },
  UPDATE_VATDECLARATION (state, vatDeclaration) {
    translateTerms(vatDeclaration)
    let index = state.vatDeclarations.findIndex(i => i.id === vatDeclaration.id)
    state.vatDeclarations.splice(index, 1, vatDeclaration)
  },
  REMOVE_VATDECLARATION (state, vatDeclaration) {
    state.vatDeclarations = state.vatDeclarations.filter(item => item.id != vatDeclaration.id)

    // let index = state.vatDeclarations.findIndex(i => i.id === vatDeclaration.id)
    // state.vatDeclarations.splice(index, 1)
  },
  SET_VATDECLARATIONSCONTEXT (state, vatDeclarationsContext) {
    state.vatDeclarationsContext = vatDeclarationsContext
  },
  UPDATE_VATDECLARATIONSCONTEXTTOTALRESULT (state, isAddVatDeclaration) {
    if (isAddVatDeclaration) {
      state.vatDeclarationsContext.total_results++
    } else {
      state.vatDeclarationsContext.total_results--
    }
  },
  SET_AUTOCOMPLETEVATDECLARATIONS (state, autocompleteVatDeclarations) {
    state.autocompleteVatDeclarations = autocompleteVatDeclarations
  },
}