import api                                                 from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend }            from '../../utils/form'
import Vue                                                 from 'vue'
import ToastificationContent                               from '@/components/toastification/ToastificationContent.vue'
import { capitalize }                                      from '@/utils/filter'
import i18n                                                from '@/libs/i18n'
import store                                               from '../index'
import useAppConfig                                        from '../../utils/useAppConfig'
import useAPI                                              from '../../utils/useAPI'
import qs                                                  from 'qs'
import { arrayUtcToTz, clone, reminderToCalendarReminder } from '../../utils/utils'
import router                                              from '../../router'

// datas = {
//   payload: {},
//   options: {
//     saveToStore: true
//   }
// }

export default {
  getReminders ({ commit, state }, datas) {
    store.commit('app/TOGGLE_LOADING', true)

    let payload = datas
    let options = {}
    if ('payload' in datas) {
      payload = datas.payload
      options = datas.options
    }

    return new Promise((resolve, reject) => {
      api.get('/reminders' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data.results)

          if (
            options == null ||
            !('saveToStore' in options) ||
            options.saveToStore != false
          ) {
            commit('SET_REMINDERS', response.data.results)
            commit('SET_REMINDERSCONTEXT', response.data.page_context)
          }
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getReminder ({ commit, state }, reminderId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/reminders/' + reminderId)
        .then(response => {
          arrayUtcToTz([response.data])

          addOrUpdateInList(response.data, state.reminders, 'reminder', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addReminder ({ commit, state }, reminder) {
    delete reminder.subType

    return await cleanEntityToSend(reminder, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/reminders', clonedEntityToSend)
            .then(response => {
              commit('ADD_REMINDER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The reminder has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateReminder ({ commit, state }, reminder) {
    return await cleanEntityToSend(reminder, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/reminders/' + reminder.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_REMINDER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The reminder has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeReminder ({ commit }, reminder) {
    return new Promise((resolve, reject) => {
      api.delete('/reminders/' + reminder.id)
        .then(response => {
          commit('REMOVE_REMINDER', reminder)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The reminder has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          if (
            router.history.current.name == 'WorkFlo view' &&
            clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
          ) {
            store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
          }

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}