import state from './workFloUsageState'
import mutations from './workFloUsageMutations'
import actions from './workFloUsageActions'
import getters from './workFloUsageGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}