import { cleanEntityToSend } from '../../utils/form'
import { capitalize }        from '@/utils/filter'

import Vue                                   from 'vue'
import api                                   from '@/libs/axios'
import ToastificationContent                 from '@/components/toastification/ToastificationContent.vue'
import i18n                                  from '@/libs/i18n'
import store                                 from '../index'
import useAPI                                from '../../utils/useAPI'
import qs                                    from 'qs'
import { useMS }                             from '../../utils/useMS'
import moment                                from 'moment'
import { getRoute, isEmptyObject, parseUTC } from '../../utils/utils'
import { useToast }                          from 'vue-toastification/composition'

const setUtc = (mailFolder) => {

}

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getMailFolders ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/mailFolders' + '?' + qs.stringify(payload))
        .then(response => {

          if (payload.page == null || payload.page == 1) {
            commit('SET_MAILFOLDERS', response.data.results)
            commit('SET_MAILFOLDERSCONTEXT', response.data.page_context)
          } else {
            commit('ADD_MAILFOLDERS', response.data.results)
            commit('SET_MAILFOLDERSCONTEXT', response.data.page_context)
          }

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  },
  getMailFolder ({ commit, state }, mailFolderId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/mailFolders/' + mailFolderId

      performApiCall(url, mailFolderId)
        .then(response => {
          store.commit('mailFolder/UPDATE_MAILFOLDER', response)

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  async addMailFolder ({ commit, state }, mailFolder) {
    setUtc(mailFolder)
    return await cleanEntityToSend(mailFolder, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/mailFolders', clonedEntityToSend)
            .then(response => {
              const toast = useToast()

              commit('ADD_MAILFOLDER', response.data)
              commit('UPDATE_MAILFOLDERSCONTEXTTOTALRESULT', 'add')

              toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The mailFolder has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateMailFolder ({ commit, state }, mailFolder) {
    setUtc(mailFolder)
    return await cleanEntityToSend(mailFolder, state.params)
      .then(async clonedEntityToSend => {
        // console.log(JSON.parse(JSON.stringify(clonedEntityToSend)))

        return new Promise((resolve, reject) => {
          api.put('/mailFolders/' + mailFolder.id, clonedEntityToSend)
            .then(response => {
              const toast = useToast()

              setUtc(response.data)
              commit('UPDATE_MAILFOLDER', response.data)

              toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The mailFolder has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeMailFolder ({ commit, state }, mailFolder) {
    return new Promise((resolve, reject) => {
      api.delete('/mailFolders/' + mailFolder.id)
        .then(response => {
          const toast = useToast()

          commit('REMOVE_MAILFOLDER', mailFolder)
          commit('UPDATE_MAILFOLDERSCONTEXTTOTALRESULT', 'sub')

          toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The mailFolder has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteMailFolders ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/mailFolders'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEMAILFOLDERS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })
  },

}