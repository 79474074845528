import { clone } from '../../utils/utils'

export default {
  getAutocompleteTemplates: (state) => state.autocompleteTemplates,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getTemplates: (state) => state.templates,
  getTemplatesContext: (state) => state.templatesContext,
  getTemplate: (state) => (id) => {
    return state.templates.find(template => template.id === parseInt(id))
  },
  getEmptyTemplate: (state) => clone(state.emptyTemplate),
  // getFieldFilters: (state) => state.fieldFilters,
}