import i18n           from '../../libs/i18n'
import { capitalize } from '../../utils/filter'

export default {
  availableFilters: [
    {
      name: '_supplierInvoice_reconciliationStatus',
      field: '_supplierInvoice_reconciliationStatus',
      i18n: capitalize(i18n.t('reconciliationStatus')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('lettered')),
          value: 'lettered'
        },
        {
          i18n: capitalize(i18n.t('unlettered')),
          value: 'unlettered'
        },
        {
          i18n: capitalize(i18n.t('partiallyLettered')),
          value: 'partially lettered'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      name: '_supplierInvoice_bankReconciliationStatus',
      field: '_supplierInvoice_bankReconciliationStatus',
      i18n: capitalize(i18n.t('bankReconciliationStatus')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('paid')),
          value: 'paid'
        },
        {
          i18n: capitalize(i18n.t('unpaid')),
          value: 'unpaid'
        },
        {
          i18n: capitalize(i18n.t('partiallyPaid')),
          value: 'partially paid'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    // {
    //   name: '_totalAmount_balance',
    //   field: '_totalAmount_balance',
    //   i18n: capitalize(i18n.t('balance')),
    //   subFields: [],
    //   isSpecific: true,
    //   type: 'number',
    // },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'paidBy',
      i18n: capitalize(i18n.t('paidBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'discount',
      i18n: capitalize(i18n.t('discount')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'vat',
      i18n: capitalize(i18n.t('vat')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'billingDate',
      i18n: capitalize(i18n.t('billingDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'billNumber',
      i18n: capitalize(i18n.t('billNumber')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'paymentDate',
      i18n: capitalize(i18n.t('paymentDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'type',
      i18n: capitalize(i18n.t('type')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'paymentMethod',
      i18n: capitalize(i18n.t('paymentMethod')),
      subFields: [],
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('Bank transfert')),
          value: 'Bank transfert'
        },
        {
          i18n: capitalize(i18n.t('Direct debit')),
          value: 'Direct debit'
        },
        {
          i18n: capitalize(i18n.t('Debit card')),
          value: 'Debit card'
        },
        {
          i18n: capitalize(i18n.t('Check')),
          value: 'Check'
        },
        {
          i18n: capitalize(i18n.t('Employee')),
          value: 'Employee'
        },
        {
          i18n: capitalize(i18n.t('Cash')),
          value: 'Cash'
        },
        {
          i18n: capitalize(i18n.t('Gift card')),
          value: 'Gift card'
        },
        {
          i18n: capitalize(i18n.t('Cashback')),
          value: 'Cashback'
        },
        {
          i18n: capitalize(i18n.t('LCR')),
          value: 'LCR'
        },
        {
          i18n: capitalize(i18n.t('Credit note')),
          value: 'Credit note'
        },
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'supplierCompany',
      i18n: capitalize(i18n.t('supplier')),
      subFields: ['name'],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableGlobalAmounts: [
    {
      value: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount'))
    },
    {
      value: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount'))
    },
    {
      value: 'incomingInvoiceBalance',
      i18n: capitalize(i18n.t('balance'))
    }
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  incomingInvoices: [],
  emptyIncomingInvoice: {
    accountingDivisions: [
      {
        accountingDivisionAttachments: [
          {
            workflow: null,
            workFlo: null,
            department: null,
            awarding: '100'
          }
        ],
        accountingLine: 251, // 601
        preTaxAmount: '0',
        vatAmount: '0',
        totalAmount: '0'
      },
      {
        accountingDivisionAttachments: [
          {
            workflow: null,
            workFlo: null,
            department: null,
            awarding: '100'
          }
        ],
        accountingLine: 981, // 4456615
        preTaxAmount: '0',
        vatAmount: '0',
        totalAmount: '0'
      }
    ],
    bankAccount: {
      _display: 'LCL 0000706371A',
      id: 1
    },
    billingDate: (new Date()).toISOString().slice(0, 10),
    paymentDate: (new Date()).toISOString().slice(0, 10),
    paymentMethod: {
      id: 1,
      name: i18n.t('Bank transfert')
    },
    paymentRelations: [],
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
    type: 'default',
    includedContacts: []
  },
  fieldFilters: [
    {
      name: 'billNumber',
      type: 'text',
      filter: {}
    },
    {
      name: 'billingDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'paymentDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'supplierCompany',
      type: 'text',
      entity: 'supplierCompany_name',
      filter: {}
    },
    {
      name: 'preTaxAmount',
      type: 'text',
      filter: {}
    },
    {
      name: 'totalAmount',
      type: 'text',
      filter: {}
    },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('balance')),
      translateTitle: true,
      key: '_balance',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('paymentMethod')),
      translateTitle: true,
      key: 'paymentMethod',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('supplier')),
      translateTitle: true,
      key: 'supplierCompany',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billNumber')),
      translateTitle: true,
      key: 'billNumber',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billingDate')),
      translateTitle: true,
      key: 'billingDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('paymentDate')),
      translateTitle: true,
      key: 'paymentDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('type')),
      translateTitle: true,
      key: 'type',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('preTaxAmount')),
      translateTitle: true,
      key: 'preTaxAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('totalAmount')),
      translateTitle: true,
      key: 'totalAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('export')),
      translateTitle: true,
      key: '_export',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('paidBy')),
      translateTitle: true,
      key: 'paidBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ].sort((a, b) => a.title < b.title ? -1 : 1),
  supplierInvoiceFieldFilters: [
    {
      isQuickFilter: true,
      name: 'supplierReceivable',
      filter: {},
      value: {
        comparator: 'is_not_empty',
        field: 'payments_id'
      }
    },
    {
      name: 'billingDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'billNumber',
      type: 'number',
      filter: {}
    },
    {
      name: 'supplierCompany',
      entity: 'supplierCompany_name',
      type: 'text',
      filter: {}
    },
    {
      name: 'paymentDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'totalAmount',
      type: 'number',
      filter: {}
    },
  ],
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'accountingLine',
      'article',
      'bankAccount',
      'company',
      'department',
      'paymentMethod',
      'phase',
      'receipt',
      'supplierCompany',
      'vatRate',
      'workflow',
      'child',
      'parent',
      'paymentRelations',
      'bankAccount',
      'paidBy',
      'paymentTerm',
      'workFlo'
    ],
    collection: [
      'accountingDivisions',
      'accountingDivisionAttachments',
      'products',
      'includedContacts',
      'bankOperations',
    ]
  },
  incomingInvoicesContext: {
    page: 1,
    filters: []
  }
}