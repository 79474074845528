import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import { taskToCalendarTask} from '../../utils/utils'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getTasks ({ commit }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/tasks'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_TASKS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'task', limit, offset, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            store.commit('app/REMOVE_LOADER')
            resolve(true)
          }
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addTask ({ commit, state }, task) {
    return await cleanEntityToSend(task, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/tasks', clonedEntityToSend)
            .then(response => {
              commit('ADD_TASK', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The task has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateTask ({ commit, state }, task) {
    return await cleanEntityToSend(task, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/tasks/' + task.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_TASK', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The task has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      api.delete('/tasks/' + task.id)
        .then(response => {
          commit('REMOVE_TASK', task)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The task has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}