// import i18n from '@/libs/i18n'

export default {
  SET_BANKSTATEMENTS (state, bankStatements) {
    state.bankStatements = bankStatements
  },
  ADD_BANKSTATEMENT (state, bankStatement) {
    if (bankStatement.repetition != null) {
      setRepetitionDisplay(bankStatement.repetition)
    }
    state.bankStatements.push(bankStatement)
  },
  UPDATE_BANKSTATEMENT (state, bankStatement) {
    if (bankStatement.repetition != null) {
      setRepetitionDisplay(bankStatement.repetition)
    }

    let index = state.bankStatements.findIndex(i => i.id == bankStatement.id)
    state.bankStatements.splice(index, 1, JSON.parse(JSON.stringify(bankStatement)))
  },
  REMOVE_BANKSTATEMENT (state, bankStatement) {
    state.bankStatements = state.bankStatements.filter(item => item.id != bankStatement.id)

    // let index = state.bankStatements.findIndex(i => i.id === bankStatement.id)
    // state.bankStatements.splice(index, 1)
  },
}