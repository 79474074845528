export default {
  SET_USERS (state, users) {
    state.users = users
  },
  ADD_USER (state, user) {
    state.users.push(user)
  },
  UPDATE_USER(state, user) {
    let index = state.users.findIndex(i => i.id === user.id)

    if (index == -1) {
      state.users.push(user)
    } else {
      state.users.splice(index, 1, user)
    }

  },
  REMOVE_USER (state, user) {
    state.users = state.users.filter(item => item.id != user.id)

    // let index = state.users.findIndex(i => i.id === user.id)
    // state.users.splice(index, 1)
  },
  
  SET_AUTOCOMPLETEUSERS (state, autocompleteUsers) {
    state.autocompleteUsers = autocompleteUsers
  },
  // Payload : user, type(company/user)
  ADD_AUTOCOMPLETEUSER (state, payload) {
    state.autocompleteUsers.push(payload)
  },
  // Payload : user, type(company/user)
  UPDATE_AUTOCOMPLETEUSER (state, payload) {
    let index = state.autocompleteUsers.findIndex(i => i.id === payload.user.id)
    state.autocompleteUsers[index]._display = payload.user._display
  },

  SET_AUTOCOMPLETEEMPLOYEES (state, autocompleteEmployees) {
    state.autocompleteEmployees = autocompleteEmployees
  },
  // Payload : user, type(company/user)
  ADD_AUTOCOMPLETEEMPLOYEE (state, payload) {
    state.autocompleteEmployees.push(payload)
  },
  // Payload : user, type(company/user)
  UPDATE_AUTOCOMPLETEEMPLOYEE (state, payload) {
    let index = state.autocompleteEmployees.findIndex(i => i.id === payload.user.id)
    state.autocompleteEmployees[index]._display = payload.user._display
  },
}