import { cleanEntityToSend } from '../../utils/form'
import { capitalize } from '@/utils/filter'

import Vue from 'vue'
import api from '@/libs/axios'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '../index'
import useAPI from '../../utils/useAPI'
import qs from 'qs'
import { useMS } from '../../utils/useMS'
import { isEmptyObject } from '../../utils/utils'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getCompanies ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      if (payload.kanbanSortBy == null) {
        commit('SET_ISKANBANCOMPANYLIST', false)
      } else {
        commit('SET_ISKANBANCOMPANYLIST', true)
      }

      api.get('/companies2' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_COMPANIES', response.data.results)
          commit('SET_COMPANIESCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getCompaniesAddresses ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      if (payload.kanbanSortBy == null) {
        commit('SET_ISKANBANCOMPANYLIST', false)
      } else {
        commit('SET_ISKANBANCOMPANYLIST', true)
      }

      api.get('/companies2Addresses' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_COMPANIES', response.data.results)
          commit('SET_COMPANIESCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getCompany ({ commit, state }, companyId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/companies/' + companyId

      performApiCall(url, companyId)
        .then(response => {
          if (state.isKanbanCompanyList != true) {
            store.commit('company/UPDATE_COMPANY', response)
          }

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getCompanyMails ({ commit, state }, companyId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()
    const { getTokenPopup } = useMS()

    return new Promise((resolve, reject) => {
      let url = '/companies/' + companyId + '/mails'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
        .catch(error => {
          getTokenPopup().then(response => {
            localStorage.setItem('msAccessToken', response.accessToken)
            store.commit('app/REMOVE_LOADER')

            store.dispatch('company/getCompanyMails', companyId)
          })
        })
    })
  },
  async addCompany ({ commit, state }, company) {
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/companies', clonedEntityToSend)
            .then(response => {
              if (state.isKanbanCompanyList != true) {
                commit('ADD_COMPANY', response.data)
              } else {
                let columnIndex = state.companies.findIndex(c => c.title == response.data.status.name)

                state.companies[columnIndex].items.unshift(response.data)
                state.companies.splice(state.companies.length, 0) // Reactive update the array
              }
              commit('UPDATE_COMPANIESCONTEXTTOTALRESULT', 'add')

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateCompany ({ commit, state }, company) {
    delete company.amount
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/companies/' + company.id, clonedEntityToSend)
            .then(response => {
              // console.log(state.isKanbanCompanyList)
              if (state.isKanbanCompanyList != true) {
                commit('UPDATE_COMPANY', response.data)
              }

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeCompany ({ commit, state }, company) {
    return new Promise((resolve, reject) => {
      api.delete('/companies/' + company.id)
        .then(response => {
          if (state.isKanbanCompanyList != true) {
            commit('REMOVE_COMPANY', company)
          }
          commit('UPDATE_COMPANIESCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The company has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteCompanies ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/companies'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETECOMPANIES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
  async sendCompanyMail ({ commit, state }, mail) {
    let mailLocal = JSON.parse(JSON.stringify(mail))
    if (mailLocal.attachments) {
      mailLocal.attachments.forEach(a => delete a._entity)
    }

    return new Promise((resolve, reject) => {
      api.post('/companies/mail', mailLocal)
        .then(response => {
          // console.log(response)

          if (response.status == 201) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('success')),
                icon: 'check',
                text: i18n.t('The email has been sent'),
                variant: 'success'
              },
            })
          }
          resolve(response)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('error')),
              icon: 'times',
              text: i18n.t('The message could not be sent'),
              variant: 'danger'
            },
          })

          // console.log(error.response)

          resolve(error.response)
        })
    })
  },
}