import i18n            from '../../libs/i18n'
import moment          from 'moment'
import { capitalize }            from '../../utils/filter'
import { getUserData, parseUTC } from '../../utils/utils'

// let now = parseUTC(new Date())
// let tomorrow = parseUTC(new Date())
let now = new Date()
let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const startDate = moment().minutes() < 30 ? moment().minutes(0) : moment().minutes(30)
const endDate = moment().minutes() < 30 ? moment().minutes(30) : moment().minutes(0).hours(moment().hours() + 1)
export default {
  autocompleteWorkFlos: [],
  availableFilters: [
    {
      name: '_workFlo_status_date',
      field: '_workFlo_status_date',
      i18n: capitalize(i18n.t('special Kévin')),
      subFields: [],
      isSpecific: true,
      type: 'workFlo_status_date',
    },
    {
      name: '_prospectDate',
      field: '_prospectDate',
      i18n: 'Date de prospect',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_leadDate',
      field: '_leadDate',
      i18n: 'Date de lead',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_analyseDeLaDemandeDate',
      field: '_analyseDeLaDemandeDate',
      i18n: 'Date d\'analyse de la demande',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_offreDate',
      field: '_offreDate',
      i18n: 'Date de l\'offre',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_commandeDate',
      field: '_commandeDate',
      i18n: 'Date de la commande',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_livraisonDate',
      field: '_livraisonDate',
      i18n: 'Date de livraison',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_suiviDate',
      field: '_suiviDate',
      i18n: 'Date de suivi',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_clotureDate',
      field: '_clotureDate',
      i18n: 'Date de cloture',
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    {
      name: '_warnings',
      field: '_warnings',
      i18n: 'Warnings',
      subFields: [],
      isSpecific: true,
      type: 'boolean',
      // booleanAccept: ['yes']
      booleanAllowNull: false
    },
    //{
    //  name: '_workFloNextActivity',
    //  field: '_workFloNextActivity',
    //  i18n: capitalize(i18n.t('nextActivity')),
    //  subFields: [],
    //  isSpecific: true,
    //  type: 'date',
    //},
    {
      name: '_workFloLastActivity',
      field: '_workFloLastActivity',
      i18n: capitalize(i18n.t('lastActivity')),
      subFields: [],
      isSpecific: true,
      type: 'date',
    },
    // {
    //   name: '_workFloLastEvent',
    //   field: '_workFloLastEvent',
    //   i18n: capitalize(i18n.t('lastEvent')),
    //   subFields: [],
    //   isSpecific: true,
    //   type: 'date',
    // },
    // {
    //   name: '_workFloLastReminder',
    //   field: '_workFloLastReminder',
    //   i18n: capitalize(i18n.t('lastReminder')),
    //   subFields: [],
    //   isSpecific: true,
    //   type: 'date',
    // },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'workFloUsages',
      i18n: capitalize(i18n.t('usage')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'source',
      i18n: capitalize(i18n.t('source')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'company',
      i18n: capitalize(i18n.tc('company')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'exhibition',
      i18n: capitalize(i18n.t('exhibition')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'typeOfSale',
      i18n: capitalize(i18n.t('typeOfSale')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'function',
      i18n: capitalize(i18n.t('function')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'typeOfApplication',
      i18n: capitalize(i18n.t('typeOfApplication')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'typeOfPurchase',
      i18n: capitalize(i18n.t('typeOfPurchase')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'isPriority',
      i18n: capitalize(i18n.t('isPriority')),
      subFields: [],
      type: 'boolean',
    },
    {
      field: 'communication',
      i18n: capitalize(i18n.t('communication')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'state',
      i18n: capitalize(i18n.t('state')),
      subFields: [],
      // type: 'text',
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('inProgress')),
          value: 'in progress'
        },
        {
          i18n: capitalize(i18n.t('success')),
          value: 'success'
        },
        {
          i18n: capitalize(i18n.t('lost')),
          value: 'lost'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'stateReason',
      i18n: capitalize(i18n.t('stateReason')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sourceIncomingRequest',
      i18n: capitalize(i18n.t('sourceIncomingRequest')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sector',
      i18n: capitalize(i18n.t('sector')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'distributionType',
      i18n: capitalize(i18n.t('distributionType')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'businessSector',
      i18n: capitalize(i18n.t('businessSector')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'businessSectorOther',
      i18n: capitalize(i18n.t('businessSectorOther')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'isProspecting',
      i18n: capitalize(i18n.t('isProspecting')),
      subFields: [],
      type: 'boolean',
    },
    {
      field: 'sourcePressMedia',
      i18n: capitalize(i18n.t('press/media')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sourceSocialNetwork',
      i18n: capitalize(i18n.t('socialNetwork')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sourceNetwork',
      i18n: capitalize(i18n.t('network')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sourceEvent',
      i18n: capitalize(i18n.tc('event')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'sourceDetails',
      i18n: capitalize(i18n.t('sourceDetails')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'status',
      i18n: capitalize(i18n.t('status')),
      // subFields: [],
      subFields: ['id'],
      type: 'autocompleteSelect',
      autocomplete: {
        store: 'workFloStatus/getAutocompleteWorkFloStatuss',
        label : 'name'
      }
    },
    // {
    //   field: 'status',
    //   i18n: capitalize(i18n.t('status')),
    //   subFields: ['name'],
    //   type: 'text',
    // },
    {
      field: 'prescriberIndividual',
      i18n: capitalize(i18n.t('prescriberIndividual')),
      subFields: ['name'],
      type: 'text',
    },
    {
      field: 'businessIntroducerIndividual',
      i18n: capitalize(i18n.t('businessIntroducerIndividual')),
      subFields: ['name', 'firstName'],
      type: 'text',
    },
    {
      field: 'assignment',
      i18n: capitalize(i18n.t('assignment')),
      subFields: [],
      // subFields: ['name', 'firstName'],
      type: 'employee',
    },
    {
      field: 'mainContact',
      i18n: capitalize(i18n.t('mainContact')),
      subFields: ['name', 'firstName'],
      type: 'text',
    },
    {
      field: 'otherContacts',
      i18n: capitalize(i18n.t('otherContacts')),
      subFields: ['name', 'firstName'],
      type: 'text',
    },
    {
      field: 'estimatedTurnover',
      i18n: capitalize(i18n.t('estimatedTurnover')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'probability',
      i18n: capitalize(i18n.t('probability')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'estimatedClosingDate',
      i18n: capitalize(i18n.t('endOfProject')),
      subFields: [],
      type: 'date',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableMapFilters: [
    {
      field: 'company_addresses',
      i18n: capitalize(i18n.t('isBillingAddress')),
      subFields: ['isBillingAddress'],
      type: 'boolean',
    },
    {
      name: '_workFlo_map_customer_type',
      field: '_workFlo_map_customer_type',
      i18n: capitalize(i18n.t('customerType')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('prospect')),
          value: 'prospect'
        },
        {
          i18n: capitalize(i18n.t('leads')),
          value: 'leads'
        },
        {
          i18n: capitalize(i18n.t('customer')),
          value: 'customer'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'status',
      i18n: capitalize(i18n.t('status')),
      // subFields: [],
      subFields: ['id'],
      type: 'autocompleteSelect',
      autocomplete: {
        store: 'workFloStatus/getAutocompleteWorkFloStatuss',
        label : 'name'
      }
    },
    {
      field: 'assignment',
      i18n: capitalize(i18n.t('assignment')),
      subFields: [],
      type: 'employee'
    },
    {
      field: 'mainAddress',
      i18n: capitalize(i18n.t('mainAddress')),
      subFields: [],
      type: 'text'
    },
    {
      field: 'typeOfPurchase',
      i18n: capitalize(i18n.t('typeOfPurchase')),
      subFields: [],
      type: 'select',
      options: [
        {
          i18n: 'Premier achat',
          value: 'Premier achat'
        },
        {
          i18n: 'Achat récurrent',
          value: 'Achat récurrent'
        },
        {
          i18n: 'Achat post location',
          value: 'Achat post location'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'typeOfSale',
      i18n: capitalize(i18n.t('typeOfSale')),
      subFields: [],
      type: 'select',
      options: [
        {
          i18n: 'Vente directe',
          value: 'Vente directe'
        },
        {
          i18n: 'Vente indirecte',
          value: 'Vente indirecte'
        },
        {
          i18n: 'Autre',
          value: 'Autre'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'businessSector',
      i18n: capitalize(i18n.t('businessSector')),
      subFields: [],
      type: 'select',
      options: [
        {
          value: 'industry',
          i18n: capitalize(i18n.t('industry')),
        },
        {
          value: 'maintenance/servicing',
          i18n: capitalize(i18n.t('maintenance/servicing')),
        },
        {
          value: 'agriculture/agri-food',
          i18n: capitalize(i18n.t('agriculture/agri-food')),
        },
        {
          value: 'public works buildings',
          i18n: capitalize(i18n.t('public works buildings')),
        },
        {
          value: 'logistics',
          i18n: capitalize(i18n.t('logistics')),
        },
        {
          value: 'health',
          i18n: capitalize(i18n.t('health')),
        },
        {
          value: 'other',
          i18n: capitalize(i18n.t('other')),
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'sourceIncomingRequest',
      i18n: capitalize(i18n.t('sourceIncomingRequest')),
      subFields: [],
      type: 'select',
      options: [
        {
          value: 'internet research',
          i18n: capitalize(i18n.t('internetResearch')),
        },
        {
          value: 'social networking',
          i18n: capitalize(i18n.t('socialNetworking')),
        },
        {
          value: 'press',
          i18n: capitalize(i18n.t('press/media')),
        },
        {
          value: 'networks',
          i18n: capitalize(i18n.tc('network',2)),
        },
        {
          value: 'newsletter',
          i18n: capitalize(i18n.t('newsletter')),
        },
        {
          value: 'event',
          i18n: capitalize(i18n.tc('event')),
        },
        {
          value: 'farming',
          i18n: capitalize(i18n.tc('farming')),
        },
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },

  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  currentWorkFlo: {},
  emptyWorkFlo: {
    assignment: getUserData() ? {
      id: getUserData().id,
      _display: getUserData()._display
    } : {},
    isDemo: false,
    isProspecting: false,
    typeOfPurchase: 'Premier achat',
    typeOfSale: 'Vente directe',
    typeOfApplication: 'Normal',
    state: 'in progress',
    status: {
      id: 1,
      name: 'Prospect'
    },
    source: 'reference',
    workFloStatusDates: [
      {
        date: startDate.format('YYYY-MM-DD'),
        workFloStatus: {
          id: 1,
          name: 'Prospect'
        },
        owner: getUserData() == null ? null : getUserData().id
      },
      {
        date: startDate.format('YYYY-MM-DD'),
        workFloStatus: {
          id: 2,
          name: 'Lead'
        },
        owner: getUserData() == null ? null : getUserData().id
      },
      {
        date: null,
        workFloStatus: {
          id: 3,
          name: 'En qualification'
        },
        owner: getUserData() == null ? null : getUserData().id
      }
    ],
    workFloNeeds: [],
    _history: {
      comingSoon: [],
      history: []
    }
  },
  emptyWorkFloTask: {
    type: 'task',
    date: tomorrow.toISOString().slice(0, 10),
    performed: false,
    assignedTo: [getUserData() ? {
      id: getUserData().id,
      _display: getUserData()._display
    } : {}]
  },
  emptyWorkFloNote: {
    _createdBy: getUserData() ? {
      id: getUserData().id,
      _display: getUserData()._display
    } : {}
  },
  emptyWorkFloCall: {
    outgoingCall: true,
    // date: now.toISOString().slice(0, 10) + ' ' + now.toISOString().slice(11,16),
    date: now.toISOString(),
    duration: '00:05:00',
    resultType: 'successful call',
    _createdBy: getUserData() ? {
      id: getUserData().id,
      _display: getUserData()._display
    } : {}
  },
  emptyWorkFloEvent: {
    startDate: startDate.format('YYYY-MM-DD HH:mm'),
    endDate: endDate.format('YYYY-MM-DD HH:mm'),
    mileageAllowanceCost: 0,
    type: 'default',
    startPlace: null,
    endPlace: null,
  },
  emptyWorkFloIntervention: {
    isTripWithPersonalVehicle: false,
    startDate: startDate.format('YYYY-MM-DD HH:mm'),
    endDate: endDate.format('YYYY-MM-DD HH:mm'),
    mileageAllowanceCost: 0,
    type: 'default',
    startPlace: null,
    endPlace: null,
  },
  fieldFilters: [
    // {
    //   name: 'title',
    //   type: 'text',
    //   filter: {}
    // },
    // {
    //   name: 'status',
    //   type: 'list',
    //   data: ['Prospect', 'Lead', '...'],
    //   entity: 'status_name',
    //   filter: {}
    // },
    // {
    //   name: 'createdAt',
    //   type: 'date',
    //   filter: {}
    // },
    // {
    //   name: 'createdBy',
    //   type: 'employee',
    //   multiple: false,
    //   filter: {}
    // },
    // {
    //   name: 'assignment',
    //   type: 'employee',
    //   multiple: false,
    //   filter: {}
    // },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('lastActivity')),
      translateTitle: true,
      key: '_lastActivity',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('lastEvent')),
      translateTitle: true,
      key: '_lastEvent',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('lastReminder')),
      translateTitle: true,
      key: '_lastReminder',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('usage')),
      translateTitle: true,
      key: 'workFloUsages',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de prospect',
      translateTitle: true,
      key: '_prospectDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Warning',
      translateTitle: true,
      key: '_warnings',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de lead',
      translateTitle: true,
      key: '_leadDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date d\'analyse de la demande',
      translateTitle: true,
      key: '_analyseDeLaDemandeDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de l\'offre',
      translateTitle: true,
      key: '_offreDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de la commande',
      translateTitle: true,
      key: '_commandeDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de livraison',
      translateTitle: true,
      key: '_livraisonDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de suivi',
      translateTitle: true,
      key: '_suiviDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'Date de cloture',
      translateTitle: true,
      key: '_clotureDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('title')),
      translateTitle: true,
      key: 'title',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('reasons for closing')),
      translateTitle: true,
      key: 'stateReason',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('source')),
      translateTitle: true,
      key: 'source',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.tc('company')),
      translateTitle: true,
      key: 'company',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('exhibition')),
      translateTitle: true,
      key: 'exhibition',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('typeOfSale')),
      translateTitle: true,
      key: 'typeOfSale',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('typeOfPurchase')),
      translateTitle: true,
      key: 'typeOfPurchase',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('function')),
      translateTitle: true,
      key: 'function',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('typeOfApplication')),
      translateTitle: true,
      key: 'typeOfApplication',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('isPriority')),
      translateTitle: true,
      key: 'isPriority',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('communication')),
      translateTitle: true,
      key: 'communication',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('state')),
      translateTitle: true,
      key: 'state',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('sourceIncomingRequest')),
      translateTitle: true,
      key: 'sourceIncomingRequest',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    // {
    //   title: capitalize(i18n.t('sourceIncomingRequestMore')),
    //   translateTitle: true,
    //   key: 'sourceIncomingRequestMore',
    //   sortable: true,
    //   cell: {
    //     center: false,
    //     useDisplay: false,
    //     isDate: false,
    //     isDateTime: false,
    //     translate: false,
    //     isCurrency: false
    //   }
    // },
    {
      title: capitalize(i18n.t('sector')),
      translateTitle: true,
      key: 'sector',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('distributionType')),
      translateTitle: true,
      key: 'distributionType',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('businessSector')),
      translateTitle: true,
      key: 'businessSector',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('businessSectorOther')),
      translateTitle: true,
      key: 'businessSectorOther',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('isProspecting')),
      translateTitle: true,
      key: 'isProspecting',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('press/media')),
      translateTitle: true,
      key: 'sourcePressMedia',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('socialNetwork')),
      translateTitle: true,
      key: 'sourceSocialNetwork',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('network')),
      translateTitle: true,
      key: 'sourceNetwork',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.tc('event')),
      translateTitle: true,
      key: 'sourceEvent',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('sourceDetails')),
      translateTitle: true,
      key: 'sourceDetails',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status_name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('prescriberIndividual')),
      translateTitle: true,
      key: 'prescriberIndividual_name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('businessIntroducerIndividual')),
      translateTitle: true,
      key: 'businessIntroducerIndividual_name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('assignment')),
      translateTitle: true,
      key: 'assignment',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('mainContact')),
      translateTitle: true,
      key: 'mainContact_name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('estimatedTurnover')),
      translateTitle: true,
      key: 'estimatedTurnover',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('probability')),
      translateTitle: true,
      key: 'probability',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('endOfProject')),
      translateTitle: true,
      key: 'estimatedClosingDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ].sort((a, b) => a.title < b.title ? -1 : 1),
  workFloHistory: { comingSoon: {}, history: {} },
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'article',
      'additionalMembers',
      'assignment',
      'assignedTo',
      'businessIntroducerCompany',
      'businessIntroducerIndividual',
      'company',
      'contact',
      'distributor',
      'documents',
      'linkedContact',
      'mainAddress',
      'mainContact',
      'needs',
      'otherContacts',
      'owner',
      'prescriberCompany',
      'prescriberIndividual',
      'status',
      'workFloStatus',
      'workFloUsages'
    ],
    collection: [
      'workFloNotes',
      'workFloTasks',
      'workFloNotes',
      'workFloCalls',
      'events',
      'interventions',
      'place',
      'startPlace',
      'endPlace',
      'workFloNeeds',
      'workFloStatusDates'
    ]
  },
  workFlos: [],
  workFlosContext: {
    page: 1,
    filters: []
  },
  workFlosAddresses: [],
  workFlosAddressesContext: {
    page: 1,
    filters: []
  }
}