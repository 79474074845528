export default {
  getAvailableFilters: (state) => state.availableFilters,
  getAutocompletePayments: (state) => state.autocompletePayments,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getPayments: (state) => state.payments,
  getPaymentsContext: (state) => state.paymentsContext,
  getPayment: (state) => (id) => {
    return state.payments.find(payment => payment.id === parseInt(id))
  },
  getEmptyPayment: (state) => JSON.parse(JSON.stringify(state.emptyPayment)),
}