export default {
  incomingInvoices: [],
  emptyIncomingInvoice: {
    bankOperations: [],
    billingDate: (new Date()).toISOString().slice(0, 10),
    paymentDate: (new Date()).toISOString().slice(0, 10),
    paymentMethod: 1,
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
    type: 'default',
    includedContacts: []
  },
  params: {
    entity: [
      'article',
      'company',
      'paymentMethod',
      'phase',
      'receipt',
      'supplierCompany',
      'vatRate',
      'workflow',
      'child',
      'parent',
      'paymentRelations',
      'bankAccount',
      'paidBy',
      'paymentTerm'
    ],
    collection: [
      'products',
      'includedContacts',
      'bankOperations',
    ]
  }
}