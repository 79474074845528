export default {
  getAutocompleteWorkflows: (state) => state.autocompleteWorkflows,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getWorkflows: (state) => state.workflows,
  getWorkflowsContext: (state) => state.workflowsContext,
  getWorkflow: (state) => (id) => {
    return state.workflows.find(workflow => workflow.id === parseInt(id))
  },
  getEmptyWorkflow: (state) => JSON.parse(JSON.stringify(state.emptyWorkflow)),
}