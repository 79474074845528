import ability from './ability'

export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
// export const canNavigate = to => to.matched.some(route => {
//   console.log(JSON.parse(localStorage.getItem('userData')))
//   console.log('Auth', ability.can('read', 'Auth'))
//   console.log('Dashboard', ability.can('read', 'Dashboard'))
//   console.log('Contacts', ability.can('read', 'Contacts'))
//   console.log('OutgoingInvoices', ability.can('read', 'OutgoingInvoices'))
//
//   return ability.can(route.meta.action || 'read', route.meta.resource)
// })

export const _ = undefined
