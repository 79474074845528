import i18n from '@/libs/i18n'

const translateTerms = (incomingInvoice) => {
  if ('paymentMethod' in incomingInvoice) {
    incomingInvoice.paymentMethod.name = i18n.t(incomingInvoice.paymentMethod.name)
  }
}


// const translatePaymentsMethodsTerms = (term) => {
//   if ('paymentMethod' in term) {
//     term.paymentMethod.name = i18n.t(term.paymentMethod.name)
//   }
// }

export default {
  SET_INCOMINGINVOICES (state, incomingInvoices) {
    incomingInvoices.forEach(ii => {
      translateTerms(ii)
      // if ('bankOperations' in ii) {
      //   ii.bankOperations.forEach(bo => {
      //     translateTerms(bo)
      //   })
      // }
    })

    state.incomingInvoices = incomingInvoices
  },
  ADD_INCOMINGINVOICE (state, incomingInvoice) {
    translateTerms(incomingInvoice)
    if ('bankOperations' in incomingInvoice) {
      incomingInvoice.bankOperations.forEach(bo => {
        translatePaymentsMethodsTerms(bo)
      })
    }

    state.incomingInvoices.unshift(incomingInvoice)
  },
  UPDATE_INCOMINGINVOICE (state, incomingInvoice) {
    translateTerms(incomingInvoice)
    // translateTerms.bankOperations.forEach(bo => {
    //   translatePaymentsMethodsTerms(bo)
    // })

    let index = state.incomingInvoices.findIndex(i => i.id == incomingInvoice.id)
    state.incomingInvoices.splice(index, 1, JSON.parse(JSON.stringify(incomingInvoice)))
  },
  REMOVE_INCOMINGINVOICE (state, incomingInvoice) {
    state.incomingInvoices = state.incomingInvoices.filter(item => item.id != incomingInvoice.id)

    // let index = state.incomingInvoices.findIndex(i => i.id === incomingInvoice.id)
    // state.incomingInvoices.splice(index, 1)
  },
  SET_INCOMINGINVOICESCONTEXT (state, incomingInvoicesContext) {
    state.incomingInvoicesContext = incomingInvoicesContext
  },
  UPDATE_INCOMINGINVOICESTOTALRESULT (state, isAddIncomingInvoice) {
    if (isAddIncomingInvoice) {
      state.incomingInvoicesContext.total_results++
    } else {
      state.incomingInvoicesContext.total_results--
    }
  },
}