export default {
  SET_CALENDARS(state, val) {
    state.calendars = val
  },
  ADD_CALENDAR (state, calendar) {
    state.calendars.push(calendar)
  },
  SET_SELECTED_CALENDARS(state, val) {
    state.selectedCalendars = val
  },
  SET_EVENTS (state, events) {
    state.events = events
  },
  ADD_EVENT (state, event) {
    state.events.push(event)
  },
  UPDATE_EVENT(state, event) {
    let index = state.events.findIndex(i => i.id == event.id)
    state.events.splice(index, 1, JSON.parse(JSON.stringify(event)))
  },
}