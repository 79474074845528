import state from './exportState';
import mutations from './exportMutations';
import actions from './exportActions';
import getters from "./exportGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}