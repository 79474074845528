import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'
import { clone } from '../../utils/utils'
import router from '../../router'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getIncomingInvoices ({ commit, state }, payload) {
    // const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/incomingInvoices2' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_INCOMINGINVOICES', response.data.results)
          commit('SET_INCOMINGINVOICESCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getIncomingInvoice ({ commit, state }, incomingInvoiceId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/incomingInvoices/'+incomingInvoiceId)
        .then(response => {
          addOrUpdateInList(response.data, state.incomingInvoices, 'incomingInvoice',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addIncomingInvoice ({ commit, state }, incomingInvoice) {
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/incomingInvoices', clonedEntityToSend)
            .then(response => {
              commit('ADD_INCOMINGINVOICE', response.data)
              commit('UPDATE_INCOMINGINVOICESTOTALRESULT', true)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateIncomingInvoice ({ commit, state }, incomingInvoice) {
    // console.log(incomingInvoice)
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {

        // console.log(clonedEntityToSend)

        return new Promise((resolve, reject) => {
          api.put('/incomingInvoices/' + incomingInvoice.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_INCOMINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeIncomingInvoice ({ commit }, incomingInvoice) {
    return new Promise((resolve, reject) => {
      api.delete('/incomingInvoices/' + incomingInvoice.id)
        .then(response => {
          commit('REMOVE_INCOMINGINVOICE', incomingInvoice)
          commit('UPDATE_INCOMINGINVOICESTOTALRESULT', false)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The incoming invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          if (
            router.history.current.name == 'WorkFlo view' &&
            clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
          ) {
            store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
          }

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}