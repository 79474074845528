export default {
  SET_COMPANIES (state, companies) {
    state.companies = companies
  },
  ADD_COMPANY (state, company) {
    state.companies.push(company)
  },
  UPDATE_COMPANY(state, company) {
    let index = state.companies.findIndex(i => i.id === company.id)
    state.companies.splice(index, 1, company)
  },
  REMOVE_COMPANY (state, company) {
    state.companies = state.companies.filter(item => item.id != company.id)

    // let index = state.companies.findIndex(i => i.id === company.id)
    // state.companies.splice(index, 1)
  },
  SET_AUTOCOMPLETECOMPANIES (state, autocompleteCompanies) {
    state.autocompleteCompanies = autocompleteCompanies
  },
  // Payload : contact, type(company/user)
  ADD_AUTOCOMPLETECOMPANY (state, company) {
    state.autocompleteCompanies.unshift(company)
  },
  // Payload : contact, type(company/user)
  UPDATE_AUTOCOMPLETECOMPANY (state, company) {
    let index = state.autocompleteCompanies.findIndex(i => i.id === company.id)
    state.autocompleteCompanies[index]._display = company._display
  },
}