import Vue  from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import accountingLine              from './accountingLine/accountingLine'
import address                     from './address/address'
import app                         from './app/app'
import article                     from './article/article'
import auth                        from './auth/auth'
import bankAccount                 from './bankAccount/bankAccount'
import bankAccount2                from './bankAccount2/bankAccount'
import bankOperation               from './bankOperation/bankOperation'
import bankStatement               from './bankStatement/bankStatement'
import bankTransaction             from './bankTransaction/bankTransaction'
import calendar                    from './calendar/calendar'
import calendar2                   from './calendar2/calendar'
import company                     from './company/company'
import company2                    from './company2/company'
import contact                     from './contact/contact'
import department                  from './department/department'
import document                    from './document/document'
import documentCategory            from './documentCategory/documentCategory'
import email                       from './email/email'
import employeeContractType        from './employeeContractType/employeeContractType'
import event                       from './event/event'
import event2                      from './event2/event'
import event3                      from './event3/event'
import expt                        from './export/export'
import incomingInvoice             from './incomingInvoice/incomingInvoice'
import incomingInvoice2            from './incomingInvoice2/incomingInvoice'
import indicator                   from './indicator/indicator'
import mailFolder                  from './mailFolder/mailFolder'
import moduleView                  from './moduleView/moduleView'
import order                       from './order/order'
import order2                      from './order2/order'
import outgoingInvoice             from './outgoingInvoice/outgoingInvoice'
import outgoingInvoice2            from './outgoingInvoice2/outgoingInvoice'
import payment                     from './payment/payment'
import paymentMethod               from './paymentMethod/paymentMethod'
import paymentTerm                 from './paymentTerm/paymentTerm'
import phase                       from './phase/phase'
import offer                       from './offer/offer'
import offer2                      from './offer2/offer'
import reminder                    from './reminder/reminder'
import salary                      from './salary/salary'
import salary2                     from './salary2/salary'
import socialSecurityContribution  from './socialSecurityContribution/socialSecurityContribution'
import socialSecurityContribution2 from './socialSecurityContribution2/socialSecurityContribution'
import task                        from './task/task'
import task2                       from './task2/task'
import template                    from './template/template'
import ticket                      from './ticket/ticket'
import user                        from './user/user'
import user2                       from './user2/user'
import vat                         from './vat/vat'
import vatDeclaration              from './vatDeclaration/vatDeclaration'
import workFlo                     from './workFlo/workFlo'
import workFloStatus               from './workFloStatus/workFloStatus'
import workFloUsage                from './workFloUsage/workFloUsage'
import workflow                    from './workflow/workflow'

export default new Vuex.Store({
  modules: {
    accountingLine: accountingLine,
    address: address,
    app: app,
    article: article,
    auth: auth,
    bankAccount: bankAccount,
    bankAccount2: bankAccount2,
    bankOperation: bankOperation,
    bankStatement: bankStatement,
    bankTransaction: bankTransaction,
    calendar: calendar,
    calendar2: calendar2,
    company: company,
    company2: company2,
    contact: contact,
    department: department,
    document: document,
    documentCategory: documentCategory,
    email: email,
    employeeContractType: employeeContractType,
    event: event,
    event2: event2,
    event3: event3,
    export: expt,
    incomingInvoice: incomingInvoice,
    incomingInvoice2: incomingInvoice2,
    indicator: indicator,
    mailFolder: mailFolder,
    moduleView: moduleView,
    order: order,
    order2: order2,
    outgoingInvoice: outgoingInvoice,
    outgoingInvoice2: outgoingInvoice2,
    payment: payment,
    paymentMethod: paymentMethod,
    paymentTerm: paymentTerm,
    phase: phase,
    offer: offer,
    offer2: offer2,
    reminder: reminder,
    salary: salary,
    salary2: salary2,
    socialSecurityContribution: socialSecurityContribution,
    socialSecurityContribution2: socialSecurityContribution2,
    task: task,
    task2: task2,
    template: template,
    ticket: ticket,
    user: user,
    user2: user2,
    vat: vat,
    vatDeclaration: vatDeclaration,
    workFlo: workFlo,
    workFloStatus: workFloStatus,
    workFloUsage: workFloUsage,
    workflow: workflow
  }
})