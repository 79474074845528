import i18n   from '../../libs/i18n'
import moment from 'moment'

const translateTerms = (mailFolder) => {
  // if ('mailFolderMethod' in mailFolder) {
  //   mailFolder.mailFolderMethod.name = i18n.t(mailFolder.mailFolderMethod.name)
  // }
}

export default {
  SET_MAILFOLDERS (state, mailFolders) {
    state.mailFolders = mailFolders
  },
  SET_ISKANBANMAILFOLDERLIST (state, value) {
    state.isKanbanMailFolderList = value
  },
  ADD_MAILFOLDER (state, mailFolder) {
    // state.mailFolders.push(mailFolder)
    // setUtc(mailFolder)
    state.mailFolders.unshift(mailFolder)
  },
  ADD_MAILFOLDERS (state, mailFolders) {
    mailFolders.forEach((value, index) => {
      state.mailFolders[index].amount = value.amount
      state.mailFolders[index].items.push(...value.items)
    })
  },
  UPDATE_MAILFOLDER (state, mailFolder) {
    translateTerms(mailFolder)
    // console.log(JSON.parse(JSON.stringify(mailFolder.events[0])))
    // setUtc(mailFolder)
    // console.log(JSON.parse(JSON.stringify(mailFolder.events[0])))

    let index = state.mailFolders.findIndex(i => i.id === mailFolder.id)
    state.mailFolders.splice(index, 1, mailFolder)
  },
  UPDATE_MAILFOLDER_KANBAN (state, mailFolder) {
    translateTerms(mailFolder)
    // setUtc(mailFolder)

    state.mailFolders.forEach((column, columnIndex) => {
      let columnCardIndex = column.items.findIndex(i => i.id == mailFolder.id)

      if (columnCardIndex != -1) {
        state.mailFolders[columnIndex].items.splice(columnCardIndex, 1, mailFolder)
      }
    })
  },
  REMOVE_MAILFOLDER (state, mailFolder) {
    state.mailFolders = state.mailFolders.filter(item => item.id != mailFolder.id)

    // let index = state.mailFolders.findIndex(i => i.id === mailFolder.id)
    // state.mailFolders.splice(index, 1)
  },
  SET_MAILFOLDERSCONTEXT (state, mailFoldersContext) {
    // console.log("ok", mailFoldersContext.fields_filters)
    state.mailFoldersContext = mailFoldersContext

    if (!state.fieldFilters.length) {
      mailFoldersContext.fields_filters.forEach(ff => {
        state.fieldFilters.push({
          name: ff.name,
          type: ff.type,
          entity: ff.relation ? ff.relation : null,
          filter: {}
        })
      })
    }

  },
  UPDATE_MAILFOLDERSCONTEXTTOTALRESULT (state, isAddMailFolder) {
    if (isAddMailFolder) {
      state.mailFoldersContext.total_results++
    } else {
      state.mailFoldersContext.total_results--
    }
  },
  SET_AUTOCOMPLETEMAILFOLDERS (state, autocompleteMailFolders) {
    state.autocompleteMailFolders = autocompleteMailFolders
  },
}