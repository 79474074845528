import { clone } from '../../utils/utils'

export default {
  getAutocompleteAccountingLines: (state) => state.autocompleteAccountingLines,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getAccountingLines: (state) => state.accountingLines,
  getHierarchicalAccountingLines: (state) => state.hierarchicalAccountingLines,
  getAccountingLinesContext: (state) => state.accountingLinesContext,
  getAccountingLine: (state) => (id) => {
    return state.accountingLines.find(accountingLine => accountingLine.id === parseInt(id))
  },
  getEmptyAccountingLine: (state) => clone(state.emptyAccountingLine),
}