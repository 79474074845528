import i18n from '@/libs/i18n'

const translateTerms = (offer) => {
  if ('paymentMethod' in offer) {
    offer.paymentMethod.name = i18n.t(offer.paymentMethod.name)
  }
  if ('paymentTerm' in offer) {
    offer.paymentTerm.term = i18n.t(offer.paymentTerm.term)
  }
}

// const translatePaymentsMethodsTerms = (term) => {
//   if ('paymentMethod' in term) {
//     term.paymentMethod.name = i18n.t(term.paymentMethod.name)
//   }
// }

export default {
  SET_OFFERS (state, offers) {
    offers.forEach(ii => {
      translateTerms(ii)
      // if ('bankOperations' in ii) {
      //   ii.bankOperations.forEach(bo => {
      //     translateTerms(bo)
      //   })
      // }
    })

    state.offers = offers
  },
  ADD_OFFER (state, offer) {
    translateTerms(offer)
    if ('bankOperations' in offer) {
      offer.bankOperations.forEach(bo => {
        bo.paymentMethod = i18n.t(bo.paymentMethod)
      })
    }

    state.offers.unshift(offer)
  },
  UPDATE_OFFER (state, offer) {
    translateTerms(offer)
    // translateTerms.bankOperations.forEach(bo => {
    //   translatePaymentsMethodsTerms(bo)
    // })

    let index = state.offers.findIndex(i => i.id == offer.id)
    state.offers.splice(index, 1, JSON.parse(JSON.stringify(offer)))
  },
  REMOVE_OFFER (state, offer) {
    state.offers = state.offers.filter(item => item.id != offer.id)

    // let index = state.offers.findIndex(i => i.id === offer.id)
    // state.offers.splice(index, 1)
  },
  SET_OFFERSCONTEXT (state, offersContext) {
    state.offersContext = offersContext
  },
  UPDATE_OFFERSTOTALRESULT (state, isAddOffer) {
    if (isAddOffer) {
      state.offersContext.total_results++
    } else {
      state.offersContext.total_results--
    }
  },
}