export default {
  SET_SALARIES (state, salaries) {
    state.salaries = salaries
  },
  ADD_SALARY (state, salary) {
    state.salaries.push(salary)
  },
  UPDATE_SALARY(state, salary) {
    let index = state.salaries.findIndex(i => i.id === salary.id)
    state.salaries.splice(index, 1, salary)
  },
  REMOVE_SALARY (state, salary) {
    state.salaries = state.salaries.filter(item => item.id != salary.id)

    // let index = state.salaries.findIndex(i => i.id === salary.id)
    // state.salaries.splice(index, 1)
  },
}