import { cleanEntityToSend } from '../../utils/form'
import { capitalize } from '@/utils/filter'

import Vue from 'vue'
import api from '@/libs/axios'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '../index'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getWorkFloStatuss ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/workFloStatus' + '?' + qs.stringify(payload))
        .then(response => {
          console.log(response)
          commit('SET_WORKFLOSTATUSS', response.data.results)
          commit('SET_WORKFLOSTATUSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getWorkFloStatus ({ commit, state }, workFloId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/workFloStatus/' + workFloId

      performApiCall(url, workFloId)
        .then(response => {
          store.commit('workFloStatus/UPDATE_WORKFLOSTATUS', response)
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  async addWorkFloStatus ({ commit, state }, workFloStatus) {
    return await cleanEntityToSend(workFloStatus, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/workFloStatus', clonedEntityToSend)
            .then(response => {
              commit('ADD_WORKFLOSTATUS', response.data)
              commit('UPDATE_WORKFLOSTATUSCONTEXTTOTALRESULT', 'add')

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workFloStatus has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateWorkFloStatus ({ commit, state }, workFloStatus) {
    delete workFloStatus.amount
    return await cleanEntityToSend(workFloStatus, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/workFloStatus/' + workFloStatus.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_WORKFLOSTATUS', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workFloStatus has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeWorkFloStatus ({ commit }, workFloStatus) {
    return new Promise((resolve, reject) => {
      api.delete('/workFloStatus/' + workFloStatus.id)
        .then(response => {
          commit('REMOVE_WORKFLOSTATUS', workFloStatus)
          commit('UPDATE_WORKFLOSTATUSCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The workFloStatus has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteWorkFloStatuss ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/workFloStatus'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEWORKFLOSTATUSS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
}