import { capitalize } from '../../utils/filter'
import i18n from '../../libs/i18n'

export default {
  availableFilters: [
    {
      name: '_customerInvoice_reconciliationStatus',
      field: '_customerInvoice_reconciliationStatus',
      i18n: capitalize(i18n.t('reconciliationStatus')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('lettered')),
          value: 'lettered'
        },
        {
          i18n: capitalize(i18n.t('unlettered')),
          value: 'unlettered'
        },
        {
          i18n: capitalize(i18n.t('partiallyLettered')),
          value: 'partially lettered'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      name: '_customerInvoice_bankReconciliationStatus',
      field: '_customerInvoice_bankReconciliationStatus',
      i18n: capitalize(i18n.t('bankReconciliationStatus')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('paid')),
          value: 'paid'
        },
        {
          i18n: capitalize(i18n.t('unpaid')),
          value: 'unpaid'
        },
        {
          i18n: capitalize(i18n.t('partiallyPaid')),
          value: 'partially paid'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      name: '_customerInvoice_workFlo_typeOfSale',
      field: '_customerInvoice_workFlo_typeOfSale',
      i18n: capitalize(i18n.t('typeOfSale')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('Vente directe')),
          value: 'Vente directe'
        },
        {
          i18n: capitalize(i18n.t('Vente indirecte')),
          value: 'Vente indirecte'
        },
        {
          i18n: capitalize(i18n.t('Autre')),
          value: 'Autre'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      name: '_customerInvoice_workFlo_typeOfPurchase',
      field: '_customerInvoice_workFlo_typeOfPurchase',
      i18n: capitalize(i18n.t('typeOfPurchase')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('Premier achat')),
          value: 'Premier achat'
        },
        {
          i18n: capitalize(i18n.t('Achat récurrent')),
          value: 'Achat récurrent'
        },
        {
          i18n: capitalize(i18n.t('Achat post location')),
          value: 'Achat post location'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'discount',
      i18n: capitalize(i18n.t('discount')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'vat',
      i18n: capitalize(i18n.t('vat')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'billingDate',
      i18n: capitalize(i18n.t('billingDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'billNumber',
      i18n: capitalize(i18n.t('billNumber')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'customerCompany',
      i18n: capitalize(i18n.t('company')),
      subFields: ['name'],
      type: 'text',
    },
    {
      field: 'customerIndividual',
      i18n: capitalize(i18n.t('individual')),
      subFields: ['name', 'firstName'],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableGlobalAmounts: [
    {
      value: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount'))
    },
    {
      value: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount'))
    },
    // {
    //   value: 'incomingInvoiceBalance',
    //   i18n: capitalize(i18n.t('balance'))
    // }
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  outgoingInvoices: [],
  emptyOutgoingInvoice: {
    bankAccount: { id: 1 },
    billingDate: (new Date()).toISOString().slice(0, 10),
    paymentMethod: 1,
    paymentRelations: [],
    paymentTerm: 3,
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  fieldFilters: [
    {
      name: 'billNumber',
      type: 'text',
      filter: {}
    },
    {
      name: 'billingDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'company',
      type: 'text',
      entity: 'customerCompany_name',
      filter: {}
    },
    {
      name: 'individual',
      type: 'text',
      entity: 'customerIndividual_name',
      filter: {}
    },
    {
      name: 'preTaxAmount',
      type: 'text',
      filter: {}
    },
    {
      name: 'totalAmount',
      type: 'text',
      filter: {}
    },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billingDate')),
      translateTitle: true,
      key: 'billingDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billNumber')),
      translateTitle: true,
      key: 'billNumber',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('customer')),
      translateTitle: true,
      key: 'customerCompany',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('title')),
      translateTitle: true,
      key: 'title',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('preTaxAmount')),
      translateTitle: true,
      key: 'preTaxAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('totalAmount')),
      translateTitle: true,
      key: 'totalAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('export')),
      translateTitle: true,
      key: 'export',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ].sort((a, b) => a.title < b.title ? -1 : 1),
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'article',
      'bankAccount',
      'bankOperations',
      'billingAddress',
      'billingCompany',
      'customerCompany',
      'customerIndividual',
      'deliveryAddress',
      'parent',
      'paymentTerm',
      'paymentRelations',
      'paymentMethod',
      'phase',
      'vatRate',
      'workflow',
      'customerPurchaseOrder',
      'offer',
      'bankAccount',
      'incomingInvoices',
      'workFlo'
    ],
    collection: [
      'products',
    ]
  },
  outgoingInvoicesContext: {
    page: 1,
    filters: []
  }
}