export default [
  // Banking flows
  {
    path: '/accounting_dashboard',
    name: 'AccountingDashboard',
    component: () => import('../../views/accounting2/dashboard/Dashboard'),
    meta: {
      resource: 'AccountingDashboard',
      action: 'manage',
    }
  },
  // Customer receivable
  {
    path: '/customer_receivable',
    name: 'CustomerReceivable3',
    component: () => import('../../views/accounting2/customerReceivable/CustomerReceivables3.vue'),
    meta: {
      resource: 'CustomerReceivables',
      action: 'manage',
    }
  },
  // Supplier receivable
  {
    path: '/supplier_receivable',
    name: 'SupplierReceivable3',
    component: () => import('../../views/accounting2/supplierReceivable/SupplierReceivable3'),
    meta: {
      resource: 'SupplierReceivables',
      action: 'manage',
    }
  },
  // Bank accounts
  {
    path: '/bank_accounts',
    name: 'BankAccounts',
    component: () => import('../../views/accounting2/bankAccounts/BankAccounts'),
    meta: {
      resource: 'BankAccounts',
      action: 'manage',
    }
  },
  // Vat declaration
  {
    path: '/vat_declarations',
    name: 'AccountingVatDeclarations',
    component: () => import('../../views/accounting2/vatDeclaration/VatDeclaration.vue'),
    meta: {
      resource: 'AccountingVatDeclarations',
      action: 'manage',
    }
  },
  // Exports
  {
    path: '/accounting_exports',
    name: 'AccountingExports',
    component: () => import('../../views/accounting2/exports/Exports2.vue'),
    meta: {
      resource: 'AccountingExports',
      action: 'manage',
    }
  }
]
