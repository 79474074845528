import i18n from '../../libs/i18n'

const translate = (paymentMethod) => {
  if ('name' in paymentMethod) {
    paymentMethod.name = i18n.t(paymentMethod.name)
  }
}
export default {
  SET_PAYMENTMETHODS (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },

  SET_AUTOCOMPLETEPAYMENTMETHODS (state, autocompletePaymentMethods) {
    autocompletePaymentMethods.forEach(pm => {
      pm = translate(pm)
    })
    state.autocompletePaymentMethods = autocompletePaymentMethods
  },
  // Payload : paymentMethod
  ADD_AUTOCOMPLETEPAYMENTMETHOD (state, payload) {
    state.autocompletePaymentMethods.push(payload)
  },
  // Payload : paymentMethod
  UPDATE_AUTOCOMPLETEPAYMENTMETHOD (state, payload) {
    console.log(payload)
    let index = state.autocompletePaymentMethods.findIndex(i => i.id === payload.paymentMethod.id)
    state.autocompletePaymentMethods[index].name = payload.paymentMethod.name
  },
}