<template>
  <div class="toastification">
    <div class="d-flex">
      <div>
        <b-avatar
            :variant="variant"
            size="1.8rem"
            class="mr-75 flex-shrink-0"
        >
          <icon
              :icon="icon"
          />
        </b-avatar>
      </div>
      <div class="text-truncate">
        <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title text-truncate"
            :class="`text-${variant}`"
            v-text="title"
        />
        <small
            v-if="text"
            class="d-inline-block text-body w-100 text-truncate"
            v-text="text"
        />
      </div>
      <div
          v-if="!hideClose"
          class="ml-50"
      >
        <span
            class="cursor-pointer toastification-close-icon ml-auto "
            @click="$emit('close-toast')"
        >
          <icon
              icon="times"
              class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style
    lang="scss"
    scoped
>
.Vue-Toastification__toast-component-body {
  width: 100%;
}

.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
