export default {
  SET_EVENTS (state, events) {
    state.events = events
  },
  ADD_EVENT (state, event) {
    state.events.unshift(event)
  },
  UPDATE_EVENT (state, event) {
    let index = state.events.findIndex(i => i.id == event.id)
    state.events.splice(index, 1, JSON.parse(JSON.stringify(event)))
  },
  REMOVE_EVENT (state, event) {
    state.events = state.events.filter(item => item.id != event.id)

    // let index = state.events.findIndex(i => i.id === event.id)
    // state.events.splice(index, 1)
  },
  SET_EVENTSCONTEXT (state, eventsContext) {
    state.eventsContext = eventsContext
  },
  UPDATE_EVENTSTOTALRESULT (state, isAddEvent) {
    if (isAddEvent) {
      state.eventsContext.total_results++
    } else {
      state.eventsContext.total_results--
    }
  },
}