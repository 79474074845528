export default {
  getCalendars: (state) => {
    return state.calendars
  },
  getSelectedCalendars: (state) => {
    return state.selectedCalendars
  },
  async getEvents(state) {
    return new Promise((resolve, reject) => {
      resolve(state.events.filter(e => state.selectedCalendars.some(f=> e.extendedProps.calendars.includes(f))))
    })
  },
}