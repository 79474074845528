export default {
  getAutocompleteWorkFloStatuss: (state) => state.autocompleteWorkFloStatuss,
  getWorkFloStatuss: (state) => state.workFloStatuss,
  getWorkFloStatussContext: (state) => state.workFloStatussContext,
  getWorkFloStatus: (state) => (id) => {
    return state.workFloStatuss.find(workFloStatus => workFloStatus.id === parseInt(id))
  },
  getWorkFloStatusByName: (state) => (name) => {
    return state.autocompleteWorkFloStatuss.find(workFloStatus => workFloStatus.name === name)
  },
  getEmptyWorkFloStatus: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloStatus)),
}