import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getTickets ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/tickets'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_TICKETS', response.results)
          commit('SET_TICKETSCONTEXT', response.page_context)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })
  },
  // getTickets ({ commit, state }, payload) {
  //   store.commit('app/TOGGLE_LOADING', true)
  //
  //   const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
  //   let limit = payload.limit
  //   let offset = 0
  //
  //   return new Promise((resolve, reject) => {
  //     let url = '/tickets'
  //     let params = new URLSearchParams({ limit: limit, offset: offset })
  //
  //     api.get(url + '?' + params)
  //       .then(response => {
  //         commit('SET_TICKETS', response.data)
  //
  //         if (response.data.length == limit) {
  //           offset += limit
  //           limit = recursiveCallIterativeLimit
  //           recursiveCall(url, 'ticket', limit, offset, state.tickets, commit, 1).then(() => {
  //             store.commit('app/TOGGLE_LOADING', false)
  //             resolve(true)
  //           })
  //         } else {
  //           store.commit('app/TOGGLE_LOADING', false)
  //           resolve(true)
  //         }
  //
  //       })
  //       .catch(err => {
  //         console.log('Error', err)
  //         reject(err)
  //       })
  //   })
  // },
  async addTicket ({ commit, state }, ticket) {
    return await cleanEntityToSend(ticket, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/tickets', clonedEntityToSend)
            .then(response => {
              commit('ADD_TICKET', response.data)
              commit('UPDATE_TICKETSCONTEXTTOTALRESULT', true)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The ticket has been ', {crud: i18n.t('created')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateTicket ({ commit, state }, ticket) {
    return await cleanEntityToSend(ticket, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/tickets/' + ticket.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_TICKET', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The ticket has been ', {crud: i18n.t('edited')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeTicket ({ commit }, ticket) {
    return new Promise((resolve, reject) => {
      api.delete('/tickets/' + ticket.id)
        .then(response => {
          commit('REMOVE_TICKET', ticket)
          commit('UPDATE_TICKETSCONTEXTTOTALRESULT', false)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The ticket has been ', {crud: i18n.t('removed')}),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getTicketsIndicator ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    // console.log(payload)

    let url = '/indicators/tickets'
    let dates = payload.dates
    let params = new URLSearchParams({
      // start: dates.start,
      // end: dates.end
    })

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_TICKETSINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}