import state from './reminderState';
import mutations from './reminderMutations';
import actions from './reminderActions';
import getters from "./reminderGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}