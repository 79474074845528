export default {
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getAddresses: (state) => state.addresses,
  getIsKanbanCompanyList: (state) => state.isKanbanCompanyList,
  getAddressesContext: (state) => state.addressesContext,
  getAddress: (state) => (id) => {
    return state.addresses.find(address => address.id === parseInt(id))
  },
  getEmptyAddress: (state) => JSON.parse(JSON.stringify(state.emptyAddress)),
  getCountries: (state) => state.countries,
  getCountry: (state) => (countryCode) => {
    return state.countries.find(country => country.code === countryCode).country
  },
  // getAddresses: (state) => state.addresses,
  // getAddress: (state) => (id) => {
  //   return state.addresses.find(address => address.id === id)
  // },
  // getEmptyAddress: (state) => state.emptyAddress,
  // getEmployees: (state) => {
  //   return state.addresses.filter(address => address.enabled && address.calculatedCurrentContract)
  // },
  // getEmployeesAndPrevious: (state) => {
  //   return state.addresses.filter(address => address.company.id == 1)
  // },
}