import i18n                      from '../../libs/i18n'
import moment                    from 'moment'
import { capitalize }            from '../../utils/filter'
import { getUserData, parseUTC } from '../../utils/utils'

console.log(getUserData())

export default {
  autocompleteEmails: [],
  availableFilters: [].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  emptyEmail: {
    toRecipients: [],
    ccRecipients: [],
    bccRecipients: [],
    attachments: [],
    importance: 'normal',
    hasLinkTracking: false,
    parent: null,
    subject: null,
    body: {
      contentType: 'html',
      content: getUserData() != null ? getUserData().mailSignature : ''
    }
  },
  fieldFilters: [],
  availableListColumns: [].sort((a, b) => a.title < b.title ? -1 : 1),
  isKanbanEmailList: false,
  params: {
    entity: [
      'attachments',
      'folder',
      'parentEmail'
    ],
    collection: []
  },
  emails: [],
  emailsContext: {
    page: 1,
    filters: []
  }
}