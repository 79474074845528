import state from './vatDeclarationState';
import mutations from './vatDeclarationMutations';
import actions from './vatDeclarationActions';
import getters from "./vatDeclarationGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}