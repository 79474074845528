import state from './phaseState';
import mutations from './phaseMutations';
import actions from './phaseActions';
import getters from "./phaseGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}