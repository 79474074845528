export default [
  {
    path: '/indicators',
    name: 'Indicators',
    component: () => import('@/views/indicators/Indicators.vue'),
    meta: {
      resource: 'Indicators',
      action: 'manage',
    }
  },
  // {
  //   path: '/dashboard/new',
  //   name: 'new dashboard',
  //   component: () => import('@/views/dashboard/New.vue'),
  // },
]
