import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'

export default {
  autocompleteCompanies: [],
  emptyCompany: {
    employees: [],
    addresses: []
  },
  fieldFilters: [
    {
      name: 'name',
      type: 'text',
      filter: {}
    },
    {
      name: 'siren',
      type: 'text',
      filter: {}
    },
  ],
  availableFilters: [

  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableMapFilters: [
    {
      field: 'addresses',
      i18n: capitalize(i18n.t('isBillingAddress')),
      subFields: ['isBillingAddress'],
      type: 'boolean',
    },

  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    {
      title: 'company',
      translateTitle: true,
      key: 'name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'siren',
      translateTitle: true,
      key: 'siren',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ],
  isKanbanCompanyList: false,
  params: {
    entity: [
      'avatar',
      'outgoingInvoices',
      'billingAddress',
      'deliveryAddress',
      'type'

    ],
    collection: [
      'addresses',
      'employees',
      'contracts'
    ]
  },
  companies: [],
  companiesContext: {
    page: 1,
    filters: []
  }
}