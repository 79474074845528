import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import api from '@/libs/axios'
import { accessToken } from '../../libs/axios'

export default {
  LOGIN (state, userData) {
    localStorage.setItem('accessToken', userData.token)
    localStorage.setItem('userData', JSON.stringify(userData.userData))

    // state.accessToken = userData.token
    // state.userData = JSON.stringify(userData.userData)

    api.defaults.headers.Authorization = 'Bearer ' + userData.token
    api.defaults.timeout = 14300
    ability.update(userData.userData.ability)
  },
  LOGOUT (state) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('msAccessToken')
    localStorage.removeItem('userData')

    // state.accessToken = null
    // state.userData = null

    api.defaults.headers.Authorization = null
    api.defaults.timeout = 0
    ability.update(initialAbility)
  },
  SET_MSACCOUNT (state, msAccount) {
    state.msAccount = msAccount
  }
}