import moment from 'moment'

export default {
  getAvailableFilters: (state) => state.availableFilters,
  getOrders: (state) => state.orders,
  getOrdersContext: (state) => state.ordersContext,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getOrder: (state) => (id) => {
    return state.orders.find(order => order.id === parseInt(id))
  },
  getEmptyOrder: (state) => JSON.parse(JSON.stringify(state.emptyOrder)),

}