import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getIncomingInvoices ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/incomingInvoices'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_INCOMINGINVOICES', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'incomingInvoice', limit, offset, state.incomingInvoices, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            store.commit('app/REMOVE_LOADER')
            resolve(true)
          }
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getIncomingInvoice ({ commit, state }, incomingInvoiceId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/incomingInvoices/'+incomingInvoiceId)
        .then(response => {
          addOrUpdateInList(response.data, state.incomingInvoices, 'incomingInvoice',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addIncomingInvoice ({ commit, state }, incomingInvoice) {
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/incomingInvoices', clonedEntityToSend)
            .then(response => {
              commit('ADD_INCOMINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateIncomingInvoice ({ commit, state }, incomingInvoice) {
    // console.log(incomingInvoice)
    return await cleanEntityToSend(incomingInvoice, state.params)
      .then(async clonedEntityToSend => {

        // console.log(clonedEntityToSend)

        return new Promise((resolve, reject) => {
          api.put('/incomingInvoices/' + incomingInvoice.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_INCOMINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeIncomingInvoice ({ commit }, incomingInvoice) {
    return new Promise((resolve, reject) => {
      api.delete('/incomingInvoices/' + incomingInvoice.id)
        .then(response => {
          commit('REMOVE_INCOMINGINVOICE', incomingInvoice)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The incoming invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}