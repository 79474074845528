import { capitalize } from '../../utils/filter'
import i18n from '../../libs/i18n'

export default {
  autocompleteWorkflows: [],
  workflows: [],
  workflowsContext: {
    page: 1,
    filters: []
  },
  emptyWorkflow: {
    events: [],
    incomingInvoices: [],
    outgoingInvoices: [],
    offers: [],
    opportunityDate: (new Date()).toISOString().slice(0, 10),
    // startDate: (new Date()).toISOString().slice(0, 10),
  },
  fieldFilters: [
    {
      name: 'opportunity',
      type: 'text',
      filter: {}
    },
    {
      name: 'opportunityDate',
      type: 'date',
      filter: {}
    },
    // {
    //   name: 'status',
    //   type: 'list',
    //   data: ['Prospect', 'Lead', '...'],
    //   entity: 'status_name',
    //   filter: {}
    // },
    // {
    //   name: 'createdBy',
    //   type: 'employee',
    //   multiple: false,
    //   filter: {}
    // },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('opportunity')),
      translateTitle: true,
      key: 'opportunity',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('opportunityDate')),
      translateTitle: true,
      key: 'opportunityDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('contact')),
      translateTitle: true,
      key: 'contact',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('_lastModifications')),
      translateTitle: true,
      key: '_lastModifications',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ],
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'events',
      'customerCompany',
      'customerIndividual',
      'incomingInvoices',
      'outgoingInvoices',
      'quotes',
      'assignedTo',
      'offers',
      'orders',
      'files',
      'responsible',
      'tasks'
    ],
    collection: [
      'activities',
      'notes',
      'phases'
    ]
  }
}