import i18n from '@/libs/i18n'

const translateTerms = (order) => {
  if ('paymentMethod' in order) {
    order.paymentMethod.name = i18n.t(order.paymentMethod.name)
  }
  if ('paymentTerm' in order) {
    order.paymentTerm.term = i18n.t(order.paymentTerm.term)
  }
}


// const translatePaymentsMethodsTerms = (term) => {
//   if ('paymentMethod' in term) {
//     term.paymentMethod.name = i18n.t(term.paymentMethod.name)
//   }
// }

export default {
  SET_ORDERS (state, orders) {
    orders.forEach(ii => {
      translateTerms(ii)
      // if ('bankOperations' in ii) {
      //   ii.bankOperations.forEach(bo => {
      //     translateTerms(bo)
      //   })
      // }
    })

    state.orders = orders
  },
  ADD_ORDER (state, order) {
    translateTerms(order)
    if ('bankOperations' in order) {
      order.bankOperations.forEach(bo => {
        bo.paymentMethod=i18n.t(bo.paymentMethod)
      })
    }

    state.orders.unshift(order)
  },
  UPDATE_ORDER (state, order) {
    translateTerms(order)
    // translateTerms.bankOperations.forEach(bo => {
    //   translatePaymentsMethodsTerms(bo)
    // })

    let index = state.orders.findIndex(i => i.id == order.id)
    state.orders.splice(index, 1, JSON.parse(JSON.stringify(order)))
  },
  REMOVE_ORDER (state, order) {
    state.orders = state.orders.filter(item => item.id != order.id)

    // let index = state.orders.findIndex(i => i.id === order.id)
    // state.orders.splice(index, 1)
  },
  SET_ORDERSCONTEXT (state, ordersContext) {
    state.ordersContext = ordersContext
  },
  UPDATE_ORDERSTOTALRESULT (state, isAddOrder) {
    if (isAddOrder) {
      state.ordersContext.total_results++
    } else {
      state.ordersContext.total_results--
    }
  },
}