export default [
  {
    path: '/contact/companies',
    name: 'contactCompanies',
    component: () => import('../../views/contacts2/contactCompanies/ContactCompanies.vue'),
    meta: {
      resource: 'ContactCompanies',
      action: 'read',
    }
  },
  {
    path: '/contact/users',
    name: 'contactUsers',
    component: () => import('../../views/contacts2/contactUsers/ContactUsers3.vue'),
    meta: {
      resource: 'ContactUsers',
      action: 'read',
    }
  },
  {
    path: '/contact/map',
    name: 'contactsMap',
    component: () => import('../../views/contacts2/contactMap/ContactsMap.vue'),
    meta: {
      resource: 'contactsMap',
      action: 'read',
    }
  },
]
