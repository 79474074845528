import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  getWorkflows ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      if (payload.kanbanSortBy == null) {
        commit('SET_ISKANBANWORKFLOLIST', false)
      } else {
        commit('SET_ISKANBANWORKFLOLIST', true)
      }

      api.get('/workflows' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_WORKFLOWS', response.data.results)
          commit('SET_WORKFLOWSCONTEXT', response.data.page_context)

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  },
  getAutocompleteWorkflows ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // if (payload.kanbanSortBy == null) {
      //   commit('SET_ISKANBANUSERLIST', false)
      // } else {
      //   commit('SET_ISKANBANUSERLIST', true)
      // }

      api.get('/autocomplete/workflows' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_AUTOCOMPLETEWORKFLOWS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  }
  ,
  getWorkflow ({ commit, state }, workflowId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/workflows/' + workflowId)
        .then(response => {
          addOrUpdateInList(response.data, state.workflows, 'workflow', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  }
  ,
  async addWorkflow ({ commit, state }, workflow) {
    return await cleanEntityToSend(workflow, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/workflows', clonedEntityToSend)
            .then(response => {
              commit('ADD_WORKFLOW', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workflow has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  }
  ,
  async updateWorkflow ({ commit, state }, workflow) {
    return await cleanEntityToSend(workflow, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/workflows/' + workflow.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_WORKFLOW', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workflow has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  }
  ,
  removeWorkflow ({ commit }, workflow) {
    return new Promise((resolve, reject) => {
      api.delete('/workflows/' + workflow.id)
        .then(response => {
          commit('REMOVE_WORKFLOW', workflow)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The workflow has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  }
  ,

}