export default [
  // Orders
  {
    path: '/workflows',
    name: 'Workflows',
    component: () => import('@/views/workflows/Workflows.vue'),
    meta: {
      resource: 'Workflows',
      // action: 'read',
    }
  },
  {
    path: '/workflows/:workflowId',
    name: 'Workflow view',
    component: () => import('@/views/workflows/WorkflowView.vue'),
    meta: {
      navActiveLink: 'Workflows',
      resource: 'Workflows',
      // action: 'read',
    }
  },
]
