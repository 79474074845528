import i18n from '../../libs/i18n'

const translateTerms = (company) => {
  // if ('companyMethod' in company) {
  //   company.companyMethod.name = i18n.t(company.companyMethod.name)
  // }
}

export default {
  SET_COMPANIES (state, companies) {
    state.companies = companies
  },
  SET_ISKANBANCOMPANYLIST (state, value) {
    state.isKanbanCompanyList = value
  },
  ADD_COMPANY (state, company) {
    // state.companies.push(company)
    state.companies.unshift(company)
  },
  UPDATE_COMPANY (state, company) {
    translateTerms(company)
    let index = state.companies.findIndex(i => i.id === company.id)
    state.companies.splice(index, 1, company)
  },
  REMOVE_COMPANY (state, company) {
    state.companies = state.companies.filter(item => item.id != company.id)

    // let index = state.companies.findIndex(i => i.id === company.id)
    // state.companies.splice(index, 1)
  },
  SET_COMPANIESCONTEXT (state, companiesContext) {
    state.companiesContext = companiesContext
  },
  UPDATE_COMPANIESCONTEXTTOTALRESULT (state, isAddCompany) {
    if (isAddCompany) {
      state.companiesContext.total_results++
    } else {
      state.companiesContext.total_results--
    }
  },
  SET_AUTOCOMPLETECOMPANIES (state, autocompleteCompanies) {
    state.autocompleteCompanies = autocompleteCompanies
  },
}