import { getUserData } from '../../utils/utils'

export default {
  getAvailableFilters: (state) => state.availableFilters,
  getEmployeeAvailableFilters: (state) => state.availableEmployeeFilters,
  getAutocompleteUsers: (state) => state.autocompleteUsers,
  getAutocompleteEmployees: (state) => state.autocompleteEmployees,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getUsers: (state) => state.users,
  getIsKanbanUserList: (state) => state.isKanbanUserList,
  getUsersContext: (state) => state.usersContext,
  getUser: (state) => (id) => {
    console.log(state.users)
    return state.users.find(user => user.id === parseInt(id))
  },
  getEmptyUser: (state) => JSON.parse(JSON.stringify(state.emptyUser)),
  getMe: (state) => {
    // return state.users.find(user => user.id == parseInt(JSON.parse(localStorage.getItem('userData')).id))
    return state.users.find(user => user.id == getUserData().id)
  },
}