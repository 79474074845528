import i18n   from '../../libs/i18n'
import moment from 'moment'

const translateTerms = (workFlo) => {
  if ('workFloMethod' in workFlo) {
    workFlo.workFloMethod.name = i18n.t(workFlo.workFloMethod.name)
  }
}

// const setUtc = (workFlo) => {
//   workFlo.events.forEach(e => {
//     e.startDate = moment(e.startDate).utc().format('YYYY-MM-DD HH:mm')
//     e.endDate = moment(e.endDate).utc().format('YYYY-MM-DD HH:mm')
//   })
//   workFlo.interventions.forEach(i => {
//     i.startDate = moment(i.startDate).utc().format('YYYY-MM-DD HH:mm')
//     i.endDate = moment(i.endDate).utc().format('YYYY-MM-DD HH:mm')
//   })
//   // workFlo.events.forEach(e => {
//   //   e.startDate = moment(e.startDate).utc().format('YYYY-MM-DD HH:mm')
//   //   e.endDate = moment(e.endDate).utc().format('YYYY-MM-DD HH:mm')
//   // })
//   // workFlo.interventions.forEach(i => {
//   //   i.startDate = moment(i.startDate).utc().format('YYYY-MM-DD HH:mm')
//   //   i.endDate = moment(i.endDate).utc().format('YYYY-MM-DD HH:mm')
//   // })
// }

export default {
  SET_WORKFLOS (state, workFlos) {

    // workFlos.forEach(w => {
    //   if ('items' in w) {
    //     w.items.forEach(i => {
    //       // console.log(JSON.parse(JSON.stringify(i)))
    //       return ('events' in i) ? setUtc(i) : false
    //     })
    //   }
    // })

    state.workFlos = workFlos
  },
  SET_WORKFLOHISTORY (state, workFloHistory) {
    state.workFloHistory = workFloHistory
  },
  SET_CURRENTWORKFLO (state, workFlo) {
    state.currentWorkFlo = workFlo
  },
  SET_WORKFLOSADDRESSES (state, workFlos) {
    state.workFlosAddresses = workFlos
  },
  SET_WORKFLOSADDRESSESCONTEXT (state, workFlosAddressesContext) {
    state.workFlosAddressesContext = workFlosAddressesContext
  },
  SET_ISKANBANWORKFLOLIST (state, value) {
    state.isKanbanWorkFloList = value
  },
  ADD_WORKFLO (state, workFlo) {
    // state.workFlos.push(workFlo)
    // setUtc(workFlo)
    state.workFlos.unshift(workFlo)
  },
  ADD_WORKFLOS (state, workFlos) {
    workFlos.forEach((value, index) => {
      state.workFlos[index].amount = value.amount
      state.workFlos[index].items.push(...value.items)
    })
  },
  UPDATE_WORKFLO (state, workFlo) {
    translateTerms(workFlo)
    // console.log(JSON.parse(JSON.stringify(workFlo.events[0])))
    // setUtc(workFlo)
    // console.log(JSON.parse(JSON.stringify(workFlo.events[0])))

    let index = state.workFlos.findIndex(i => i.id === workFlo.id)
    state.workFlos.splice(index, 1, workFlo)
  },
  UPDATE_WORKFLO_KANBAN (state, workFlo) {
    translateTerms(workFlo)
    // setUtc(workFlo)

    state.workFlos.forEach((column, columnIndex) => {
      let columnCardIndex = column.items.findIndex(i => i.id == workFlo.id)

      if (columnCardIndex != -1) {
        state.workFlos[columnIndex].items.splice(columnCardIndex, 1, workFlo)
      }
    })
  },
  REMOVE_WORKFLO (state, workFlo) {
    state.workFlos = state.workFlos.filter(item => item.id != workFlo.id)

    // let index = state.workFlos.findIndex(i => i.id === workFlo.id)
    // state.workFlos.splice(index, 1)
  },
  SET_WORKFLOSCONTEXT (state, workFlosContext) {
    // console.log("ok", workFlosContext.fields_filters)
    state.workFlosContext = workFlosContext

    if (!state.fieldFilters.length) {
      workFlosContext.fields_filters.forEach(ff => {
        state.fieldFilters.push({
          name: ff.name,
          type: ff.type,
          entity: ff.relation ? ff.relation : null,
          filter: {}
        })
      })
    }

  },
  UPDATE_WORKFLOSCONTEXTTOTALRESULT (state, isAddWorkFlo) {
    if (isAddWorkFlo) {
      state.workFlosContext.total_results++
    } else {
      state.workFlosContext.total_results--
    }
  },
  SET_AUTOCOMPLETEWORKFLOS (state, autocompleteWorkFlos) {
    state.autocompleteWorkFlos = autocompleteWorkFlos
  },
  RESET_WORKFLOS (state) {
    state.workFlos = []
    state.workFlosContext = {
      page: 1,
      filters: []
    }
  }
}