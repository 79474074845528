export default {
  getAvailableFilters: (state) => state.availableFilters,
  getBankTransactions: (state) => state.bankTransactions,
  getBankTransactionsContext: (state) => state.bankTransactionsContext,
  getBankTransactionsPayloadContext: (state) => {
    return {
      filters: state.bankTransactionsContext.filters,
      sorts: state.bankTransactionsContext.sorts
    }
  },
  getBankTransaction: (state) => (id) => {
    return state.bankTransactions.find(bankTransaction => bankTransaction.id === parseInt(id))
  },
  getEmptyBankTransaction: (state) => JSON.parse(JSON.stringify(state.emptyBankTransaction)),
  getBankTransactionsIndicator: (state) => state.bankTransactionsIndicator,
  getFieldFilters: (state) => state.fieldFilters,
}