import moment from 'moment'

export default {
  getEvents: (state) => state.events,
  getEvent: (state) => (id) => {
    return state.events.find(event => event.id === parseInt(id))
  },
  getEmptyEvent: (state) => JSON.parse(JSON.stringify(state.emptyEvent)),
  getMovements: (state) => state.events.filter(e => e.type == 'trip' && e.isTripWithPersonalVehicle),
  getEventsBetweenDates: (state) => (dates) => {
    return state.events.filter(e => dates.start <= moment(e.startDate) && moment(e.endDate) <= dates.end)
  }
}