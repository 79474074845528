export default [
  {
    path: '/dashboard',
    name: 'home',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      // action: 'read',
    }
  },
  {
    path: '/dashboard2',
    name: 'home2',
    component: () => import('../../views/dashboard2/Dashboard2.vue'),
    meta: {
      resource: 'Dashboard',
      // action: 'read',
    }
  },
  {
    path: '/dev/base3',
    name: 'base3',
    component: () => import('@/views/devs/Base3.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'manage',
    }
  },
  {
    path: '/dev/ckeditor',
    name: 'CKEditor',
    component: () => import('../../views/devs/Ckeditor.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'manage',
    }
  },
  {
    path: '/dev/emails',
    name: 'DevEmails',
    component: () => import('../../views/devs/Emails.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'manage',
    }
  },
  {
    path: '/dev/opp_by_depts',
    name: 'DevOppByDepts',
    component: () => import('../../views/devs/OppByDepts.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'manage',
    }
  },
  {
    path: '/dev/dev',
    name: 'Dev',
    component: () => import('../../views/devs/Dev.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'manage',
    }
  },
]
