export default [
  // Dashboard
  {
    path: '/warehousedashboard',
    name: 'Warehouse dashboard',
    component: () => import('../../views/warehouse/dashboard/Dashboard'),
    meta: {
      resource: 'WarehouseDashboard',
      action: 'manage',
    }
  },
]
