export default {
  SET_EXPORTS (state, exports) {
    state.exports = exports
  },
  ADD_EXPORT (state, expt) {
    state.exports.push(expt)
  },
  UPDATE_EXPORT(state, expt) {
    let index = state.exports.findIndex(i => i.id === expt.id)
    state.exports.splice(index, 1, expt)
  },
  REMOVE_EXPORT (state, expt) {
    state.exports = state.exports.filter(item => item.id != expt.id)

    // let index = state.exports.findIndex(i => i.id === export.id)
    // state.exports.splice(index, 1)
  },
  SET_EXPORTSCONTEXT (state, exportsContext) {
    state.exportsContext = exportsContext
  },
}