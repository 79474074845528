export default {
  autocompleteArticles: [],
  articles: [],
  emptyArticle: {},
  params: {
    entity: [
      'vatRate'
    ],
    collection: []
  }
}