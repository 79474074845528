import { clone } from '../../utils/utils'

export default {
  getAvailableFilters: (state) => state.availableFilters,
  getEmptyTask: (state) => clone(state.emptyTask),
  getTasks: (state) => state.tasks,
  getTasksContext: (state) => state.tasksContext,
  getTask: (state) => (id) => {
    return state.tasks.find(task => task.id === parseInt(id))
  },
  getFieldFilters: (state) => state.fieldFilters,
}