import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'

export default {
  autocompleteTemplates: [],
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'autocompleteSelect',
      autocomplete: {
        store: 'user2/getAutocompleteEmployees',
        label : '_display'
      }
    },
    {
      field: 'name',
      i18n: capitalize(i18n.t('name')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'type',
      i18n: capitalize(i18n.t('type')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'shared',
      i18n: capitalize(i18n.t('shared')),
      subFields: [],
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('public')),
          value: 'public'
        },
        {
          i18n: capitalize(i18n.t('private')),
          value: 'private'
        },
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('name')),
      translateTitle: false,
      key: 'name',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('type')),
      translateTitle: false,
      key: 'type',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: true,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('shared')),
      translateTitle: false,
      key: 'shared',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: true,
        isCurrency: false
      }
    },
  ],
  templates: [],
  isKanbanWorkFloList: false,
  // fieldFilters: [
  // ],
  templatesContext: {
    page: 1,
    filters: []
  },
  emptyTemplate: {
    name: null,
    type: null,
    shared: 'private'
  },
  params: {
    entity: [
      "additionalMembers",
      "workflow",
      "phase",
      // "address",
    ],
    collection: [
      "emailAddress",
      "template",
      "toRecipients"
    ]
  }
}