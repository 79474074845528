import i18n                      from '../../libs/i18n'
import moment                    from 'moment'
import { capitalize }            from '../../utils/filter'
import { getUserData, parseUTC } from '../../utils/utils'

export default {
  autocompleteMailFolders: [],
  availableFilters: [].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  emptyMailFolder: {},
  fieldFilters: [],
  availableListColumns: [].sort((a, b) => a.title < b.title ? -1 : 1),
  isKanbanMailFolderList: false,
  params: {
    entity: [
    ],
    collection: []
  },
  mailFolders: [],
  mailFoldersContext: {
    page: 1,
    filters: []
  }
}