import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'
import moment         from 'moment'
import { clone }      from '../../utils/utils'

const startDate = moment().minutes(0)
const endDate = moment().minutes(15)

export default {
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'startDate',
      i18n: capitalize(i18n.t('startDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'endDate',
      i18n: capitalize(i18n.t('endDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'type',
      i18n: capitalize(i18n.t('type')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'isValidated',
      i18n: capitalize(i18n.t('isValidated')),
      subFields: [],
      type: 'boolean',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    // {
    //   title: capitalize(i18n.t('title')),
    //   translateTitle: false,
    //   key: 'title',
    //   sortable: true,
    //   cell: {
    //     center: false,
    //     useDisplay: false,
    //     isDate: false,
    //     isDateTime: false,
    //     translate: false,
    //     isCurrency: false
    //   }
    // },
    {
      title: capitalize(i18n.tc('employee')),
      translateTitle: true,
      key: '_employee',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    // {
    //   title: capitalize(i18n.t('status')),
    //   translateTitle: true,
    //   key: 'status_name',
    //   sortable: true,
    //   cell: {
    //     center: false,
    //     useDisplay: false,
    //     isDate: false,
    //     isDateTime: false,
    //     translate: false,
    //     isCurrency: false
    //   }
    // },
  ],
  events: [],
  isKanbanWorkFloList: false,
  fieldFilters: [
    // {
    //   name: 'title',
    //   type: 'text',
    //   filter: {}
    // },
    {
      name: 'startDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'endDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'type',
      type: 'list',
      data: ['holiday'],
      isDisabled: true,
      filter: {}
    },
    // {
    //   name: 'status',
    //   type: 'list',
    //   data: ['Prospect', 'Lead', '...'],
    //   entity: 'status_name',
    //   filter: {}
    // },
    {
      name: 'createdBy',
      type: 'employee',
      multiple: false,
      filter: {}
    },
  ],
  eventsContext: {
    page: 1,
    filters: []
  },
  emptyEvent: {
    additionalMembers: [],
    isAllDay: false,
    isTripWithPersonalVehicle: false,
    isValidated: null,
    mileageAllowanceCost: 0,
    callResultType: null, // DBB !
    documents: [],
    discussionPlatform: null,
    endDate: endDate.format('YYYY-MM-DD HH:mm'),
    endPlace: null,
    isOnlineMeeting: false,
    phase: null,
    place: null,
    reminder: null,
    startDate: startDate.format('YYYY-MM-DD HH:mm'),
    startPlace: null,
    steps: [],
    subType: null,
    type: 'work',
    visioLink: null,
    workFlo: null,
    workflow: null,
  },
  params: {
    entity: [
      // 'startPlace',
      // 'endPlace',
      'additionalMembers',
      'documents',
      'phase',
      'reminder',
      'workFlo',
      'workflow',
      'writtenEmail',
    ],
    collection: [
      'endPlace',
      'place',
      'startPlace',
      'steps',
    ]
  }
}