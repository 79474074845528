import state from './indicatorState';
import mutations from './indicatorMutations';
import actions from './indicatorActions';
import getters from "./indicatorGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}