export default {
  getAvailableFilters: (state) => state.availableFilters,
  getEvents: (state) => state.events,
  getCalendarEvents: (state) => {
    let calendarEvents = []

    state.events.forEach(e=> {
      calendarEvents.push(e._calendarEvent)
    })
    return calendarEvents
  },
  getEventsContext: (state) => state.eventsContext,
  getEvent: (state) => (id) => {
    return state.events.find(event => event.id === parseInt(id))
  },
  getEmptyEvent: (state) => JSON.parse(JSON.stringify(state.emptyEvent)),
  getFieldFilters: (state) => state.fieldFilters,
}