import api                                      from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import { arrayUtcToTz, taskToCalendarTask }     from '../../utils/utils'
import Vue                                  from 'vue'
import ToastificationContent                from '@/components/toastification/ToastificationContent.vue'
import { capitalize }                       from '@/utils/filter'
import i18n                                 from '@/libs/i18n'
import store                                from '@/store'
import qs                                   from 'qs'

export default {
  getTasks ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/tasks2' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data.results)

          commit('SET_TASKS', response.data.results)
          commit('SET_TASKSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getTask ({ commit, state }, taskId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/tasks/'+taskId)
        .then(response => {
          arrayUtcToTz([response.data])

          addOrUpdateInList(response.data, state.tasks, 'task',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addTask ({ commit, state }, task) {
    return await cleanEntityToSend(task, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/tasks', clonedEntityToSend)
            .then(response => {
              commit('ADD_TASK', response.data)
              store.commit('calendar/ADD_TASK', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The task has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateTask ({ commit, state }, task) {
    return await cleanEntityToSend(task, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/tasks/' + task.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_TASK', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The task has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      api.delete('/tasks/' + task.id)
        .then(response => {
          commit('REMOVE_TASK', task)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The task has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
}