export default [
  // Dashboard
  {
    path: '/emails',
    name: 'Emails',
    component: () => import('../../views/emails/Emails.vue'),
    meta: {
      resource: 'Emails',
      action: 'manage',
    }
  },
]
