export default {
  getAutocompleteMailFolders: (state) => state.autocompleteMailFolders,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getMailFolders: (state) => state.mailFolders,
  getIsKanbanMailFolderList: (state) => state.isKanbanMailFolderList,
  getMailFoldersContext: (state) => state.mailFoldersContext,
  getMailFolder: (state) => (id) => {
    return state.mailFolders.find(mailFolder => mailFolder.id === parseInt(id))
  },
  getEmptyMailFolder: (state) => JSON.parse(JSON.stringify(state.emptyMailFolder)),
}