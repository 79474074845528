import i18n from '@/libs/i18n'

export default {
  autocompleteUsers: [],
  autocompleteEmployees: [],
  users: [],
  emptyUser: {
    addresses: [],
    roles: [],
  },
  params: {
    entity: [
      'activities',
      'company',
      'incomingInvoices',
      'outgoingInvoices',
      'quotes',
      'workflows',
      'type',
      'avatar',
      'files',
      'salaries',
      'offers',
      'diploma',
      'carInsurances',
      'events'
    ],
    collection: [
      'addresses',
      'contracts',
      'placeOfBirth'
    ]
  },
  roleOptions: [
    {
      value: 'ROLE_ADMIN',
      label: 'Admin'
    },
    // {
    //   value: 'ROLE_SERVICE_MANAGER',
    //   display: this.$t('ServiceManager')
    // },
    // {
    //   value: 'ROLE_PURCHASING_MANAGER',
    //   display: this.$t('PurchasingManager')
    // },
    // {
    //   value: 'ROLE_PROJECT_MANAGER',
    //   display: this.$t('ProjectManager')
    // },
    {
      value: 'ROLE_USER',
      label: i18n.t('User')
    }
  ],
}