import api                                                 from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend }            from '../../utils/form'
import Vue                                                 from 'vue'
import ToastificationContent                               from '@/components/toastification/ToastificationContent.vue'
import { capitalize }                                      from '@/utils/filter'
import i18n                                                from '@/libs/i18n'
import store                                               from '../index'
import useAppConfig                                        from '../../utils/useAppConfig'
import useAPI                                              from '../../utils/useAPI'
import qs                                                  from 'qs'
import { arrayUtcToTz, clone, accountingLineToCalendarAccountingLine } from '../../utils/utils'
import router                                              from '../../router'

export default {
  getAccountingLines ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/accountingLines' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data.results)

          commit('SET_ACCOUNTINGLINES', response.data.results)
          commit('SET_ACCOUNTINGLINESCONTEXT', response.data.page_context)

          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getHierarchicalAccountingLines ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/accountingLine/hierarchical' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data)

          commit('SET_HIERARCHICALACCOUNTINGLINES', response.data)

          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getAccountingLine ({ commit, state }, accountingLineId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/accountingLines/' + accountingLineId)
        .then(response => {
          arrayUtcToTz([response.data])

          addOrUpdateInList(response.data, state.accountingLines, 'accountingLine', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addAccountingLine ({ commit, state }, accountingLine) {
    return await cleanEntityToSend(accountingLine, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.post('/accountingLines', clonedEntityToSend)
            .then(response => {
              commit('ADD_ACCOUNTINGLINE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The accountingLine has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateAccountingLine ({ commit, state }, accountingLine) {
    return await cleanEntityToSend(accountingLine, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.put('/accountingLines/' + accountingLine.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_ACCOUNTINGLINE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The accountingLine has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeAccountingLine ({ commit }, accountingLine) {
    return new Promise((resolve, reject) => {
      api.delete('/accountingLines/' + accountingLine.id)
        .then(response => {
          commit('REMOVE_ACCOUNTINGLINE', accountingLine)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The accountingLine has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteAccountingLines ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/autocomplete/accountingLines' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_AUTOCOMPLETEACCOUNTINGLINES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  }

}