import { computed }    from '@vue/composition-api'
import store           from '@/store'
import i18n            from '@/libs/i18n'
import moment          from 'moment'
import { sortCompare } from './utils'
import api             from '../libs/axios'
import useTemplateAPI  from '../store/template/useTemplateApi'

export default function useAPI () {
  const defaultLimit = 100
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================
  const fetchAddresses = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('address/getAddresses', payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteArticles = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('article/getAutocompleteArticles', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteBankAccounts = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getAutocompleteBankAccounts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteCompanies = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company/getAutocompleteCompanies', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteContacts = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('contact/getAutocompleteContacts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteDocumentCategories = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('documentCategory/getAutocompleteDocumentCategories', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteEmployees = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/getAutocompleteEmployees')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteModuleViews = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('moduleView/getAutocompleteModuleViews', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePayments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/getAutocompletePayments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePaymentMethods = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('paymentMethod/getAutocompletePaymentMethods', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePaymentTerms = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('paymentTerm/getAutocompletePaymentTerms', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePhases = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('phase/getAutocompletePhases', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteUsers = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/getAutocompleteUsers', payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteVats = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('vat/getAutocompleteVats', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteWorkFlos = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/getAutocompleteWorkFlos', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteWorkFloStatuss = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFloStatus/getAutocompleteWorkFloStatuss', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteWorkFloUsages = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFloUsage/getAutocompleteWorkFloUsages', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteWorkflows = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/getAutocompleteWorkflows', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchArticles = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('article/getArticles', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/getBankAccounts', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccounts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts2CheckForTransactions = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/updateBankAccountCheckForTransactions', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccountIndicators = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccountIndicators', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccountsIndicators = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccountsIndicators', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankTransactions = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankTransaction/getBankTransactions', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCalendars = (payload) => {
    // store.dispatch('calendar/getCalendars', payload)
    return new Promise((resolve, reject) => {
      store.dispatch('calendar/getCalendars', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCompanies = (companyId) => {
    if (companyId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('company/getCompany', companyId)
          .then((company) => {
            resolve(company)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('company/getCompanies', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  }
  const fetchCompanies2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company2/getCompanies', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCompanies2Addresses = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company2/getCompaniesAddresses', payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchEmails = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('email/getEmails', payload)
        .then((r) => {
          resolve(r)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchEmployeeContactTypes = () => {
    store.dispatch('employeeContractType/getEmployeeContractTypes')
  }
  // const fetchEvents = (limit = defaultLimit) => {
  //   return new Promise((resolve, reject) => {
  //     store.dispatch('event/getEvents', { limit: limit })
  //       .then(() => {
  //         resolve()
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
  const fetchEvents = (dates, type = null) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/getEventsByDates', { dates: dates, type: type, limit: defaultLimit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchEvents2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event2/getEvents', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchEvents3 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/getEvents', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  // const fetchExpensesIndicator = (dates, type = null) => {
  //   return new Promise((resolve, reject) => {
  //     store.dispatch('indicator/getExpensesIndicator', { dates: dates })
  //       .then(() => {
  //         resolve()
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
  const fetchExpensesIndicator = (payload) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getExpensesIndicator', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAwaitingExportExpensesIndicator = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getAwaitingExportExpensesIndicator')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchExports = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('export/getExports', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchHRIndicator = (employee, dates) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getHRIndicator', { employee: employee, dates: dates })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchIncomingInvoices = (incomingInvoiceId) => {
    if (incomingInvoiceId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('incomingInvoice/getIncomingInvoice', incomingInvoiceId)
          .then((incomingInvoice) => {
            resolve(incomingInvoice)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('incomingInvoice/getIncomingInvoices', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchIncomingInvoices2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice2/getIncomingInvoices', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchOffers = (offerId) => {
    if (offerId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('offer/getOffer', offerId)
          .then((offer) => {
            resolve(offer)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('offer/getOffers', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOffers2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer2/getOffers', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchOrders = (orderId) => {
    if (orderId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('order/getOrder', orderId)
          .then((order) => {
            resolve(order)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('order/getOrders', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOrders2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('order2/getOrders', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchOutgoingInvoices = (outgoingInvoiceId) => {
    if (outgoingInvoiceId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('outgoingInvoice/getOutgoingInvoice', outgoingInvoiceId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('outgoingInvoice/getOutgoingInvoices', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOutgoingInvoices2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoices', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchMailFolders = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('mailFolder/getMailFolders', payload)
        .then((r) => {
          resolve(r)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchModuleViews = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('moduleView/getModuleViews', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchPayments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/getPayments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchPaymentMethods = () => {
    store.dispatch('paymentMethod/getPaymentMethods')
  }
  const fetchPaymentTerms = () => {
    store.dispatch('paymentTerm/getPaymentTerms')
  }
  const fetchReminders = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('reminder/getReminders', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchUsers2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/getUsers', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchSalaries = (salaryId) => {
    if (salaryId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('salary/getSalary', salaryId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('salary/getSalaries', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

  const fetchSalaries2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('salary2/getSalaries', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchSalesIndicator = (payload) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getSalesIndicator', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAwaitingExportSalesIndicator = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getAwaitingExportSalesIndicator')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchSocialSecurityContributions = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution/getSocialSecurityContributions', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchSocialSecurityContributions2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution2/getSocialSecurityContributions', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTasks = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task/getTasks', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTasks2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task2/getTaskss', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTickets = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/getTickets', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTicketsIndicator = (dates, type = null) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/getTicketsIndicator', { dates: dates })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchUsers = (userId) => {
    if (userId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('user/getUser', userId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('user/getUsers', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchVats = () => {
    store.dispatch('vat/getVats')
  }
  const fetchVatDeclarations = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('vatDeclaration/getVatDeclarations', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchWorkFloHistory = (workFloId) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/getWorkFloHistory', workFloId)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchWorkFlos = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/getWorkFlos', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchWorkFlosAddresses = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/getWorkFlosAddresses', payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchWorkflows = (payload) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/getWorkflows', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
    // if (workflowId != null) {
    //   return new Promise((resolve, reject) => {
    //     store.dispatch('workflow/getWorkflow', workflowId)
    //       .then((workflow) => {
    //         resolve(workflow)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // } else {
    //   return new Promise((resolve, reject) => {
    //     store.dispatch('workflow/getWorkflows', { limit: defaultLimit })
    //       .then(() => {
    //         resolve()
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // }
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const addresses = computed(() => {
    return store.getters['address/getAddresses']
  })

  const addressesContext = computed(() => {
    return store.getters['address/getAddressesContext']
  })

  const autocompleteArticles = computed(() => {
    return store.getters['article/getAutocompleteArticles']
  })
  const articles = computed(() => {
    return store.getters['article/getArticles']
  })

  const autocompleteBankAccounts = computed(() => {
    return store.getters['bankAccount2/getAutocompleteBankAccounts']
  })

  const autocompleteCompanies = computed(() => {
    return store.getters['company/getAutocompleteCompanies']
  })

  const autocompleteContacts = computed(() => {
    return store.getters['contact/getAutocompleteContacts']
  })

  const autocompleteDocumentCategories = computed(() => {
    return store.getters['documentCategory/getAutocompleteDocumentCategories']
  })

  const autocompleteEmployees = computed(() => {
    return store.getters['user2/getAutocompleteEmployees']
  })

  const autocompletePayments = computed(() => {
    return store.getters['payment/getAutocompletePayments']
  })

  const autocompletePaymentMethods = computed(() => {
    return store.getters['paymentMethod/getAutocompletePaymentMethods']
  })

  const autocompletePaymentTerms = computed(() => {
    return store.getters['paymentTerm/getAutocompletePaymentTerms']
  })
  const autocompletePhases = computed(() => {
    return store.getters['phase/getAutocompletePhases']
  })

  const autocompleteUsers = computed(() => {
    return store.getters['user2/getAutocompleteUsers']
  })

  const autocompleteVats = computed(() => {
    return store.getters['vat/getAutocompleteVats']
  })

  const autocompleteWorkFlos = computed(() => {
    return store.getters['workFlo/getAutocompleteWorkFlos']
  })

  const autocompleteWorkFloUsages = computed(() => {
    return store.getters['workFloUsage/getAutocompleteWorkFloUsages']
  })

  const autocompleteWorkflows = computed(() => {
    return store.getters['workflow/getAutocompleteWorkflows']
  })

  const autocompleteWorkFloStatus = computed(() => {
    return store.getters['workFloStatus/getAutocompleteWorkFloStatuss']
  })

  const isLoading = computed(() => {
    return store.getters['app/isLoading']
  })

  const bankAccounts = computed(() => {
    return store.getters['bankAccount/getBankAccounts']
  })

  const bankAccounts2 = computed(() => {
    return store.getters['bankAccount2/getBankAccounts']
  })

  const bankAccountsContext = computed(() => {
    return store.getters['bankAccount2/getBankAccountsContext']
  })

  const bankAccountIndicators = computed(() => {
    return store.getters['bankAccount2/getBankAccountIndicators']
  })

  const bankAccountsIndicators = computed(() => {
    return store.getters['bankAccount2/getBankAccountsIndicators']
  })

  const bankTransactions = computed(() => {
    return store.getters['bankTransaction/getBankTransactions']
  })

  const bankTransactionsContext = computed(() => {
    return store.getters['bankTransaction/getBankTransactionsContext']
  })

  const contacts = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companies.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index

      if ('roles' in contact) {
        contact._vSelectType = 'user'
        contact._vSelectId = contact.id
      } else {
        contact._vSelectType = 'company'
        contact._vSelectId = contact.id
      }

      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output

  })

  const contactsWithMainCompany = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companiesWithMe.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index

      if ('roles' in contact) {
        contact._vSelectType = 'user'
        contact._vSelectId = contact.id
      } else {
        contact._vSelectType = 'company'
        contact._vSelectId = contact.id
      }

      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const contactsWithMe = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companiesWithMe.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index
      contact._vSelectId = contact.id
      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const companies = computed(() => {
    return store.getters['company/getCompaniesWithoutMainCompany']
  })

  const companies2 = computed(() => {
    return store.getters['company2/getCompanies']
  })

  const companiesContext = computed(() => {
    return store.getters['company2/getCompaniesContext']
  })

  const companiesWithMe = computed(() => {
    return store.getters['company/getCompanies']
  })

  const currentBreakPoint = computed(() => {
    return store.getters['app/currentBreakPoint']
  })

  const emails = computed(() => {
    return store.getters['email/getEmails']
  })

  const emailsContext = computed(() => {
    return store.getters['email/getEmailsContext']
  })

  const emailLoaders = computed(() => {
    return store.getters['app/emailLoaders']
  })

  const employeeContractTypes = computed(() => {
    return store.getters['employeeContractType/getEmployeeContractTypes']
  })

  const events = computed(() => {
    return store.getters['event/getEvents']
  })

  const events2 = computed(() => {
    return store.getters['event2/getEvents']
  })
  const events2Context = computed(() => {
    return store.getters['event2/getEventsContext']
  })

  const events3 = computed(() => {
    return store.getters['event3/getEvents']
  })
  const events3Context = computed(() => {
    return store.getters['event3/getEventsContext']
  })

  const expensesIndicator = computed(() => {
    return store.getters['indicator/getExpensesIndicator']
  })

  const exports = computed(() => {
    return store.getters['export/getExports']
  })

  const exportsContext = computed(() => {
    return store.getters['export/getExportsContext']
  })

  const awaitingExportExpensesIndicator = computed(() => {
    return store.getters['indicator/getAwaitingExportExpensesIndicator']
  })

  const hrIndicator = computed(() => {
    return store.getters['indicator/getHRIndicator']
  })

  const incomingInvoices = computed(() => {
    return store.getters['incomingInvoice/getIncomingInvoices']
  })

  const incomingInvoices2 = computed(() => {
    return store.getters['incomingInvoice2/getIncomingInvoices']
  })

  const incomingInvoicesContext = computed(() => {
    return store.getters['incomingInvoice2/getIncomingInvoicesContext']
  })

  const mailFolders = computed(() => {
    return store.getters['mailFolder/getMailFolders']
  })

  const mailFoldersContext = computed(() => {
    return store.getters['mailFolder/getMailFoldersContext']
  })

  const moduleViews = computed(() => {
    return store.getters['moduleView/getModuleViews']
  })

  const moduleViewsContext = computed(() => {
    return store.getters['moduleView/getModuleViewsContext']
  })

  const myCompany = computed(() => {
    return store.getters['company/getMyCompany']
  })

  const offers2 = computed(() => {
    return store.getters['offer2/getOffers']
  })

  const offersContext = computed(() => {
    return store.getters['offer2/getOffersContext']
  })

  const orders = computed(() => {
    return store.getters['order/getOrders']
  })

  const orders2 = computed(() => {
    return store.getters['order2/getOrders']
  })

  const ordersContext = computed(() => {
    return store.getters['order2/getOrdersContext']
  })

  const outgoingInvoices = computed(() => {
    return store.getters['outgoingInvoice/getOutgoingInvoices']
  })

  const outgoingInvoices2 = computed(() => {
    return store.getters['outgoingInvoice2/getOutgoingInvoices']
  })

  const outgoingInvoicesContext = computed(() => {
    return store.getters['outgoingInvoice2/getOutgoingInvoicesContext']
  })

  const payments = computed(() => {
    return store.getters['payment/getPayments']
  })

  const paymentsContext = computed(() => {
    return store.getters['payment/getPaymentsContext']
  })

  const paymentMethods = computed(() => {
    let output = []
    store.getters['paymentMethod/getPaymentMethods'].forEach(pm => {
      output.push({
        id: pm.id,
        name: i18n.t(pm.name),
      })
    })

    return output
  })

  const paymentTerms = computed(() => {
    let output = []
    store.getters['paymentTerm/getPaymentTerms'].forEach(pt => {
      output.push({
        id: pt.id,
        term: i18n.t(pt.term),
        days: pt.days
      })
    })

    return output
  })

  const offers = computed(() => {
    return store.getters['offer/getOffers']
  })

  const reminders = computed(() => {
    return store.getters['reminder/getReminders']
  })

  const remindersContext = computed(() => {
    return store.getters['reminder/getRemindersContext']
  })

  const salaries = computed(() => {
    return store.getters['salary/getSalaries']
  })

  const salaries2 = computed(() => {
    return store.getters['salary2/getSalaries']
  })

  const salariesContext = computed(() => {
    return store.getters['salary2/getSalariesContext']
  })

  const salesIndicator = computed(() => {
    return store.getters['indicator/getSalesIndicator']
  })

  const awaitingExportSalesIndicator = computed(() => {
    return store.getters['indicator/getAwaitingExportSalesIndicator']
  })

  const socialSecurityContributions = computed(() => {
    return store.getters['socialSecurityContribution/getSocialSecurityContributions']
  })

  const socialSecurityContributions2 = computed(() => {
    return store.getters['socialSecurityContribution2/getSocialSecurityContributions']
  })

  const socialSecurityContributionsContext = computed(() => {
    return store.getters['socialSecurityContribution2/getSocialSecurityContributionsContext']
  })

  const tasks = computed(() => {
    return store.getters['task/getTasks']
  })

  const tasks2 = computed(() => {
    return store.getters['task2/getTasks']
  })
  const tasks2Context = computed(() => {
    return store.getters['task2/getTasksContext']
  })

  const tickets = computed(() => {
    return store.getters['ticket/getTickets']
  })

  const ticketsContext = computed(() => {
    return store.getters['ticket/getTicketsContext']
  })

  const ticketsIndicator = computed(() => {
    return store.getters['ticket/getTicketsIndicator']
  })

  const users = computed(() => {
    return store.getters['user/getUsers']
  })

  const users2 = computed(() => {
    return store.getters['user2/getUsers']
  })

  const usersContext = computed(() => {
    return store.getters['user2/getUsersContext']
  })

  const vats = computed(() => {
    return store.getters['vat/getVats']
  })

  const vatDeclarations = computed(() => {
    return store.getters['vatDeclaration/getVatDeclarations']
  })

  const vatDeclarationsContext = computed(() => {
    return store.getters['vatDeclaration/getVatDeclarationsContext']
  })

  const workFloHistory = computed(() => {
    return store.getters['workFlo/getWorkFloHistory']
  })

  const workFlos = computed(() => {
    return store.getters['workFlo/getWorkFlos']
  })

  const workFlosContext = computed(() => {
    return store.getters['workFlo/getWorkFlosContext']
  })

  const workFlosAddresses = computed(() => {
    return store.getters['workFlo/getWorkFlosAddresses']
  })

  const workFlosAddressesContext = computed(() => {
    return store.getters['workFlo/getWorkFlosAddressesContext']
  })

  const workflows = computed(() => {
    return store.getters['workflow/getWorkflows']
  })

  const workflowsContext = computed(() => {
    return store.getters['workflow/getWorkflowsContext']
  })

  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================

  const getCountry = (countryCode) => {
    return store.getters['address/getCountry'](countryCode)
  }

  const getContact = (userOrCompanyEntity) => {
    return contactsWithMainCompany.value.find(ct => {
      return (
        'roles' in userOrCompanyEntity &&
        ct._vSelectType == 'user' &&
        ct._vSelectId == userOrCompanyEntity.id
      ) || (
        !('roles' in userOrCompanyEntity) &&
        ct._vSelectType == 'company' &&
        ct._vSelectId == userOrCompanyEntity.id
      )

    })
  }

  const getUserContract = (user, searchedDate = null) => {
    // console.log(user)
    if (!('contracts' in user) || user.contracts.length == 0) return null
    else {
      let date = moment(searchedDate)
      if (searchedDate == null) {
        date = moment()
      }

      return user.contracts.find(c => moment(c.start) <= date && (c.end == null || date <= moment(c.end)))
    }
  }

  const performApiCall = (url, payload) => {
    return new Promise((resolve, reject) => {

      const { page, perPage, searchCriteria, sortColumn, sortOrder } = payload
      let params = new URLSearchParams()

      if (page != null) {params.append('page', page)}
      if (perPage != null) {params.append('per_page', perPage)}
      if (searchCriteria != null && searchCriteria.length != 0) {
        searchCriteria.forEach((item, index) => {
          for (const key in item) {
            params.append('search_criteria[' + index + '][' + key + ']', item[key])
          }
        })
      }
      if (sortColumn != null) {params.append('sort_column', sortColumn)}
      if (sortOrder != null) {params.append('sort_order', sortOrder)}

      api.get(url + '?' + params)
        .then(response => {
          // commit('SET_AUTOCOMPLETECONTACTS', response.data.results)
          // store.commit('app/TOGGLE_LOADING', false)
          // console.log(response)
          resolve(response)

        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
          reject(error)
        })
    })
  }

  // const getMileageAllowanceCost = (user, distance) => {
  //   if (user.horsePower != null) {
  //     if (user.horsePower <= 3) {
  //       return parseFloat(distance) * 0.502
  //     } else if (user.horsePower == 4) {
  //       return parseFloat(distance) * 0.575
  //     } else if (user.horsePower == 5) {
  //       return parseFloat(distance) * 0.603
  //     } else if (user.horsePower == 6) {
  //       return parseFloat(distance) * 0.531
  //     } else {
  //       return parseFloat(distance) * 0.661
  //     }
  //   } else {
  //     return 0
  //   }
  // }

  const {
    fetchAutocompleteTemplates,
    fetchTemplates,
    autocompleteTemplates,
    templates,
    templatesContext
  } = useTemplateAPI()

  return {
    // Fetchers
    fetchAddresses,
    fetchAutocompleteArticles,
    fetchAutocompleteBankAccounts,
    fetchAutocompleteCompanies,
    fetchAutocompleteContacts,
    fetchAutocompleteDocumentCategories,
    fetchAutocompleteEmployees,
    fetchAutocompleteModuleViews,
    fetchAutocompletePayments,
    fetchAutocompletePaymentMethods,
    fetchAutocompletePaymentTerms,
    fetchAutocompletePhases,
    fetchAutocompleteUsers,
    fetchAutocompleteVats,
    fetchAutocompleteWorkFlos,
    fetchAutocompleteWorkFloStatuss,
    fetchAutocompleteWorkFloUsages,
    fetchAutocompleteWorkflows,
    fetchArticles,
    fetchBankAccounts,
    fetchBankAccounts2,
    fetchBankAccounts2CheckForTransactions,
    fetchBankAccountIndicators,
    fetchBankAccountsIndicators,
    fetchBankTransactions,
    fetchCalendars,
    fetchCompanies,
    fetchCompanies2,
    fetchCompanies2Addresses,
    fetchEmails,
    fetchEmployeeContactTypes,
    fetchEvents,
    fetchEvents2,
    fetchEvents3,
    fetchExpensesIndicator,
    fetchExports,
    fetchAwaitingExportExpensesIndicator,
    fetchHRIndicator,
    fetchIncomingInvoices,
    fetchIncomingInvoices2,
    fetchMailFolders,
    fetchOrders,
    fetchOutgoingInvoices,
    fetchOutgoingInvoices2,
    fetchPayments,
    fetchPaymentMethods,
    fetchPaymentTerms,
    fetchOffers,
    fetchOffers2,
    fetchOrders2,
    fetchReminders,
    fetchSalaries,
    fetchSalaries2,
    fetchSalesIndicator,
    fetchAwaitingExportSalesIndicator,
    fetchSocialSecurityContributions,
    fetchSocialSecurityContributions2,
    fetchTasks,
    fetchTasks2,
    fetchTemplates,
    fetchTickets,
    fetchTicketsIndicator,
    fetchUsers,
    fetchUsers2,
    fetchVats,
    fetchVatDeclarations,
    fetchWorkFloHistory,
    fetchWorkFlos,
    fetchWorkFlosAddresses,
    fetchWorkflows,

    // Computed
    addresses,
    addressesContext,
    articles,
    autocompleteArticles,
    autocompleteBankAccounts,
    autocompleteCompanies,
    autocompleteContacts,
    autocompleteDocumentCategories,
    autocompleteEmployees,
    autocompletePayments,
    autocompletePaymentMethods,
    autocompletePaymentTerms,
    autocompletePhases,
    autocompleteUsers,
    autocompleteVats,
    autocompleteWorkFlos,
    autocompleteWorkflows,
    autocompleteWorkFloStatus,
    autocompleteWorkFloUsages,
    bankAccountIndicators,
    bankAccountsIndicators,
    bankAccounts,
    bankAccounts2,
    bankAccountsContext,
    bankTransactions,
    bankTransactionsContext,
    contacts,
    contactsWithMe,
    contactsWithMainCompany,
    companies,
    companies2,
    companiesContext,
    currentBreakPoint,
    emails,
    emailsContext,
    emailLoaders,
    employeeContractTypes,
    events,
    events2,
    events2Context,
    events3,
    events3Context,
    expensesIndicator,
    exports,
    exportsContext,
    awaitingExportExpensesIndicator,
    hrIndicator,
    incomingInvoices,
    incomingInvoices2,
    incomingInvoicesContext,
    isLoading,
    mailFolders,
    mailFoldersContext,
    moduleViews,
    moduleViewsContext,
    myCompany,
    orders,
    outgoingInvoices,
    outgoingInvoices2,
    outgoingInvoicesContext,
    payments,
    paymentsContext,
    paymentMethods,
    paymentTerms,
    reminders,
    remindersContext,
    salaries,
    salaries2,
    salariesContext,
    salesIndicator,
    awaitingExportSalesIndicator,
    socialSecurityContributions,
    socialSecurityContributions2,
    socialSecurityContributionsContext,
    tasks,
    tasks2,
    tasks2Context,
    templates,
    templatesContext,
    tickets,
    ticketsContext,
    ticketsIndicator,
    users,
    users2,
    usersContext,
    offers,
    offers2,
    offersContext,
    orders2,
    ordersContext,
    vats,
    vatDeclarations,
    vatDeclarationsContext,
    workFloHistory,
    workFlos,
    workFlosAddresses,
    workFlosContext,
    workFlosAddressesContext,
    workflows,
    workflowsContext,

    // Methods
    getCountry,
    getContact,
    getUserContract,
    // getMileageAllowanceCost,
    performApiCall
  }
}
