export default [
  // Indicators
  {
    path: '/workFlos/dashboard',
    name: 'WorkFlos dashboard',
    component: () => import('../../views/crm/indicators/CrmIndicators.vue'),
    meta: {
      resource: 'CRMIndicators',
      action: 'manage',
    }
  },
  {
    path: '/workFlos/myDashboard',
    name: 'My CRM dashboard',
    component: () => import('../../views/crm/indicators/MyCRMDashboard.vue'),
    meta: {
      resource: 'WorkFlos',
      // action: 'read',
    }
  },
  // Pipeline
  {
    path: '/workFlos/pipeline',
    name: 'WorkFlos',
    component: () => import('../../views/crm/WorkFlos3.vue'),
    meta: {
      resource: 'WorkFlos',
      // action: 'read',
    }
  },
  {
    path: '/workFlos/:workFloId',
    name: 'WorkFlo view',
    component: () => import('../../views/crm/WorkFlo.vue'),
    meta: {
      navActiveLink: 'WorkFlos',
      resource: 'WorkFlos',
      // action: 'read',
    }
  },
  // Map
  {
    path: '/workFlosMap',
    name: 'WorkFlosMap',
    component: () => import('../../views/crm/map/WorkFloMap.vue'),
    meta: {
      resource: 'WorkFlos',
      // action: 'read',
    }
  },
]
