import state from './calendarState';
import mutations from './calendarMutations';
import actions from './calendarActions';
import getters from "./calendarGetters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}