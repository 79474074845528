import moment from 'moment'

export default {
  getOrders: (state) => state.orders,
  getOrder: (state) => (id) => {
    return state.orders.find(order => order.id === parseInt(id))
  },
  getEmptyOrder: (state) => JSON.parse(JSON.stringify(state.emptyOrder)),
  getOrdersBetweenDates: (state) => (dates) => {
    return state.orders.filter(o => {
      return o._linkedIncomingInvoices.length != 0 &&
      dates.start <= moment(o._linkedIncomingInvoices[0].billingDate) && moment(o._linkedIncomingInvoices[0].billingDate) <= dates.end
    })
  }
}