export default {
  getAutocompleteCompanies: (state) => state.autocompleteCompanies,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableMapFilters: (state) => state.availableMapFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getCompanies: (state) => state.companies,
  getIsKanbanCompanyList: (state) => state.isKanbanCompanyList,
  getCompaniesContext: (state) => state.companiesContext,
  getCompany: (state) => (id) => {
    return state.companies.find(company => company.id === parseInt(id))
  },
  getEmptyCompany: (state) => JSON.parse(JSON.stringify(state.emptyCompany)),
}