import moment from 'moment'

export default {
  getOffers: (state) => state.offers,
  getOffer: (state) => (id) => {
    return state.offers.find(offer => offer.id === parseInt(id))
  },
  getEmptyOffer: (state) => JSON.parse(JSON.stringify(state.emptyOffer)),
  getOffersBetweenDates: (state) => (dates) => {
    return state.offers.filter(o => dates.start <= moment(o.billingDate) && moment(o.billingDate) <= dates.end)
  }
}