export default {
  autocompleteVatDeclarations: [],
  vatDeclarations: [],
  emptyVatDeclaration: {
    bankAccount: {
      '_display': 'LCL 0000706371A',
      'id': 1
    },
    month: (new Date()).toISOString().slice(0, 10),
    paymentDate: (new Date()).toISOString().slice(0, 10)
  },
  params: {
    entity: [
      'bankAccount',
      'document',
      'paymentMethod',
      'paymentRelations'
    ],
    collection: []
  },
  vatDeclarationsContext: {}
}