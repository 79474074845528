import api from '@/libs/axios'
import useAPI from '../../utils/useAPI'
import store from '../index'

export default {
  getAutocompletePaymentTerms ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/paymentTerms'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEPAYMENTTERMS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
  getPaymentTerms ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.paymentTerms.length == 0) {
        api.get('/paymentTerms')
          .then(response => {
            commit('SET_PAYMENTTERMS', response.data)
            resolve(response)
          })
          .catch(err => {
            console.log('Error', err)
            reject(err)
          })
      } else {
        resolve(true)
      }

    })
  },

}