import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'

export default {
  availableFilters: [
    {
      field: '_createdAt',
      i18n: capitalize(i18n.t('exportDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'date',
      i18n: capitalize(i18n.t('month')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'type',
      i18n: capitalize(i18n.t('type')),
      subFields: [],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableGlobalAmounts: [].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  exports: [],
  emptyExport: {},
  params: {
    entity: [],
    collection: []
  },
  exportsContext: {}
}