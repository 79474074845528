// import i18n from '@/libs/i18n'
import { setRepetitionDisplay } from '../../utils/utils'

export default {
  SET_TASKS (state, tasks) {
    tasks.forEach(t => {
      if (t.repetition != null) {
        setRepetitionDisplay(t.repetition)
      }
    })

    state.tasks = tasks
  },
  ADD_TASK (state, task) {
    if (task.repetition != null) {
      setRepetitionDisplay(task.repetition)
    }

    state.tasks.unshift(task)
  },
  UPDATE_TASK (state, task) {
    if (task.repetition != null) {
      setRepetitionDisplay(task.repetition)
    }

    let index = state.tasks.findIndex(i => i.id == task.id)
    state.tasks.splice(index, 1, JSON.parse(JSON.stringify(task)))
  },
  REMOVE_TASK (state, task) {
    state.tasks = state.tasks.filter(item => item.id != task.id)

    // let index = state.tasks.findIndex(i => i.id === task.id)
    // state.tasks.splice(index, 1)
  },
  SET_TASKSCONTEXT (state, tasksContext) {
    state.tasksContext = tasksContext
  },
  UPDATE_TASKSTOTALRESULT (state, isAddTask) {
    if (isAddTask) {
      state.tasksContext.total_results++
    } else {
      state.tasksContext.total_results--
    }
  },
}