export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:resetToken',
    name: 'auth-reset-password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/validate-registration',
    name: 'auth-validate-registration',
    component: () => import('@/views/authentication/ValidateRegistration.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/miscellaneous/error',
    name: 'error',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  // {
  //   path: '/error-404',
  //   name: 'error-404',
  //   component: () => import('@/views/miscellaneous/Error404.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     action: 'read',
  //   },
  // },
]
