import state from './workFloState';
import mutations from './workFloMutations';
import actions from './workFloActions';
import getters from "./workFloGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}