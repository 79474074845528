export default {
  calendars: [],
  selectedCalendars: [],
  events: []

  // calendars: [
  //   {
  //     name: 'expense',
  //     color: 'danger',
  //     // isSelected: true
  //   }, {
  //     name: 'invoice',
  //     color: 'warning',
  //     // isSelected: true
  //   }, {
  //     name: 'personal',
  //     color: 'primary',
  //     // isSelected: true
  //   }
  // ],
  // selectedCalendars: ['expense', 'invoice', 'personal'],
  // events: [
  //   {
  //     title: 'title 1 - calendar Personal',
  //     start: (new Date()),
  //     end: (new Date()),
  //     allDay: 'isAllDay',
  //     extendedProps: {
  //       calendar: 'personal'
  //     }
  //   },
  //   {
  //     title: 'title 2 - calendar Personal',
  //     start: (new Date()),
  //     end: (new Date()),
  //     allDay: 'isAllDay',
  //     extendedProps: {
  //       calendar: 'personal'
  //     }
  //   },
  //   {
  //     title: 'title 1 - calendar Expense',
  //     start: (new Date()),
  //     end: (new Date()),
  //     allDay: 'isAllDay',
  //     extendedProps: {
  //       calendar: 'expense'
  //     }
  //   },
  //
  // ]
}