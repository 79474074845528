import { capitalize } from '../../utils/filter'

import i18n from '@/libs/i18n'
import { setRepetitionDisplay } from '../../utils/utils'

export default {
  SET_WORKFLOWS (state, workflows) {
    // workflows.forEach(w => {
    //   if ('incomingInvoices' in w) {
    //     w.incomingInvoices.forEach(ii => {
    //       if ('paymentMethod' in ii) {
    //         ii.paymentMethod.name = i18n.t(ii.paymentMethod.name)
    //       }
    //     })
    //   }
    //
    //   if ('_monitoring' in w) {
    //     w._monitoring.forEach(m => {
    //       if (m.content != null) {
    //         if (m.content.includes('email done:')) {
    //           m.content = capitalize(i18n.t('email done:', { msg: m.content.split('email done: ').pop() }))
    //         } else if (m.content.includes('call done:')) {
    //           m.content = capitalize(i18n.t('call done:', { msg: m.content.split('call done: ').pop() }))
    //         } else if (m.content.includes('appointment done:')) {
    //           m.content = capitalize(i18n.t('appointment done:', { msg: m.content.split('appointment done: ').pop() }))
    //         } else if (m.content.includes('toDo done:')) {
    //           m.content = capitalize(i18n.t('toDo done:', { msg: m.content.split('toDo done: ').pop() }))
    //         } else if (m.content.includes('reminder done:')) {
    //           m.content = capitalize(i18n.t('reminder done:', { msg: m.content.split('reminder done: ').pop() }))
    //         } else if ([
    //           'Workflow created',
    //           'Workflow edited',
    //           'email done',
    //           'call done',
    //           'appointment done',
    //           'toDo done',
    //           'reminder done'
    //         ].includes(m.content)) {
    //           m.content = capitalize(i18n.t(m.content))
    //         }
    //       }
    //     })
    //   }
    //
    //   if ('tasks' in w) {
    //     w.tasks.forEach(t => {
    //       if (t.repetition != null) {
    //         setRepetitionDisplay(t.repetition)
    //       }
    //     })
    //   }
    //
    // })

    state.workflows = workflows
  },
  SET_WORKFLOWSCONTEXT (state, workflowsContext) {
    state.workflowsContext = workflowsContext
  },
  SET_ISKANBANWORKFLOLIST (state, value) {
    state.isKanbanWorkFloList = value
  },
  ADD_WORKFLOW (state, workflow) {
    state.workflows.push(workflow)
  },
  UPDATE_WORKFLOW (state, workflow) {
    let index = state.workflows.findIndex(i => i.id == workflow.id)
    state.workflows.splice(index, 1, JSON.parse(JSON.stringify(workflow)))
  },
  REMOVE_WORKFLOW (state, workflow) {
    state.workflows = state.workflows.filter(item => item.id != workflow.id)

    // let index = state.workflows.findIndex(i => i.id === workflow.id)
    // state.workflows.splice(index, 1)
  },

  SET_AUTOCOMPLETEWORKFLOWS (state, autocompleteWorkflows) {
    state.autocompleteWorkflows = autocompleteWorkflows
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEWORKFLOW (state, payload) {
    state.autocompleteWorkflows.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEWORKFLOW (state, payload) {
    // console.log(payload)
    let index = state.autocompleteWorkflows.findIndex(i => i.id === payload.workflow.id)
    state.autocompleteWorkflows[index].name = payload.workflow.name
  },
}