export default {
  orders: [],
  emptyOrder: {
    documents: [],
    products: [],
    isDiscountPercent: false,
    paymentMethod: 1,
    paymentTerm: 3,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  params: {
    entity: [
      'article',
      'company',
      'childs',
      'documents',
      'incomingInvoice',
      'paymentMethod',
      'paymentTerm',
      'phase',
      'receipt',
      'signedDocument',
      'supplierCompany',
      'vatRate',
      'workflow',

    ],
    collection: [
      'includedContacts',
      'incomingInvoices',
      'products',
    ]
  }
}