import moment from 'moment'

export default {
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableGlobalAmounts: (state) => state.availableGlobalAmounts,
  getIncomingInvoices: (state) => state.incomingInvoices,
  getIncomingInvoicesContext: (state) => state.incomingInvoicesContext,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getSupplierInvoiceFieldFilters: (state) => state.supplierInvoiceFieldFilters,
  getIncomingInvoice: (state) => (id) => {
    return state.incomingInvoices.find(incomingInvoice => incomingInvoice.id === parseInt(id))
  },
  getEmptyIncomingInvoice: (state) => JSON.parse(JSON.stringify(state.emptyIncomingInvoice)),
  incomingInvoicesNotCompletelyPaid: (state) => {
    // let iis = state.incomingInvoices.filter(incomingInvoice => incomingInvoice._paymentStatus != 'Paid' && incomingInvoice.billNumber != null)
    let iis = state.incomingInvoices.filter(incomingInvoice => incomingInvoice._paymentStatus != 'Paid')

    iis.sort((a, b) => {return new Date(b.billingDate) - new Date(a.billingDate)})

    return iis
  },
  supplierReceivable: (state) => {
    return state.incomingInvoices.filter(incomingInvoice => {
      return incomingInvoice._paymentStatus != 'Paid' &&
        moment(incomingInvoice.paymentDate) < moment() &&
        [1, 2, 4].includes(incomingInvoice.paymentMethod.id)
    })
  },
  getIncomingInvoicesBetweenDates: (state) => (dates) => {
    return state.incomingInvoices.filter(ii => dates.start <= moment(ii.billingDate) && moment(ii.billingDate) <= dates.end)
  }
}