import i18n from '@/libs/i18n'

const translateTerms = (socialSecurityContribution) => {
  socialSecurityContribution.paymentMethod.name = i18n.t(socialSecurityContribution.paymentMethod.name)
}

export default {
  SET_SOCIALSECURITYCONTRIBUTIONS (state, socialSecurityContributions) {
    socialSecurityContributions.forEach(oi => {
      translateTerms(oi)
    })

    state.socialSecurityContributions = socialSecurityContributions
  },
  ADD_SOCIALSECURITYCONTRIBUTION (state, socialSecurityContribution) {
    translateTerms(socialSecurityContribution)

    state.socialSecurityContributions.push(socialSecurityContribution)
  },
  UPDATE_SOCIALSECURITYCONTRIBUTION(state, socialSecurityContribution) {
    translateTerms(socialSecurityContribution)

    let index = state.socialSecurityContributions.findIndex(i => i.id == socialSecurityContribution.id)
    state.socialSecurityContributions.splice(index, 1, JSON.parse(JSON.stringify(socialSecurityContribution)))
  },
  REMOVE_SOCIALSECURITYCONTRIBUTION (state, socialSecurityContribution) {
    state.socialSecurityContributions = state.socialSecurityContributions.filter(item => item.id != socialSecurityContribution.id)

    // let index = state.socialSecurityContributions.findIndex(i => i.id === socialSecurityContribution.id)
    // state.socialSecurityContributions.splice(index, 1)
  },
  SET_SOCIALSECURITYCONTRIBUTIONSCONTEXT (state, socialSecurityContributionsContext) {
    state.socialSecurityContributionsContext = socialSecurityContributionsContext
  },
}