export default {
  salaries: [],
  emptySalary: {},
  params: {
    entity: [
      'bankOperations',
      'paySlip',
      'employee'
    ],
    collection: []
  }
}