import i18n from '../../libs/i18n'
import { capitalize } from '../../utils/filter'
import { getUserData } from '../../utils/utils'

export default {
  autocompleteWorkFloStatuss: [],
  emptyWorkFloStatus: {},
  fieldFilters: [],
  availableListColumns: [],
  params: {
    entity: [],
    collection: []
  },
  workFloStatuss: [],
  workFloStatussContext: {}
}