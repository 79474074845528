import state from './employeeContractTypeState'
import mutations from './employeeContractTypeMutations'
import actions from './employeeContractTypeActions'
import getters from './employeeContractTypeGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}