import i18n from '../../libs/i18n'

const translateTerms = (moduleView) => {
  
}

export default {
  SET_MODULEVIEWS (state, moduleViews) {
    state.moduleViews = moduleViews
  },
  ADD_MODULEVIEW (state, moduleView) {
    // state.moduleViews.push(moduleView)
    state.moduleViews.unshift(moduleView)
  },
  UPDATE_MODULEVIEW (state, moduleView) {
    translateTerms(moduleView)
    let index = state.moduleViews.findIndex(i => i.id === moduleView.id)
    state.moduleViews.splice(index, 1, moduleView)
  },
  REMOVE_MODULEVIEW (state, moduleView) {
    state.moduleViews = state.moduleViews.filter(item => item.id != moduleView.id)

    // let index = state.moduleViews.findIndex(i => i.id === moduleView.id)
    // state.moduleViews.splice(index, 1)
  },
  SET_MODULEVIEWSCONTEXT (state, moduleViewsContext) {
    state.moduleViewsContext = moduleViewsContext
  },
  UPDATE_MODULEVIEWSCONTEXTTOTALRESULT (state, isAddModuleView) {
    if (isAddModuleView) {
      state.moduleViewsContext.total_results++
    } else {
      state.moduleViewsContext.total_results--
    }
  },
  SET_AUTOCOMPLETEMODULEVIEWS (state, autocompleteModuleViews) {
    state.autocompleteModuleViews = autocompleteModuleViews
  },
}