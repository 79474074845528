<template>
  <div
      v-b-hover="hoverHandler"
      class="d-inline position-relative"
      :class="{'fa-disabled': disabled, 'text-primary': iconHover}"
      @click="$emit('click')"
  >
    <font-awesome-icon :icon="localIcon"
                       :size="size"
                       :spin="spin"
                       class="mx-auto"
    />

    <span
        v-if="typeof badge !== 'undefined'"
        class="badge badge-up badge-pill"
        :class="'badge-'+badgeColor"
        :style="{'top': (size=='2x')?'-19px':'-11px'}"
    >
      <slot name="badge">
        {{ badge }}
      </slot>
    </span>

    <slot name="textRight"/>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    library: {
      type: String,
      default: 'fas'
    },
    size: {
      type: String,
      default: '1x'
    },
    badge: {
      // type: String|Number,
      // default: ''
    },
    badgeColor: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    activeHover: {
      type: Boolean,
      default: false
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      iconHover: false
    }
  },
  computed: {
    localIcon () {
      if (this.library != 'fas') return [this.library, this.icon]
      else return this.icon
    }
  },
  methods: {
    hoverHandler (isHovered) {
      if (this.activeHover) {
        if (isHovered) {
          // Do something
          this.iconHover = true
        } else {
          // Do something else
          this.iconHover = false
        }
      }

    }
  },
  mounted () { },
  created () {}
}
</script>