import { capitalize } from '../../utils/filter'
import i18n from '../../libs/i18n'

export default {
  availableFilters: [
    {
      name: '_payment_bankReconciliationStatus',
      field: '_payment_bankReconciliationStatus',
      i18n: capitalize(i18n.t('bankReconciliationStatus')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('reconciliated')),
          value: 'reconciliated'
        },
        {
          i18n: capitalize(i18n.t('unreconciliated')),
          value: 'unreconciliated'
        },
        {
          i18n: capitalize(i18n.t('partialReconciliation')),
          value: 'partially reconciliated'
        }
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      name: '_payment_benificiaries',
      field: '_payment_benificiaries',
      i18n: capitalize(i18n.t('beneficiaries')),
      subFields: [],
      isSpecific: true,
      type: 'text'
    },
    {
      name: '_payment_relations',
      field: '_payment_relations',
      i18n: capitalize(i18n.t('invoices')),
      subFields: [],
      isSpecific: true,
      type: 'text'
    },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'date',
      i18n: capitalize(i18n.t('date')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'amount',
      i18n: capitalize(i18n.t('amount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'paymentMethod',
      i18n: capitalize(i18n.t('paymentMethod')),
      subFields: ['name'],
      type: 'string',
    },
    {
      field: 'bankAccount',
      i18n: capitalize(i18n.t('bankAccount')),
      subFields: [],
      type: 'string',
      show: false
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  autocompletePayments: [],
  payments: [],
  fieldFilters: [
    {
      isQuickFilter: true,
      name: 'reconciliated',
      filter: {},
      value: {
        comparator: 'is_not_empty',
        field: 'bankTransaction'
      }
    },
    {
      isQuickFilter: true,
      name: 'unreconciliated',
      filter: {},
      value: {
        comparator: 'is_empty',
        field: 'bankTransaction'
      }
    },
    {
      name: 'amount',
      type: 'number',
      filter: {}
    },
    // {
    //   name: 'bankAccount',
    //   type: 'entity',
    //   entityStore: 'bankAccount/getAutocompleteBankAccounts',
    //   multiple: false,
    //   filter: {}
    // },
    {
      name: 'paymentMethod',
      type: 'entity',
      entityStore: 'paymentMethod/getAutocompletePaymentMethods',
      entityLabel: 'name',
      multiple: false,
      filter: {}
    },
    {
      name: 'date',
      type: 'date',
      filter: {}
    },
    // {
    //   name: 'status',
    //   type: 'list',
    //   data: ['Prospect', 'Lead', '...'],
    //   entity: 'status_name',
    //   filter: {}
    // },
    // {
    //   name: 'createdBy',
    //   type: 'employee',
    //   multiple: false,
    //   filter: {}
    // },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('date')),
      translateTitle: true,
      key: 'date',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('paymentMethod')),
      translateTitle: true,
      key: 'paymentMethod',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('beneficiaries')),
      translateTitle: true,
      key: '_paymentBeneficiaries',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('invoices')),
      translateTitle: true,
      key: '_paymentRelationsDisplay',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('amount')),
      translateTitle: true,
      key: 'amount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
  ],
  isKanbanWorkFloList: false,
  emptyPayment: {
    date: (new Date()).toISOString().slice(0, 10),
    bankAccount: {
      '_display': 'LCL 0000706371A',
      'id': 1
    }
  },
  params: {
    entity: [
      'attachment',
      'attachmentCategory',
      'bankAccount',
      'bankTransaction',
      'incomingInvoice',
      'outgoingInvoice',
      'paymentMethod',
      'salary',
      'socialSecurityContribution',
      'vatDeclaration'
    ],
    collection: [
      'paymentRelations',
    ]
  },
  paymentsContext: {
    page: 1,
    filters: []
  }
}