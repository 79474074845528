export default [
  // Account settings
  {
    path: '/accountSettings',
    name: 'AccountSettings',
    component: () => import('@/views/accountSettings/AccountSettings.vue'),
    meta: {
      resource: 'AccountSettings',
      action: 'read',
    }
  },
]
