export default {
  SET_VATS (state, vats) {
    state.vats = vats
  },

  SET_AUTOCOMPLETEVATS (state, autocompleteVats) {
    state.autocompleteVats = autocompleteVats
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEVAT (state, payload) {
    state.autocompleteVats.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEVAT (state, payload) {
    // console.log(payload)
    let index = state.autocompleteVats.findIndex(i => i.id === payload.vat.id)
    state.autocompleteVats[index].name = payload.vat.name
  },
}