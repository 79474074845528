import { capitalize } from '../../utils/filter'
import i18n from '../../libs/i18n'

export default {
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'name',
      i18n: capitalize(i18n.t('name')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'firstName',
      i18n: capitalize(i18n.t('firstName')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'email',
      i18n: capitalize(i18n.t('email')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'phone',
      i18n: capitalize(i18n.t('phone')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'mobile',
      i18n: capitalize(i18n.t('mobile')),
      subFields: [],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  autocompleteUsers: [],
  autocompleteEmployees: [],
  emptyUser: {
    addresses: [],
    roles: [],
  },
  fieldFilters: [
    {
      name: 'name',
      type: 'text',
      filter: {}
    },
    {
      name: 'firstName',
      type: 'text',
      filter: {}
    },
    {
      name: 'email',
      type: 'text',
      filter: {}
    },
    {
      name: 'phone',
      type: 'text',
      filter: {}
    },
    {
      name: 'mobile',
      type: 'text',
      filter: {}
    },
  ],
  availableListColumns: [
    {
      title: 'name',
      translateTitle: true,
      key: '_display',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'email',
      translateTitle: true,
      key: 'email',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'phone',
      translateTitle: true,
      key: 'phone',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: 'mobile',
      translateTitle: true,
      key: 'mobile',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ],
  availableEmployeeFilters: [
    {
      name: '_employee_status',
      field: '_employee_status',
      i18n: capitalize(i18n.t('status')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: capitalize(i18n.t('activeContract')),
          value: 'activeContract'
        },
        {
          i18n: capitalize(i18n.t('outOfContract')),
          value: 'outOfContract'
        },
      ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
    },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'name',
      i18n: capitalize(i18n.t('name')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'firstName',
      i18n: capitalize(i18n.t('firstName')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'email',
      i18n: capitalize(i18n.t('email')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'roles',
      i18n: capitalize(i18n.t('roles')),
      subFields: [],
      type: 'text',
      _sectionOperatorDisabled: true,
      _fieldDisabled: true,
      _operatorDisabled: true,
      _allowDelete: false,
      _isInputValueDisabled: true
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  isKanbanUserList: false,
  params: {
    entity: [
      'activities',
      'avatar',
      'carInsurances',
      'company',
      'defaultViewAddressesMap',
      'defaultViewBankTransactions',
      'defaultViewCrm',
      'defaultViewCashFlowJournal',
      'defaultViewContactCompanies',
      'defaultViewContactUsers',
      'defaultViewCustomerInvoices',
      'defaultViewCustomerReceivables',
      'defaultViewHolidays',
      'defaultViewOffers',
      'defaultViewOrders',
      'defaultViewSupplierInvoices',
      'defaultViewSupplierReceivables',
      'defaultViewWorkflows',
      'defaultViewEmployees',
      'defaultViewExports',
      'defaultViewAddressesMap',
      'defaultViewWorkFlosMap',
      'defaultViewTemplates',
      'diploma',
      'events',
      'files',
      'incomingInvoices',
      'offers',
      'outgoingInvoices',
      'quotes',
      'salaries',
      'type',
      'workflows'
    ],
    collection: [
      'addresses',
      'contracts',
      'placeOfBirth'
    ]
  },
  users: [],
  usersContext: {
    page: 1,
    filters: []
  }
}