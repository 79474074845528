import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

// For HTML2CANVAS ASYNC/AWAIT
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// 3rd party plugins
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import CustomIcon from './components/icon/Icon'
import '@/libs/axios'
import '@/libs/acl'
import '@/libs/moment'
import '@/libs/toastification'
import '@/libs/vue-file-agent'
import '@/libs/vue-select'
import '@/libs/portal-vue'

// Font awesome icons
library.add(fas, far, fab)
Vue.component('icon', CustomIcon)

// Bootstrap
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import styles
require('@/assets/scss/main.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
