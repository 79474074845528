export default [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/contacts/Contacts.vue'),
    meta: {
      resource: 'Contacts',
      action: 'read',
    }
  },
]
