import api from '@/libs/axios'
import { cleanEntityToSend, addOrUpdateInList } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getAutocompleteCompanies ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/companies'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETECOMPANIES', response.data.results)
          store.commit('company2/SET_AUTOCOMPLETECOMPANIES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
  getCompanies ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/companies'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          // console.log(JSON.stringify(response.data))
          // addOrUpdateInList(response.data, state.companies, 'company', commit)
          commit('SET_COMPANIES', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'company', limit, offset, state.companies, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')

              // store.dispatch('company/getCompany', 1).then(() => {
              //   resolve(true)
              // })
              resolve(true)

            })
          } else {
            store.commit('app/REMOVE_LOADER')
            // store.dispatch('company/getCompany', 1).then(() => {
            //   resolve(true)
            // })
            resolve(true)
          }
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getCompany ({ commit, state }, companyId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/companies/'+companyId)
        .then(response => {
          addOrUpdateInList(response.data, state.companies, 'company', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addCompany ({ commit, state }, company) {
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/companies', clonedEntityToSend)
            .then(response => {
              commit('ADD_COMPANY', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateCompany ({ commit, state }, company) {
    return await cleanEntityToSend(company, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/companies/' + company.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_COMPANY', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The company has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              // console.log('Error', error)
              if (error.response && error.response.status == 401) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('UnableToUpdate'),
                    icon: 'times',
                    text: i18n.t('Changes are linked ton non-editable items'),
                    variant: 'danger'
                  },
                })
              }
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeCompany ({ commit }, company) {
    return new Promise((resolve, reject) => {
      api.delete('/companies/' + company.id)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The company has been ', { crud: i18n.t('deletedE') }),
              variant: 'success'
            },
          })

          commit('REMOVE_COMPANY', company)
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('UnableToDelete'),
                icon: 'times',
                text: i18n.t('This company is linked to invoices'),
                variant: 'danger'
              },
            })
          }
          reject(error)
        })
    })
  }

}