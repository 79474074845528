import i18n from '../../libs/i18n'
import { capitalize } from '../../utils/filter'

let expirationDate = new Date()
expirationDate.setDate(expirationDate.getDate() + 30)

export default {
  availableFilters: [
    {
      name: '_billable_preTaxAmount_balance',
      field: '_billable_preTaxAmount_balance',
      i18n: capitalize(i18n.t('billablePreTaxAmountBalance')),
      subFields: [],
      isSpecific: true,
      type: 'number',
    },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'discount',
      i18n: capitalize(i18n.t('discount')),
      subFields: [],
      type: 'text',
    },
    {
      name: '_offer_status',
      field: '_offer_status',
      i18n: capitalize(i18n.t('status')),
      subFields: [],
      isSpecific: true,
      type: 'select',
      options: [
        {
          i18n: i18n.t("Canceled"),
          value: "Canceled"
        },
        {
          i18n: i18n.t("Fully invoiced"),
          value: "Fully invoiced"
        },
        {
          i18n: i18n.t("Partially invoiced"),
          value: "Partially invoiced"
        },
        {
          i18n: i18n.t("Declined"),
          value: "Declined"
        },
        {
          i18n: i18n.t("Waiting for invoicing"),
          value: "Waiting for invoicing"
        },
        {
          i18n: i18n.t("Waiting for signature"),
          value: "Waiting for signature"
        },
      ]
    },
    {
      field: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'vat',
      i18n: capitalize(i18n.t('vat')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'billingDate',
      i18n: capitalize(i18n.t('billingDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'signedDocumentDate',
      i18n: capitalize(i18n.t('signedDocumentDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'expirationDate',
      i18n: capitalize(i18n.t('expirationDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'billNumber',
      i18n: capitalize(i18n.t('billNumber')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'signedDocumentNumber',
      i18n: capitalize(i18n.t('signedDocumentNumber')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'customerCompany',
      i18n: capitalize(i18n.tc('company')),
      subFields: ['name'],
      type: 'text',
    },
    {
      field: 'customerIndividual',
      i18n: capitalize(i18n.tc('individual')),
      subFields: ['name', 'firstName'],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  offers: [],
  emptyOffer: {
    billingDate: (new Date()).toISOString().slice(0, 10),
    expirationDate: expirationDate.toISOString().slice(0, 10),
    paymentMethod: {
      id: 1,
      name: i18n.t('Bank transfert')
    },
    paymentTerm: {
      id: 3,
      term: i18n.t('30 days')
    },
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  fieldFilters: [
    {
      name: 'billNumber',
      type: 'text',
      filter: {}
    },
    {
      name: 'title',
      type: 'text',
      filter: {}
    },
    {
      name: 'preTaxAmount',
      type: 'text',
      filter: {}
    },
    {
      name: 'totalAmount',
      type: 'text',
      filter: {}
    },
    {
      name: 'billingDate',
      type: 'date',
      filter: {}
    },
    {
      name: 'company',
      type: 'text',
      entity: 'customerCompany_name',
      filter: {}
    },
    {
      name: 'individual',
      type: 'text',
      entity: 'customerIndividual_name',
      filter: {}
    },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billNumber')),
      translateTitle: true,
      key: 'billNumber',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('signedDocumentNumber')),
      translateTitle: true,
      key: 'signedDocumentNumber',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('signedDocumentDate')),
      translateTitle: true,
      key: 'signedDocumentDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('expirationDate')),
      translateTitle: true,
      key: 'expirationDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('billingDate')),
      translateTitle: true,
      key: 'billingDate',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: '_status',
      sortable: false,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('customer')),
      translateTitle: true,
      key: 'customerCompany',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('title')),
      translateTitle: true,
      key: 'title',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('preTaxAmount')),
      translateTitle: true,
      key: 'preTaxAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('totalAmount')),
      translateTitle: true,
      key: 'totalAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('billablePreTaxAmountBalance')),
      translateTitle: true,
      key: '_billable_preTaxAmount_balance',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
  ],
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'article',
      'billingAddress',
      'childs',
      'customerCompany',
      'customerIndividual',
      'deliveryAddress',
      'paymentTerm',
      'paymentMethod',
      'phase',
      'vatRate',
      'workflow',
      'signedDocument',
      'toInvoice',
      'outgoingInvoice',
      'quotes',
      'workFlo'
    ],
    collection: [
      'products'
    ]
  },
  offersContext: {
    page: 1,
    filters: []
  }
}