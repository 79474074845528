export default {
  SET_DEPARTMENTS (state, departments) {
    state.departments = departments
  },
  SET_HIERARCHICALDEPARTMENTS (state, hierarchicalDepartments) {
    state.hierarchicalDepartments = hierarchicalDepartments
  },
  ADD_DEPARTMENT (state, department) {
    state.departments.unshift(department)
  },
  UPDATE_DEPARTMENT (state, department) {
    let index = state.departments.findIndex(i => i.id == department.id)
    state.departments.splice(index, 1, JSON.parse(JSON.stringify(department)))
  },
  REMOVE_DEPARTMENT (state, department) {
    state.departments = state.departments.filter(item => item.id != department.id)
  },
  SET_DEPARTMENTSCONTEXT (state, departmentsContext) {
    state.departmentsContext = departmentsContext
  },
  UPDATE_DEPARTMENTSTOTALRESULT (state, isAddDepartment) {
    if (isAddDepartment) {
      state.departmentsContext.total_results++
    } else {
      state.departmentsContext.total_results--
    }
  },
  SET_AUTOCOMPLETEDEPARTMENTS (state, autocompleteDepartments) {
    state.autocompleteDepartments = autocompleteDepartments
  },
}