export default [
  // Dashboard
  {
    path: '/parameters',
    name: 'General parameters',
    component: () => import('../../views/parameters/Parameters.vue'),
    meta: {
      resource: 'GeneralParameters',
      action: 'manage',
    }
  },
]
