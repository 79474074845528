import { capitalize } from '../../utils/filter'
import i18n from '../../libs/i18n'

export default {
  availableFilters: [
    {
    name: '_bankReconciliationStatus',
    field: '_bankReconciliationStatus',
    i18n: capitalize(i18n.t('bankReconciliationStatus')),
    subFields: [],
    isSpecific: true,
    type: 'select',
    options: [
      {
        i18n: capitalize(i18n.t('reconciliated')),
        value: 'reconciliated'
      },
      {
        i18n: capitalize(i18n.t('unreconciliated')),
        value: 'unreconciliated'
      },
      {
        i18n: capitalize(i18n.t('partialReconciliation')),
        value: 'partially reconciliated'
      }
    ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  },
    {
      name: '_bankTransactions_relations',
      field: '_bankTransactions_relations',
      i18n: capitalize(i18n.t('invoices')),
      subFields: [],
      isSpecific: true,
      type: 'text'
    },
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'date',
      i18n: capitalize(i18n.t('date')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'wording',
      i18n: capitalize(i18n.t('wording')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'debit',
      i18n: capitalize(i18n.tc('debit')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'credit',
      i18n: capitalize(i18n.tc('credit')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'paymentMethod',
      i18n: capitalize(i18n.t('paymentMethod')),
      subFields: ['name'],
      type: 'string',
    },
    {
      field: 'bankAccount',
      i18n: capitalize(i18n.t('bankAccount')),
      subFields: [],
      type: 'string',
      show: false
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    {
      title: capitalize(i18n.t('date')),
      translateTitle: true,
      key: 'date',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('wording')),
      translateTitle: true,
      key: 'wording',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('beneficiaries')),
      translateTitle: true,
      key: '_paymentBeneficiaries',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('paymentMethod')),
      translateTitle: true,
      key: 'paymentMethod',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('debit')),
      translateTitle: true,
      key: 'debit',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('credit')),
      translateTitle: true,
      key: 'credit',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
  ],
  bankTransactions: [],
  bankTransactionsIndicator: {},
  emptyBankTransaction: {
    files: [],
    priority: 'Normal',
    type: 'Issue',
    status: 'New'
  },
  fieldFilters: [
    {
      isQuickFilter: true,
      name: 'reconciliated',
      filter: {},
      value: {
        comparator: 'is_not_empty',
        field: 'payments_id'
      }
    },
    {
      isQuickFilter: true,
      name: 'unreconciliated',
      filter: {},
      value: {
        comparator: 'is_empty',
        field: 'payments_id'
      }
    },
    {
      name: 'debit',
      type: 'text',
      filter: {}
    },
    {
      name: 'credit',
      type: 'text',
      filter: {}
    },
    // {
    //   name: 'bankAccount',
    //   type: 'entity',
    //   entityStore: 'bankAccount/getAutocompleteBankAccounts',
    //   multiple: false,
    //   filter: {}
    // },
    {
      name: 'paymentMethod',
      type: 'entity',
      entityStore: 'paymentMethod/getAutocompletePaymentMethods',
      entityLabel: 'name',
      multiple: false,
      filter: {}
    },
    {
      name: 'date',
      type: 'date',
      filter: {}
    },
    // {
    //   name: 'status',
    //   type: 'list',
    //   data: ['Prospect', 'Lead', '...'],
    //   entity: 'status_name',
    //   filter: {}
    // },
    // {
    //   name: 'createdBy',
    //   type: 'employee',
    //   multiple: false,
    //   filter: {}
    // },
  ],
  params: {
    entity: [
      'attachment',
      'attachmentCategory',
      'linkedTransaction',
      'payments',
      'paymentMethod',
    ],
    collection: []
  },
  bankTransactionsContext: {
    page: 1,
    filters: []
  }
}