export default [
  // Dashboard
  {
    path: '/salesdashboard',
    name: 'Sales dashboard',
    component: () => import('../../views/sales/dashboard/Dashboard'),
    meta: {
      resource: 'SalesDashboard',
      action: 'manage',
    }
  },
  // Offers
  {
    path: '/offers',
    name: 'Offers',
    component: () => import('../../views/sales/offers2/Offers3.vue'),
    meta: {
      resource: 'Offers',
      // action: 'read',
    }
  },
  {
    path: '/offers2',
    name: 'Offers2',
    component: () => import('../../views/sales/offers2/Offers3.vue'),
    meta: {
      resource: 'Offers2',
      // action: 'read',
    }
  },
  {
    path: '/offers/:offerId',
    name: 'Offer view',
    component: () => import('@/views/sales/offers/OfferView.vue'),
    meta: {
      navActiveLink: 'Offers',
      resource: 'Offers',
      // action: 'read',
    }
  },
  {
    path: '/offers/:offerId/edit',
    name: 'Offer edit',
    component: () => import('@/views/sales/offers/OfferEdit.vue'),
    meta: {
      navActiveLink: 'Offers',
      resource: 'Offers',
      // action: 'read',
    }
  },

  // Outgoing invoices
  {
    path: '/outgoingInvoices',
    name: 'OutgoingInvoices',
    component: () => import('@/views/sales/outgoingInvoices/OutgoingInvoices.vue'),
    meta: {
      resource: 'OutgoingInvoices',
      // action: 'read',
    }
  },
  {
    path: '/outgoingInvoices/:outgoingInvoiceId',
    name: 'OutgoingInvoice view',
    component: () => import('@/views/sales/outgoingInvoices/OutgoingInvoiceView.vue'),
    meta: {
      navActiveLink: 'OutgoingInvoices',
      resource: 'OutgoingInvoices',
      // action: 'read',
    }
  },
  {
    path: '/outgoingInvoices/:outgoingInvoiceId/edit',
    name: 'OutgoingInvoice edit',
    component: () => import('@/views/sales/outgoingInvoices/OutgoingInvoiceEdit.vue'),
    meta: {
      navActiveLink: 'OutgoingInvoices',
      resource: 'OutgoingInvoices',
      // action: 'read',
    }
  },
  // Customer invoices Beta
  {
    path: '/customerInvoices',
    name: 'Customer invoices3',
    component: () => import('../../views/sales/customerInvoice/CustomerInvoices3.vue'),
    meta: {
      resource: 'CustomerInvoices',
      // action: 'read',
    }
  },
]
