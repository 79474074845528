import state from './companyState';
import mutations from './companyMutations';
import actions from './companyActions';
import getters from "./companyGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}