import state from './offerState';
import mutations from './offerMutations';
import actions from './offerActions';
import getters from "./offerGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}