export default {
  socialSecurityContributions: [],
  emptySocialSecurityContribution: {
    month: (new Date()).toISOString().slice(0, 10),
    dueDate: (new Date()).toISOString().slice(0, 10),
    paymentMethod: 1,

  },
  params: {
    entity: [
      'bankOperations',
      'document',
      'paymentMethod',
      'supplier'
    ],
    collection: []
  },
  socialSecurityContributionsContext: {}
}