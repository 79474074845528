import i18n           from '@/libs/i18n'
// import router         from '@/router'
import moment         from 'moment'
import store          from '@/store'
import api            from '@/libs/axios'
import { computed }   from '@vue/composition-api'
import { capitalize } from './filter'
import router         from '../router'

export const isProdEnv = () => {
  return !api.defaults.baseURL.includes('8000')
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isEmptyObject = obj => !obj || (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype)

export const getObjectId = obj => {
  return obj != null ? (isObject(obj) ? ((obj != null && 'id' in obj) ? obj.id : 0) : isNaN(obj) ? 0 : obj) : 0
}

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const initials = text => {
  let initials = JSON.parse(JSON.stringify(text)).split(' ')
  let output = ''

  if (initials.length > 1) {
    // initials = initials.shift().charAt(0) + initials.pop().charAt(0)
    initials.forEach(i => {
      output += i.charAt(0).toUpperCase()
    })
  } else {
    output = text.substring(0, 2).toUpperCase()
  }

  return output
}

export const initialsFromEmail = email => {
  let text = email.substring(0, email.indexOf('@'))
  let initials = JSON.parse(JSON.stringify(text)).split('.')
  let output = ''

  if (initials.length > 1) {
    // initials = initials.shift().charAt(0) + initials.pop().charAt(0)
    initials.forEach(i => {
      output += i.charAt(0).toUpperCase()
    })
  } else {
    output = text.substring(0, 2).toUpperCase()
  }

  return output
}

// export const getDate = () => {
//   if (i18n.locale == 'fr') {
//     return moment().format('DD/MM/YYYY')
//   } else {
//     return moment().format('YYYY-MM-DD')
//   }
// }
//
// export const getDateTime = () => {
//   if (i18n.locale == 'fr') {
//     return moment().format('DD/MM/YYYY HH:mm')
//   } else {
//     return moment().format('YYYY-MM-DD HH:mm')
//   }
// }

export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') !== null
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  // if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const comparableString = (item) => {
  if (item == null) {
    return ''
  }

  if (typeof item === 'number') {
    item = item.toString()
  }
  return item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const sortCompare = key => (a, b) => {
  if (a[key] == null || b[key] == null) {
    return 0
  }

  const fieldA = comparableString(a[key])
  const fieldB = comparableString(b[key])

  return fieldA === fieldB ? 0 : fieldA < fieldB ? -1 : 1
}

export const getRoute = () => {
  return router.history.current
}

export const getCurrentFinancialYear = () => {
  let now = moment()
  if (now > moment(now.format('YYYY') + '-07-01')) {
    return {
      start: now.format('YYYY') + '-07-01',
      end: (parseInt(now.format('YYYY')) + 1) + '-06-30'
    }
  } else {
    return {
      start: (parseInt(now.format('YYYY')) - 1) + '-07-01',
      end: now.format('YYYY') + '-06-30'
    }
  }
}

export const getPreviousFinancialYear = () => {
  let now = moment()
  if (now > moment(now.format('YYYY') + '-07-01')) {
    return {
      start: (parseInt(now.format('YYYY')) - 1) + '-07-01',
      end: now.format('YYYY') + '-06-30'
    }
  } else {
    return {
      start: (parseInt(now.format('YYYY')) - 2) + '-07-01',
      end: (parseInt(now.format('YYYY')) - 1) + '-06-30'
    }
  }
}

export const eventToCalendarEvent = (event) => {
  let calendarsName = [event.logs[0].by.firstName + ' ' + event.logs[0].by.name]
  event.additionalMembers.forEach(am => {
    calendarsName.push(am.firstName + ' ' + am.name)
  })

  let color = 'secondary'
  if (event.additionalMembers.length != 0) {
    color = 'multipleMembersEvent'
  } else if (getUserData().id == event.logs[0].by.id) {
    color = 'primary'
  }
  // console.log(event, color)

  calendarsName.forEach(cn => {
    if (!store.getters['calendar/getCalendars'].some(c => c.name == cn)) {
      store.commit('calendar/ADD_CALENDAR', {
        name: cn,
        color: color
      })
    }
  })

  // if (!store.getters['calendar/getCalendars'].some(c => calendarsName.includes(c.name))) {
  //   store.commit('calendar/ADD_CALENDAR', {
  //     name: c.name,
  //     color: color
  //   })
  // }

  let title = event.title != null ? event.title : (event.type == 'holiday' ? i18n.t('Holidays') : '')
  // if (event.notes != null && event.title != 'Email envoyé') {
  //   title += '<div style="font-size: .7rem;"><i>' + event.notes + '</i></div>'
  // }

  return {
    id: event.id,
    title: title,
    start: event.startDate,
    end: (event.type == 'holiday' && event.isAllDay) ? moment(event.endDate).add(1, 'days').toJSON() : event.endDate,
    allDay: event.isAllDay,
    editable: (!event.isValidated && event.type != 'holiday' && color != 'secondary'),
    extendedProps: {
      calendars: calendarsName,
      color: color,
      entity: event,
      icons: getEventIcons(event),
      // secondaryIcon: (event.type == 'holiday' && event.isValidated == null) ? 'stopwatch' : null
    }
  }
}

export const getEventIcons = (event) => {
  let icons = []
  if (event._filters[3].value == false && event.type != 'holiday') {
    icons.push('exclamation-triangle')
  }

  if (event.additionalMembers && event.additionalMembers.length != 0) {
    icons.push('users')
  }

  if (event.notes != null && event.title != 'Email envoyé') {
    icons.push('clipboard')
  }

  if (event.type == 'default') {
    // icons.push('user-cog')
  } else if (event.type == 'call') {
    icons.push('phone')
  } else if (event.type == 'visio') {
    icons.push('headset')
  } else if (event.type == 'site') {
    icons.push('briefcase')
  } else if (event.type == 'trip') {
    icons.push('suitcase-rolling')
  } else if (event.type == 'holiday') {
    icons.push('umbrella-beach')
    if (event.isValidated == null) {
      icons.push('stopwatch')
    }
  }

  return icons
}

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
export const setRepetitionDisplay = (repetition) => {
  if (
    typeof repetition != 'undefined' &&
    repetition != null &&
    'period' in repetition &&
    'quantity' in repetition
  ) {

    if (repetition.period == 'days') {
      // Days

      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allDays0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allDays', { days: repetition.quantity }))
      } else {
        delete repetition._display
      }
    } else if (repetition.period == 'weeks') {
      // Weeks

      repetition.days.sort((a, b) => (days.indexOf(a) > days.indexOf(b)) ? 1 : ((days.indexOf(b) > days.indexOf(a)) ? -1 : 0))

      let displayDays = []
      repetition.days.forEach(d => {
        displayDays.push(i18n.t(d))
      })
      displayDays = displayDays.join(', ')
      // console.log(displayDays)
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allWeeks0'))
        repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allWeeks', { weeks: repetition.quantity }))
        repetition._display += '<br /><small>' + capitalize(displayDays) + '</small>'
      } else {
        delete repetition._display
      }

    } else if (repetition.period == 'months') {
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allMonths0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allMonths', { months: repetition.quantity }))
      } else {
        delete repetition._display
      }
    } else if (repetition.period == 'years') {
      if (parseInt(repetition.quantity) == 1) {
        repetition._display = capitalize(i18n.t('allYears0'))
      } else if (parseInt(repetition.quantity) > 1) {
        repetition._display = capitalize(i18n.t('allYears', { years: repetition.quantity }))
      } else {
        delete repetition._display
      }
    }
  }
}

export const displayPaymentStatus = (paymentStatus) => {
  if (paymentStatus == 'Unlettered') {
    // if (paymentStatus == 'Waiting for payment') {
    return {
      variant: 'info',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Lettered') {
    // } else if (paymentStatus == 'Paid') {
    return {
      variant: 'success',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Partially lettered') {
    // } else if (paymentStatus == 'Partial payment') {
    return {
      variant: 'warning',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Unlettered') {
    // } else if (paymentStatus == 'Unpaid') {
    return {
      variant: 'danger',
      value: i18n.t(paymentStatus)
    }
  } else if (paymentStatus == 'Open') {
    return {
      variant: 'secondary',
      value: i18n.t(paymentStatus)
    }
  }
}

export const downloadByDocument = (documentEntity) => {
  if ('url' in documentEntity && documentEntity.url != null) {
    let link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href = documentEntity.url
    link.click()
  }
}

export const isSameObjectsContent = (obj1, obj2) => {
  return JSON.stringify(obj1) == JSON.stringify(obj2)
}

export const parseUTC = (date) => {
  // console.log(JSON.parse(JSON.stringify(date)))
  // console.log(date.getTime())
  // console.log(date.toUTCString())
  // console.log(date.toISOString())
  // console.log(date.toISOString().slice(-4))

  // if (date.toISOString().slice(-4) != "000Z") {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
  // } else {
  //   return date
  // }
}

export const debounce = (fn, wait) => {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer) // clear any pre-existing timer
    }
    const context = this // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args) // call the function if time expires
    }, wait)
  }
}

export const clone = item => {
  if (item == null) {
    return null
  } else {
    return JSON.parse(JSON.stringify(item))
  }
}

export const arrayUtcToTz = datas => {
  const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone
  const regexDate = new RegExp('^(?:\\d{4})-(?:\\d{2})-(?:\\d{2})T(?:\\d{2}):(?:\\d{2}):(?:\\d{2})(?:\\+\\d{2}):(?:\\d{2})$')

  datas.forEach((data, i) => {
    for (const [key, value] of Object.entries(data)) {
      if (regexDate.test(value)) {
        // datas[i][key] = convertTZ(value, tzString)
        datas[i][key] = new Date((typeof value === 'string' ? new Date(value) : value).toLocaleString('en-US', { timeZone: tzString }))
      }
    }
  })
}

export const goToNewTab= route => {
  window.open(
    route,
    '_blank'
  )

  // console.log(router.resolve({name: 'WorkFlo view', params: { workFloId: $event.id }}))
  //
  // window.open(
  //   router.resolve({name: 'WorkFlo view', params: { workFloId: $event.id }}).href,
  //   '_blank'
  // )
}

export const escapeHtml = unsafe => {
  return unsafe.replaceAll('</p>', ' ').replace(/<[^>]+>/g, '');
}