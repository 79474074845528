import i18n from '@/libs/i18n'

// const translateTerms = (bankAccount) => {
//   if ('paymentMethod' in bankAccount) {
//     bankAccount.paymentMethod.name = i18n.t(bankAccount.paymentMethod.name)
//   }
// }

export default {
  SET_BANKACCOUNTS (state, bankAccounts) {
    // bankAccounts.forEach(ba => {
    //   ba.bankOperations.forEach(b => {
    //     translateTerms(b)
    //   })
    // })
    state.bankAccounts = bankAccounts
  },
  ADD_BANKACCOUNT (state, bankAccount) {
    state.bankAccounts.push(bankAccount)
  },
  UPDATE_BANKACCOUNT (state, bankAccount) {
    let index = state.bankAccounts.findIndex(i => i.id == bankAccount.id)
    state.bankAccounts.splice(index, 1, JSON.parse(JSON.stringify(bankAccount)))
  },
  REMOVE_BANKACCOUNT (state, bankAccount) {
    state.bankAccounts = state.bankAccounts.filter(item => item.id != bankAccount.id)

    // let index = state.bankAccounts.findIndex(i => i.id === bankAccount.id)
    // state.bankAccounts.splice(index, 1)
  },
  ADD_BANKOPERATION (state, bankOperation) {
    // let bankAccountLocal =
    bankOperation.paymentMethod.name = i18n.t(bankOperation.paymentMethod.name)
    state.bankAccounts.find(ba => ba.id == bankOperation.bankAccount.id).bankOperations.push(bankOperation)
  },
  UPDATE_BANKOPERATION (state, bankOperation) {
    bankOperation.paymentMethod.name = i18n.t(bankOperation.paymentMethod.name)
    let bankAccount = state.bankAccounts.find(i => i.id == bankOperation.bankAccount.id)
    let bankOperationIndex = bankAccount.bankOperations.findIndex(i => i.id == bankOperation.id)
    bankAccount.bankOperations.splice(bankOperationIndex, 1, JSON.parse(JSON.stringify(bankOperation)))
  },
  REMOVE_BANKOPERATION (state, bankOperation) {
    let bankAccount = state.bankAccounts.find(i => i.id == bankOperation.bankAccount.id)
    let bankOperationIndex = bankAccount.bankOperations.findIndex(i => i.id == bankOperation.id)
    bankAccount.bankOperations.splice(bankOperationIndex, 1)
  },

  SET_BANKACCOUNTSCONTEXT (state, bankAccountsContext) {
    state.bankAccountsContext = bankAccountsContext
  },
  UPDATE_BANKACCOUNTSCONTEXTTOTALRESULT (state, isAddBankAccount) {
    if (isAddBankAccount) {
      state.bankAccountsContext.total_results++
    } else {
      state.bankAccountsContext.total_results--
    }
  },

  SET_AUTOCOMPLETEBANKACCOUNTS (state, autocompleteBankAccounts) {
    state.autocompleteBankAccounts = autocompleteBankAccounts
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEBANKACCOUNT (state, payload) {
    state.autocompleteBankAccounts.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEBANKACCOUNT (state, payload) {
    console.log(payload)
    let index = state.autocompleteBankAccounts.findIndex(i => i.id === payload.bankAccount.id)
    state.autocompleteBankAccounts[index].name = payload.bankAccount.name
  },
  SET_BANKACCOUNTINDICATORS (state, bankAccountIndicators) {
    state.bankAccountIndicators = bankAccountIndicators
  },
  SET_BANKACCOUNTSINDICATORS (state, bankAccountsIndicators) {
    state.bankAccountsIndicators = bankAccountsIndicators
  },
}