export default {
  getAvailableFilters: (state) => state.availableFilters,
  getReminders: (state) => state.reminders,
  getCalendarReminders: (state) => {
    let calendarReminders = []

    state.reminders.forEach(r => {
      calendarReminders.push(r._calendarEvent)
    })
    return calendarReminders
  },
  getRemindersContext: (state) => state.remindersContext,
  getReminder: (state) => (id) => {
    return state.reminders.find(reminder => reminder.id === parseInt(id))
  },
  getEmptyReminder: (state) => JSON.parse(JSON.stringify(state.emptyReminder)),
  getFieldFilters: (state) => state.fieldFilters,
}