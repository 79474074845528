import state from './documentCategoryState';
import mutations from './documentCategoryMutations';
import actions from './documentCategoryActions';
import getters from "./documentCategoryGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}