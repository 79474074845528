import moment from 'moment'

export default {
  bankStatements: [],
  emptyBankStatement: {},
  params: {
    entity: [
      'bankAccount',
      'bankOperations',
      'document',
      'paymentMethod',
      'incomingInvoices'
    ],
    collection: [
      // 'bankOperations'
    ]
  }
}