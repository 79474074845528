export default {
  SET_REMINDERS (state, reminders) {
    state.reminders = reminders
  },
  ADD_REMINDER (state, reminder) {
    state.reminders.unshift(reminder)
  },
  UPDATE_REMINDER (state, reminder) {
    let index = state.reminders.findIndex(i => i.id == reminder.id)
    state.reminders.splice(index, 1, JSON.parse(JSON.stringify(reminder)))
  },
  REMOVE_REMINDER (state, reminder) {
    state.reminders = state.reminders.filter(item => item.id != reminder.id)

    // let index = state.reminders.findIndex(i => i.id === reminder.id)
    // state.reminders.splice(index, 1)
  },
  SET_REMINDERSCONTEXT (state, remindersContext) {
    state.remindersContext = remindersContext
  },
  UPDATE_REMINDERSTOTALRESULT (state, isAddReminder) {
    if (isAddReminder) {
      state.remindersContext.total_results++
    } else {
      state.remindersContext.total_results--
    }
  },
}