import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'
import moment         from 'moment'

const now = moment()

export default {
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'completionDate',
      i18n: capitalize(i18n.t('completionDate')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'type',
      i18n: capitalize(i18n.t('type')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'priority',
      i18n: capitalize(i18n.t('priority')),
      subFields: [],
      type: 'boolean',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    {
      title: capitalize(i18n.t('title')),
      translateTitle: false,
      key: 'title',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
  ],
  reminders: [],
  isKanbanWorkFloList: false,
  fieldFilters: [
    {
      name: 'title',
      type: 'text',
      filter: {}
    },
    {
      name: 'completionDate',
      type: 'date',
      filter: {}
    },
    // {
    //   name: 'type',
    //   type: 'list',
    //   data: ['holiday'],
    //   isDisabled: true,
    //   filter: {}
    // },
    {
      name: 'createdBy',
      type: 'employee',
      multiple: false,
      filter: {}
    },
  ],
  remindersContext: {
    page: 1,
    filters: []
  },
  emptyReminder: {
    additionalMembers: [],
    completionDate: now.format('YYYY-MM-DD'),
    discussionPlatform: null,
    documents: [],
    endPlace: null,
    isClosed: false,
    isOnlineMeeting: false,
    isOutgoingCall: false,
    isTripWithPersonalVehicle: false,
    notes: null,
    phase: null,
    place: null,
    priority: "low",
    startPlace: null,
    steps: [],
    title: null,
    type: 'work',
    visioLink: null,
    workFlo: null,
    workflow: null,
  },
  params: {
    entity: [
      'phase',
      'workflow',
      'documents',
      'additionalMembers',
      'workFlo'
    ],
    collection: [
      'place',
      'startPlace',
      'endPlace',
      'steps'
    ]
  }
}