import api                                                 from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend }            from '../../utils/form'
import Vue                                                 from 'vue'
import ToastificationContent                               from '@/components/toastification/ToastificationContent.vue'
import { capitalize }                                      from '@/utils/filter'
import i18n                                                from '@/libs/i18n'
import store                                               from '../index'
import useAppConfig                                        from '../../utils/useAppConfig'
import useAPI                                              from '../../utils/useAPI'
import qs                                                  from 'qs'
import { arrayUtcToTz, clone, departmentToCalendarDepartment } from '../../utils/utils'
import router                                              from '../../router'

export default {
  getDepartments ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/departments' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data.results)

          commit('SET_DEPARTMENTS', response.data.results)
          commit('SET_DEPARTMENTSCONTEXT', response.data.page_context)

          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getHierarchicalDepartments ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/department/hierarchical' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data)

          commit('SET_HIERARCHICALDEPARTMENTS', response.data)

          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getDepartment ({ commit, state }, departmentId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/departments/' + departmentId)
        .then(response => {
          arrayUtcToTz([response.data])

          addOrUpdateInList(response.data, state.departments, 'department', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addDepartment ({ commit, state }, department) {
    return await cleanEntityToSend(department, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.post('/departments', clonedEntityToSend)
            .then(response => {
              commit('ADD_DEPARTMENT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The department has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateDepartment ({ commit, state }, department) {
    return await cleanEntityToSend(department, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.put('/departments/' + department.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_DEPARTMENT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The department has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeDepartment ({ commit }, department) {
    return new Promise((resolve, reject) => {
      api.delete('/departments/' + department.id)
        .then(response => {
          commit('REMOVE_DEPARTMENT', department)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The department has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteDepartments ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/autocomplete/departments' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_AUTOCOMPLETEDEPARTMENTS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  }

}