export default {
  autocompleteModuleViews: [],
  fieldFilters: [
    {
      name: 'name',
      type: 'name',
      filter: {}
    },
    {
      name: 'module',
      type: 'module',
      filter: {}
    },
    {
      name: 'type',
      type: 'type',
      filter: {}
    },
    {
      name: 'byDefault',
      type: 'isDefault',
      filter: {}
    },
    {
      name: 'createdBy',
      type: 'employee',
      multiple: false,
      filter: {}
    },
  ],
  moduleViews: [],
  emptyModuleView: {
    filters: [],
    listColumns: [],
    sorts: []
  },
  params: {
    entity: [],
    collection: []
  },
  moduleViewsContext: {}
}