import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getOrders ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/orders'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_ORDERS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'order', limit, offset, state.orders, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            store.commit('app/REMOVE_LOADER')
            resolve(true)
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getOrder ({ commit, state }, orderId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/orders/'+orderId)
        .then(response => {
          addOrUpdateInList(response.data, state.orders, 'order',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addOrder ({ commit, state }, order) {
    return await cleanEntityToSend(order, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/orders', clonedEntityToSend)
            .then(response => {
              commit('ADD_ORDER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The order has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateOrder ({ commit, state }, order) {
    return await cleanEntityToSend(order, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/orders/' + order.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_ORDER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The order has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeOrder ({ commit }, order) {
    return new Promise((resolve, reject) => {
      api.delete('/orders/' + order.id)
        .then(response => {
          commit('REMOVE_ORDER', order)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The order has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  async validateOrder ({ commit, state }, order) {
    return new Promise((resolve, reject) => {
      api.put('/orders/' + order.id + '/validate')
        .then(response => {
          commit('UPDATE_ORDER', response.data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The order has been ', { crud: i18n.t('edited') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  printOrder ({ commit }, order) {
    return new Promise((resolve, reject) => {
      api.get('/orders/' + order.id + '/pdf')
        .then(response => {

          let byteCharacters = atob(response.data)
          let byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          let byteArray = new Uint8Array(byteNumbers)
          let blob = new Blob([byteArray], {
            type: 'application/pdf'
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = capitalize(i18n.tc('order')) + ' - ' + (order.number ? order.number : i18n.t('draft')) + '.pdf'
          link.click()
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}