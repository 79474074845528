import moment from 'moment'

export default {
  tasks: [  ],
  emptyTask: {
    'documents': [],
    'steps': []
  },
  params: {
    entity: [
      'phase',
      'workflow',
      'documents'
    ],
    collection: [
      'repetition',
      'steps'
    ]
  }
}