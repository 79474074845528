export default {
  appName: 'ERP',
  appAPIUrl: 'http://192.168.1.11:8000/',
  breakpoints: {
    xs: 0,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
    xxl: 1440
  },
  windowWidth: 0,
  isVerticalMenuCollapsed: true,
  isNavMenuHidden: false,
  shallShowOverlay: false,
  isLoading: false,
  emailLoaders: 0,
  loader: 0,
  isRTL: false,
}