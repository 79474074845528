import api                                                 from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend }            from '../../utils/form'
import Vue                                                 from 'vue'
import ToastificationContent                               from '@/components/toastification/ToastificationContent.vue'
import { capitalize }                                      from '@/utils/filter'
import i18n                                                from '@/libs/i18n'
import store                                               from '../index'
import useAppConfig                                        from '../../utils/useAppConfig'
import useAPI                                              from '../../utils/useAPI'
import qs                                                  from 'qs'
import { arrayUtcToTz, clone, templateToCalendarTemplate } from '../../utils/utils'
import router                                              from '../../router'

export default {
  getTemplates ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/templates' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          arrayUtcToTz(response.data.results)

          commit('SET_TEMPLATES', response.data.results)
          commit('SET_TEMPLATESCONTEXT', response.data.page_context)

          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getTemplate ({ commit, state }, templateId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/templates/' + templateId)
        .then(response => {
          arrayUtcToTz([response.data])

          let template = response.data
          if (template.type == 'event') {
            if (Array.isArray(template.template.endPlace) && template.template.endPlace.length == 0) {
              // template.template.endPlace = null
              delete template.template.endPlace
            }
            if (Array.isArray(template.template.place) && template.template.place.length == 0) {
              // template.template.place = null
              delete template.template.place
            }
            if (Array.isArray(template.template.startPlace) && template.template.startPlace.length == 0) {
              // template.template.startPlace = null
              delete template.template.startPlace
            }
          }

          Object.keys(template.template).forEach(key => {
            if (
              key.startsWith('is') &&
              (
                template.template[key] == '0' ||
                template.template[key] == '1'
              )
            ) {
              template.template[key] == '0' ? (template.template[key] = false) : (template.template[key] = true)
            }
          })

          addOrUpdateInList(template, state.templates, 'template', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addTemplate ({ commit, state }, template) {
    // console.log(template)
    delete template.subType
    if (template.type == "email") {
      template.template.attachments.forEach(a => {
        delete a._base64
      })
    }

    let duration = clone(template.template._duration)

    return await cleanEntityToSend(template, state.params)
      .then(async clonedEntityToSend => {
        if (duration != null) {
          clonedEntityToSend.template._duration = duration
        }

        return new Promise((resolve, reject) => {
          api.post('/templates', clonedEntityToSend)
            .then(response => {
              commit('ADD_TEMPLATE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The template has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateTemplate ({ commit, state }, template) {
    if (template.type == "email") {
      template.template.attachments.forEach(a => {
        delete a._base64
      })
    }

    let duration = clone(template.template._duration)
    return await cleanEntityToSend(template, state.params)
      .then(async clonedEntityToSend => {
        if (duration != null) {
          clonedEntityToSend.template._duration = duration
        }

        return new Promise((resolve, reject) => {
          api.put('/templates/' + template.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_TEMPLATE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The template has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeTemplate ({ commit }, template) {
    return new Promise((resolve, reject) => {
      api.delete('/templates/' + template.id)
        .then(response => {
          commit('REMOVE_TEMPLATE', template)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The template has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteTemplates ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/autocomplete/templates' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_AUTOCOMPLETETEMPLATES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  }

}