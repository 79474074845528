export default {
  SET_ACCOUNTINGLINES (state, accountingLines) {
    state.accountingLines = accountingLines
  },
  SET_HIERARCHICALACCOUNTINGLINES (state, hierarchicalAccountingLines) {
    state.hierarchicalAccountingLines = hierarchicalAccountingLines
  },
  ADD_ACCOUNTINGLINE (state, accountingLine) {
    state.accountingLines.unshift(accountingLine)
  },
  UPDATE_ACCOUNTINGLINE (state, accountingLine) {
    let index = state.accountingLines.findIndex(i => i.id == accountingLine.id)
    state.accountingLines.splice(index, 1, JSON.parse(JSON.stringify(accountingLine)))
  },
  REMOVE_ACCOUNTINGLINE (state, accountingLine) {
    state.accountingLines = state.accountingLines.filter(item => item.id != accountingLine.id)
  },
  SET_ACCOUNTINGLINESCONTEXT (state, accountingLinesContext) {
    state.accountingLinesContext = accountingLinesContext
  },
  UPDATE_ACCOUNTINGLINESTOTALRESULT (state, isAddAccountingLine) {
    if (isAddAccountingLine) {
      state.accountingLinesContext.total_results++
    } else {
      state.accountingLinesContext.total_results--
    }
  },
  SET_AUTOCOMPLETEACCOUNTINGLINES (state, autocompleteAccountingLines) {
    state.autocompleteAccountingLines = autocompleteAccountingLines
  },
}