import state from './mailFolderState';
import mutations from './mailFolderMutations';
import actions from './mailFolderActions';
import getters from "./mailFolderGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}