import api from '@/libs/axios'
import store from '../index'
import { addOrUpdateInList } from '../../utils/form'
import useAPI from '../../utils/useAPI'

export default {
  getVats ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.vats.length == 0) {
        api.get('/vats')
          .then(response => {
            commit('SET_VATS', response.data)
            resolve(response)
          })
          .catch(err => {
            console.log('Error', err)
            reject(err)
          })
      } else {
        resolve(true)
      }
    })
  },
  getVat ({ commit, state }, vatId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/vats/'+vatId)
        .then(response => {
          // addOrUpdateInList(response.data, state.vats, 'vat',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getAutocompleteVats ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/vats'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEVATS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },

}