import { getUserData } from '../../utils/utils'

export default {
  getAutocompleteModuleViews: (state) => state.autocompleteModuleViews,
  getFieldFilters: (state) => state.fieldFilters,
  getModuleViews: (state) => state.moduleViews,
  getModuleViewsContext: (state) => state.moduleViewsContext,
  getModuleView: (state) => (id) => {
    return state.moduleViews.find(moduleView => moduleView.id === parseInt(id))
  },
  getEmptyModuleView: (state) => JSON.parse(JSON.stringify(state.emptyModuleView)),

  getDefaultViewByModule: (state) => (viewType, module) => {
    let view = state.moduleViews.find(m => m.type == viewType && m.id == getUserData().defaultViews[module].id)

    if (view) {
      return view
    } else {
      let view = state.moduleViews.find(m => m.module == module && m.type == viewType && m._createdBy != null && m._createdBy.id == getUserData().id)

      if (view) {
        return view
      } else {
        return state.moduleViews.find(m => m.module == module && m.type == viewType && m._createdBy == null)
      }
    }

    //   let output = state.moduleViews.filter(m => m.module == module && m.isDefault && m._createdBy != null && m._createdBy.id == getUserData().id && m.type == viewType)
    //
    // if (output.length == 1) {
    //   return output[0]
    // } else {
    //   return state.moduleViews.filter(m => m.module == module && m.isDefault && m._createdBy == null && m.type == viewType)[0]
    // }
  },
  getMyConcernedViewsByModule: (state) => (module) => {
    // console.log(state.moduleViews.filter(m => {
    //   if (
    //     m.module == module &&
    //     (m._createdBy == null || (m._createdBy.id == getUserData().id))
    //   ) {
    //     return true
    //   }
    // }))


    return state.moduleViews.filter(m => {
      if (
        m.module == module &&
        (m._createdBy == null || (m._createdBy.id == getUserData().id))
      ) {
        return true
      }
    })
  },
  getCurrentModuleView: (state) => (module) => {
    // console.log(state.moduleViews)
    return state.moduleViews.find(mv=>mv.id == getUserData().defaultViews[module].id)
  },

  getCrmModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'crm')
  },
  getHolidaysModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'holidays')
  },
  getContactCompaniesModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'contactCompanies')
  },
  getContactUsersModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'contactUsers')
  },
  getCashFlowJournalModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'cashFlowJournal')
  },
  getSupplierInvoicesModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'supplierInvoices')
  },
  getOffersModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'offers')
  },
  getCustomerInvoicesModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'customerInvoices')
  },
  getOrdersModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'orders')
  },
  getBankTransactionsModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'bankTransactions')
  },
  getWorkflowsModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'workflows')
  },
  getSupplierReceivablesModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'supplierReceivables')
  },
  getCustomerReceivablesModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'customerReceivables')
  },
  getUsersModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'users')
  },
  getAddressesMapModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'addressesMap')
  },
  getWorkFloMapModuleViews: (state) => {
    return state.moduleViews.filter(m => m.module == 'workFloMap')
  },
}