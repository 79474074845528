import state from './taskState';
import mutations from './taskMutations';
import actions from './taskActions';
import getters from "./taskGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}