export default {
  SET_TEMPLATES (state, templates) {
    state.templates = templates
  },
  ADD_TEMPLATE (state, template) {
    state.templates.unshift(template)
  },
  UPDATE_TEMPLATE (state, template) {
    let index = state.templates.findIndex(i => i.id == template.id)
    state.templates.splice(index, 1, JSON.parse(JSON.stringify(template)))
  },
  REMOVE_TEMPLATE (state, template) {
    state.templates = state.templates.filter(item => item.id != template.id)
  },
  SET_TEMPLATESCONTEXT (state, templatesContext) {
    state.templatesContext = templatesContext
  },
  UPDATE_TEMPLATESTOTALRESULT (state, isAddTemplate) {
    if (isAddTemplate) {
      state.templatesContext.total_results++
    } else {
      state.templatesContext.total_results--
    }
  },
  SET_AUTOCOMPLETETEMPLATES (state, autocompleteTemplates) {
    state.autocompleteTemplates = autocompleteTemplates
  },
}