export default [
  // Dashboard
  {
    path: '/purchasesdashboard',
    name: 'Purchases dashboard',
    component: () => import('../../views/purchases/dashboard/Dashboard'),
    meta: {
      resource: 'PurchasesDashboard',
      action: 'manage',
    }
  },
  // Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../../views/purchases/orders/orders3.vue'),
    meta: {
      resource: 'Orders',
      // action: 'read',
    }
  },
  {
    path: '/orders/:orderId',
    name: 'Order view',
    component: () => import('@/views/purchases/orders/OrderView.vue'),
    meta: {
      navActiveLink: 'Orders',
      resource: 'Orders',
      // action: 'read',
    }
  },
  {
    path: '/orders/:orderId/edit',
    name: 'Order edit',
    component: () => import('@/views/purchases/orders/OrderEdit.vue'),
    meta: {
      navActiveLink: 'Orders',
      resource: 'Orders',
      // action: 'read',
    }
  },
  // Incoming invoices
  {
    path: '/incomingInvoices',
    name: 'Incoming invoices',
    component: () => import('@/views/purchases/incomingInvoicesOld/IncomingInvoices.vue'),
    meta: {
      resource: 'IncomingInvoices',
      // action: 'read',
    }
  },
  // Incoming invoices Beta
  {
    path: '/supplierInvoices',
    name: 'Supplier invoices',
    component: () => import('../../views/purchases/supplierInvoice/SupplierInvoices.vue'),
    meta: {
      resource: 'IncomingInvoices',
      // action: 'read',
    }
  },
]
