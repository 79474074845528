export default [
  // Dashboard
  {
    path: '/ticketsDashboard',
    name: 'Tickets dashboard',
    component: () => import('../../views/support/tickets/Dashboard'),
    meta: {
      resource: 'TicketsDashboard',
      action: 'manage',
    }
  },
  // Tickets
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import('../../views/support/tickets/Tickets'),
    meta: {
      resource: 'Tickets',
      // action: 'read',
    }
  },

]
