export default {
  autocompleteCompanies: [],
  companies: [],
  emptyCompany: {
    addresses: [],
    employees: []
  },
  params: {
    entity: [
      'avatar',
      'outgoingInvoices',
      'billingAddress',
      'deliveryAddress',
      'type'
    ],
    collection: [
      "addresses",
      "employees",
      'contracts'
    ]
  }
}