import i18n from '../../libs/i18n'
import { capitalize } from '../../utils/filter'

export default {
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'discount',
      i18n: capitalize(i18n.t('discount')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'preTaxAmount',
      i18n: capitalize(i18n.t('preTaxAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'totalAmount',
      i18n: capitalize(i18n.t('totalAmount')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'vat',
      i18n: capitalize(i18n.t('vat')),
      subFields: [],
      type: 'number',
    },
    {
      field: 'number',
      i18n: capitalize(i18n.t('number')),
      subFields: [],
      type: 'text',
    },
    {
      field: 'supplier',
      i18n: capitalize(i18n.t('supplierCompany')),
      subFields: ['name'],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  orders: [],
  emptyOrder: {
    documents: [],
    products: [],
    isDiscountPercent: false,
    paymentMethod: 1,
    paymentTerm: 3,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  fieldFilters: [
    {
      name: 'number',
      type: 'text',
      filter: {}
    },
  ],
  availableListColumns: [
    {
      title: capitalize(i18n.t('number')),
      translateTitle: true,
      key: 'number',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('status')),
      translateTitle: true,
      key: 'status',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('supplier')),
      translateTitle: true,
      key: 'supplierCompany',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('preTaxAmount')),
      translateTitle: true,
      key: 'preTaxAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
    {
      title: capitalize(i18n.t('totalAmount')),
      translateTitle: true,
      key: 'totalAmount',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: true
      }
    },
  ],
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'article',
      'company',
      'childs',
      'documents',
      'incomingInvoice',
      'paymentMethod',
      'paymentTerm',
      'phase',
      'receipt',
      'signedDocument',
      'supplierCompany',
      'vatRate',
      'workFlo',
      'workflow',

    ],
    collection: [
      'includedContacts',
      'incomingInvoices',
      'products',
    ]
  },
  ordersContext: {
    page: 1,
    filters: []
  }
}