import { cleanEntityToSend } from '../../utils/form'
import { capitalize }        from '@/utils/filter'

import Vue                                   from 'vue'
import api                                   from '@/libs/axios'
import ToastificationContent                 from '@/components/toastification/ToastificationContent.vue'
import i18n                                  from '@/libs/i18n'
import store                                 from '../index'
import useAPI                                from '../../utils/useAPI'
import qs                                    from 'qs'
import { useMS }                             from '../../utils/useMS'
import moment                                from 'moment'
import { getRoute, isEmptyObject, parseUTC } from '../../utils/utils'
import { useToast }                          from 'vue-toastification/composition'
import { useEmails }                         from '../../components/email/v2/useEmails'

const setUtc = (email) => {

}

const { sentEmailNotification } = useEmails()
export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getEmails ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/emails' + '?' + qs.stringify(payload))
        .then(response => {

          if (payload.page == null || payload.page == 1) {
            commit('SET_EMAILS', response.data.results)
            commit('SET_EMAILSCONTEXT', response.data.page_context)
          } else {
            commit('ADD_EMAILS', response.data.results)
            commit('SET_EMAILSCONTEXT', response.data.page_context)
          }

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  },
  getEmail ({ commit, state }, emailId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/emails/' + emailId

      performApiCall(url, emailId)
        .then(response => {
          store.commit('email/UPDATE_EMAIL', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getEmailWithoutReadUpdate ({ commit, state }, emailId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/emails/' + emailId

      api.get(url + '?' + qs.stringify({
        readUpdate: false
      }))
        .then(response => {
          store.commit('email/UPDATE_EMAIL', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addEmail ({ commit, state }, email) {
    setUtc(email)

    // console.log(email)
    let emailLocal = JSON.parse(JSON.stringify(email))
    // delete emailLocal.attachments
    emailLocal.toRecipients.forEach(toR => {
      // console.log(toR)
      delete toR.emailAddress.name
    })
    // emailLlocal.body = JSON.stringify(email.body)
    return await cleanEntityToSend(emailLocal, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/emails', clonedEntityToSend)
            .then(response => {
              // commit('ADD_EMAIL', response.data)
              // commit('UPDATE_EMAILSCONTEXTTOTALRESULT', 'add')

              // sentEmailNotification()

              Vue.$toast({
                component: ToastificationContent, props: {
                  title: capitalize(i18n.t('email sent')),
                  icon: 'envelope',
                  text: i18n.t('your email has been sent'),
                  variant: 'success'
                },
              })

              // console.log(JSON.parse(JSON.stringify(store.getters['email/getEmails'])))

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateEmail ({ commit, state }, email) {
    setUtc(email)

    delete email.receivedDateTime
    delete email.sentDateTime
    return await cleanEntityToSend(email, state.params)
      .then(async clonedEntityToSend => {
        // console.log(JSON.parse(JSON.stringify(clonedEntityToSend)))

        return new Promise((resolve, reject) => {
          api.put('/emails/' + email.id, clonedEntityToSend)
            .then(response => {
              const toast = useToast()

              setUtc(response.data)
              commit('UPDATE_EMAIL', response.data)

              Vue.$toast({
                component: ToastificationContent, props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The email has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeEmail ({ commit, state }, email) {
    return new Promise((resolve, reject) => {
      api.delete('/emails/' + email.id)
        .then(response => {
          const toast = useToast()

          commit('REMOVE_EMAIL', email)
          commit('UPDATE_EMAILSCONTEXTTOTALRESULT', 'sub')

          Vue.$toast({
            component: ToastificationContent, props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The email has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          // toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: capitalize(i18n.t('success')),
          //     icon: 'check',
          //     text: i18n.t('The email has been ', { crud: i18n.t('removed') }),
          //     variant: 'success'
          //   },
          // })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteEmails ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/emails'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEEMAILS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })
  },
  getRefreshEmails ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')
    store.commit('app/ADD_EMAILLOADER')

    return new Promise((resolve, reject) => {
      let url = '/emails/refresh'

      performApiCall(url, payload)
        .then(response => {
          // console.log(response)
          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')
          store.commit('app/REMOVE_EMAILLOADER')
          localStorage.setItem('isRefreshEmail', false)
          resolve(response)
        })
        .catch(response => {
          store.commit('app/REMOVE_EMAILLOADER')
          localStorage.setItem('isRefreshEmail', false)
          // console.log(response)
          resolve(response)
        })
    })
  },
  downloadAllAttachments ({ commit, state }, emailId) {
    console.log("To do !")
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/emails/' + emailId + '/downloadAttachments'

      performApiCall(url, emailId)
        .then(response => {
          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')
          reject(err)
        })
    })
  },
  // }

}