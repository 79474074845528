export default {
  getAutocompleteEmails: (state) => state.autocompleteEmails,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getEmails: (state) => state.emails,
  getIsKanbanEmailList: (state) => state.isKanbanEmailList,
  getEmailsContext: (state) => state.emailsContext,
  getEmail: (state) => (id) => {
    return state.emails.find(email => email.id === parseInt(id))
  },
  getEmptyEmail: (state) => JSON.parse(JSON.stringify(state.emptyEmail)),
}