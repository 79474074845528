import state from './socialSecurityContributionState';
import mutations from './socialSecurityContributionMutations';
import actions from './socialSecurityContributionActions';
import getters from "./socialSecurityContributionGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}