import {getUserData} from '../../utils/utils'

export default {
  getAutocompleteUsers: (state) => state.autocompleteUsers,
  getAutocompleteEmployees: (state) => state.autocompleteEmployees,
  getUsers: (state) => {
    return state.users.filter(usr => usr.roles.length == 0)
  },
  getUser: (state) => (id) => {
    return state.users.find(user => user.id === parseInt(id))
  },
  getEmptyUser: (state) => state.emptyUser,
  getCurrentEmployees: (state) => {
    return state.users.filter(user => user._isCurrentlyEmployed == true)
  },
  getEmployees: (state) => {
    return state.users.filter(user => user._wasEmployed)
  },
  getEmployeesWithoutMe: (state) => {
    return state.users.filter(user => user._isCurrentlyEmployed && user.id != getUserData().id)
  },
  getEmployeesAndPrevious: (state) => {
    return state.users.filter(user => user.company.id == 1)
  },
  getRoleOptions: (state) => state.roleOptions,
  getMe: (state) => {
    // return state.users.find(user => user.id == parseInt(JSON.parse(localStorage.getItem('userData')).id))
    return state.users.find(user => user.id == getUserData().id)
  },
}