import store from '../index'
import { computed } from '@vue/composition-api'

export default function useTemplateAPI () {
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================

  const fetchAutocompleteTemplates = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('template/getAutocompleteTemplates', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchTemplates = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('template/getTemplates', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const autocompleteTemplates = computed(() => {
    return store.getters['template/getAutocompleteTemplates']
  })

  const templates = computed(() => {
    return store.getters['template/getTemplates']
  })

  const templatesContext = computed(() => {
    return store.getters['template/getTemplatesContext']
  })


  return {
    // Fetchers
    fetchAutocompleteTemplates,
    fetchTemplates,

    // Computed
    autocompleteTemplates,
    templates,
    templatesContext
  }
}