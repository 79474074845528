import i18n from '@/libs/i18n'

const translateTerms = (outgoingInvoice) => {
  if ('paymentMethod' in outgoingInvoice) {
    outgoingInvoice.paymentMethod.name = i18n.t(outgoingInvoice.paymentMethod.name)
  }
  if ('paymentTerm' in outgoingInvoice) {
    outgoingInvoice.paymentTerm.term = i18n.t(outgoingInvoice.paymentTerm.term)
  }
  if ('products' in outgoingInvoice) {
    outgoingInvoice.products.forEach(p => {
      if (p.article.id == 784) {
        p.article.name = i18n.t(p.article.name)
      }
    })
  }
}

export default {
  SET_OUTGOINGINVOICES (state, outgoingInvoices) {
    outgoingInvoices.forEach(oi => {
      translateTerms(oi)
      // oi.paymentMethod.name = i18n.t(oi.paymentMethod.name)
      // oi.paymentTerm.term = i18n.t(oi.paymentTerm.term)
    })

    state.outgoingInvoices = outgoingInvoices
  },
  ADD_OUTGOINGINVOICE (state, outgoingInvoice) {
    translateTerms(outgoingInvoice)

    state.outgoingInvoices.unshift(outgoingInvoice)
  },
  UPDATE_OUTGOINGINVOICE (state, outgoingInvoice) {
    translateTerms(outgoingInvoice)

    let index = state.outgoingInvoices.findIndex(i => i.id == outgoingInvoice.id)
    state.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(outgoingInvoice)))
  },
  REMOVE_OUTGOINGINVOICE (state, outgoingInvoice) {
    state.outgoingInvoices = state.outgoingInvoices.filter(item => item.id != outgoingInvoice.id)
    // let index = state.outgoingInvoices.findIndex(i => i.id === outgoingInvoice.id)
    // state.outgoingInvoices.splice(index, 1)
  },
  SET_OUTGOINGINVOICESCONTEXT (state, outgoingInvoicesContext) {
    state.outgoingInvoicesContext = outgoingInvoicesContext
  },
  UPDATE_OUTGOINGINVOICESTOTALRESULT (state, isAddOutgoingInvoice) {
    if (isAddOutgoingInvoice) {
      state.outgoingInvoicesContext.total_results++
    } else {
      state.outgoingInvoicesContext.total_results--
    }
  },
}