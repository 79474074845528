import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import {isObject} from '../../utils/utils'
import useAPI from '../../utils/useAPI'

export default {
  getSalesIndicator ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    // console.log(payload)

    let url = '/indicators/sales'
    let dates = payload.dates
    let workflowId = (typeof payload.workflowId === "undefined")?'':payload.workflowId
    let phasesIds = (typeof payload.phasesIds === "undefined")?'':payload.phasesIds
    let params = new URLSearchParams({
      start: dates.start,
      end: dates.end,
      workflowId: workflowId,
      phasesIds: phasesIds
    })

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_SALESINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getAwaitingExportSalesIndicator ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    // console.log(payload)

    let url = '/indicators/sales'
    let workflowId = ''
    let phasesIds = ''
    let params = new URLSearchParams({
      workflowId: workflowId,
      phasesIds: phasesIds,
      awaitingExport: true
    })

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_AWAITINGEXPORTSALESINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getExpensesIndicator ({ commit, state }, payload) {
    let url = '/indicators/expenses'
    const { start, end, employees } = payload

    let params = new URLSearchParams()
    if (start != null) {params.append('start', start)}
    if (end != null) {params.append('end', end)}
    params.append('employees', employees)

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_EXPENSESINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getAwaitingExportExpensesIndicator ({ commit, state }) {
    store.commit('app/ADD_LOADER')
    // console.log(payload)

    let url = '/indicators/expenses'
    let params = new URLSearchParams({ awaitingExport: true })

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_AWAITINGEXPORTEXPENSESINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getHRIndicator ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    // console.log(payload)

    let url = '/indicators/hr'
    let employeeId = isObject(payload.employee)?payload.employee.id:payload.employee
    let dates = payload.dates
    let params = new URLSearchParams({ employeeId: employeeId, start: dates.start, end: dates.end })

    return new Promise((resolve, reject) => {
      api.get(url + '?' + params)
        .then(response => {
          commit('SET_HRINDICATOR', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(true)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}