<template>
  <div
      id="app"
      class="h-100"
  >
    <component :is="layout">
      <router-view/>
    </component>

    <support v-if="$route.name != 'Tickets'" />
    <scroll-to-top/>
  </div>
</template>

<script>
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'
import store from '@/store'

export default {
  name: 'App',
  components: {
    Layout: () => import('@/layouts/Layout.vue'),
    LayoutFull: () => import('@/layouts/LayoutFull.vue'),
    ScrollToTop: () => import('@/components/scrollToTop/ScrollToTop'),
    Support: () => import('@/components/support/NewTicket'),
  },
  computed: {
    layout () {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout`
    },
  },
  setup () {
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {}
  }
}
</script>
