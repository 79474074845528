import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getBankAccounts ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/bankAccounts2'

      performApiCall(url, payload)
        .then(response => {
          // console.log(response)
          commit('SET_BANKACCOUNTS', response.data.results)
          commit('SET_BANKACCOUNTSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })
  },
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder, bankAccountId
  getBankAccountPayments ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/bankAccounts2/' + payload.bankAccountId + '/payments'

      performApiCall(url, payload)
        .then(response => {
          store.commit('payment/SET_PAYMENTS', response.results)
          store.commit('payment/SET_PAYMENTSCONTEXT', response.page_context)
          store.commit('app/REMOVE_LOADER')
          resolve(response.data)
        })
    })
  },
  getBankAccountPayments2 ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    console.log(payload)

    return new Promise((resolve, reject) => {
      resolve([])
    })

    // api.get('/bankAccounts2/' + '?' + qs.stringify(payload))
    //   .then(response => {
    //
    //     if (payload.kanbanSortBy == null) {
    //       commit('SET_WORKFLOS', response.data.results)
    //       commit('SET_WORKFLOSCONTEXT', response.data.page_context)
    //     } else {
    //       if (payload.page == null || payload.page == 1) {
    //         commit('SET_WORKFLOS', response.data.results)
    //         commit('SET_WORKFLOSCONTEXT', response.data.page_context)
    //       } else {
    //         commit('ADD_WORKFLOS', response.data.results)
    //         commit('SET_WORKFLOSCONTEXT', response.data.page_context)
    //       }
    //     }
    //
    //     store.commit('app/TOGGLE_LOADING', false)
    //     store.commit('app/REMOVE_LOADER')
    //
    //     resolve(response.data)
    //   })
    //   .catch(err => {
    //     console.log('Error', err)
    //     // reject(err)
    //   })

    // return new Promise((resolve, reject) => {
    //   let url = '/bankAccounts2/' + payload.bankAccountId + '/payments'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       store.commit('payment/SET_PAYMENTS', response.results)
    //       store.commit('payment/SET_PAYMENTSCONTEXT', response.page_context)
    //       store.commit('app/REMOVE_LOADER')
    //       resolve(response.data)
    //     })
    // })
  },
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder, bankAccountId
  getBankAccountBankTransactions ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/bankAccounts2/' + payload.bankAccountId + '/bankTransactions'

      performApiCall(url, payload)
        .then(response => {
          store.commit('bankTransaction/SET_BANKTRANSACTIONS', response.results)
          store.commit('bankTransaction/SET_BANKTRANSACTIONSCONTEXT', response.page_context)
          store.commit('app/REMOVE_LOADER')
          resolve(response.data)
        })
    })
  },
  getBankAccount ({ commit, state }, bankAccountId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/bankAccounts2/' + bankAccountId)
        .then(response => {
          addOrUpdateInList(response.data, state.bankAccounts, 'bankAccount', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addBankAccount ({ commit, state }, bankAccount) {
    return await cleanEntityToSend(bankAccount, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/bankAccounts', clonedEntityToSend)
            .then(response => {
              commit('ADD_BANKACCOUNT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankAccount has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateBankAccount ({ commit, state }, bankAccount) {
    return await cleanEntityToSend(bankAccount, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/bankAccounts/' + bankAccount.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_BANKACCOUNT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankAccount has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateBankAccountCheckForTransactions ({ commit, state }, bankAccount) {
    return await cleanEntityToSend(bankAccount, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.get('/bankAccounts2/' + bankAccount.id + '/checkForTransactions', clonedEntityToSend)
            .then(response => {
              commit('UPDATE_BANKACCOUNT', response.data.bankAccount)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.tc('New X banking transactions added', response.data.newTransactions, { newBankTransactions: response.data.newTransactions }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeBankAccount ({ commit }, bankAccount) {
    return new Promise((resolve, reject) => {
      api.delete('/bankAccounts/' + bankAccount.id)
        .then(response => {
          commit('REMOVE_BANKACCOUNT', bankAccount)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The bankAccount has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteBankAccounts ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/bankAccounts'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEBANKACCOUNTS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
  getBankAccountIndicators ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    let params = new URLSearchParams()
    const { start, end } = payload
    params.append('start', start)
    params.append('end', end)

    return new Promise((resolve, reject) => {
      api.get('/bankAccounts2/' + payload.bankAccount.id + '/indicators' + '?' + params)
        .then(response => {
          commit('SET_BANKACCOUNTINDICATORS', response.data.results)
          store.commit('app/REMOVE_LOADER')
          resolve(response.data.results)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })

  },
  getBankAccountsIndicators ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    let params = new URLSearchParams()
    const { start, end } = payload
    params.append('start', start)
    params.append('end', end)

    return new Promise((resolve, reject) => {
      api.get('/bankAccounts2/indicators' + '?' + params)
        .then(response => {
          commit('SET_BANKACCOUNTSINDICATORS', response.data.results)
          store.commit('app/REMOVE_LOADER')
          resolve(response.data.results)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })

  }

}