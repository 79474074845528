import { capitalize } from '../../utils/filter'

import i18n from '@/libs/i18n'
import { setRepetitionDisplay } from '../../utils/utils'

export default {
  SET_AUTOCOMPLETEPHASES (state, autocompletePhases) {
    state.autocompletePhases = autocompletePhases
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEPHASE (state, payload) {
    state.autocompletePhases.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEPHASE (state, payload) {
    // console.log(payload)
    let index = state.autocompletePhases.findIndex(i => i.id === payload.phase.id)
    state.autocompletePhases[index].name = payload.phase.name
  },
}