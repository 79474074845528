import Vue   from 'vue'
import axios             from 'axios'
import { useMS } from '../utils/useMS'
import { isObject } from '../utils/utils'

const accessToken = localStorage.getItem('accessToken')
// const msAccessToken = localStorage.getItem('msAccessToken')
// const apiURL = 'http://192.168.1.12:8000/'
// const apiURL = 'http://192.168.2.39:8000/'
// const apiURL = 'http://localhost:8000/'
const apiURL = 'https://apierp.hmt-france.com/'

// console.log(msAccessToken)

const api = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiURL,
  // timeout: 90000,
  headers: {
    // 'content-type': 'application/x-www-form-urlencoded',
    'Authorization': 'Bearer ' + accessToken,
    // 'MsAccessToken': msAccessToken
  }
})

api.interceptors.request.use(config => {
  // console.log(config)
  // console.log(config.url)
  // console.log("Current token : ", localStorage.getItem('msAccessToken'))
  config.headers['MsAccessToken'] = localStorage.getItem('msAccessToken')
  config.headers['MsRefreshToken'] = localStorage.getItem('msRefreshToken')

  if (config.url.startsWith('/emails')) {
    config.timeout = 600000
  }

  return config
})

let isRenewalMsToken = false
api.interceptors.response.use(response => {
  if (
    typeof response.data != "string" &&
    'page_context' in response.data &&
    response.data.page_context.renewal_ms_token == true
  ) {
    if (isRenewalMsToken == false) {
      console.log('renewal MS token')
      isRenewalMsToken = true

      useMS().login().then(() => {
        isRenewalMsToken = false
      })
    }
  }

  return response
}, error => {
  console.log(error)
  return Promise.reject(error)
})

Vue.prototype.$http = api
export default api