export default [
  // Upgrading
  {
    path: '/upgrading',
    name: 'Upgrading',
    component: () => import('../../views/upgrading/Upgrading'),
    meta: {
      resource: 'Upgrading',
      action: 'manage',
    }
  },
]
