import i18n from '../../libs/i18n'

const translateTerms = (user) => {
  // if ('userMethod' in user) {
  //   user.userMethod.name = i18n.t(user.userMethod.name)
  // }
}

export default {
  SET_USERS (state, users) {
    state.users = users
  },
  SET_ISKANBANUSERLIST (state, value) {
    state.isKanbanUserList = value
  },
  ADD_USER (state, user) {
    // state.users.push(user)
    state.users.unshift(user)
  },
  UPDATE_USER (state, user) {
    translateTerms(user)
    let index = state.users.findIndex(i => i.id === user.id)
    state.users.splice(index, 1, user)
  },
  REMOVE_USER (state, user) {
    state.users = state.users.filter(item => item.id != user.id)

    // let index = state.users.findIndex(i => i.id === user.id)
    // state.users.splice(index, 1)
  },
  SET_USERSCONTEXT (state, usersContext) {
    state.usersContext = usersContext
  },
  UPDATE_USERSCONTEXTTOTALRESULT (state, isAddUser) {
    if (isAddUser) {
      state.usersContext.total_results++
    } else {
      state.usersContext.total_results--
    }
  },
  SET_AUTOCOMPLETEUSERS (state, autocompleteUsers) {
    state.autocompleteUsers = autocompleteUsers
  },
  SET_AUTOCOMPLETEEMPLOYEES (state, autocompleteEmployees) {
    state.autocompleteEmployees = autocompleteEmployees
  },
}