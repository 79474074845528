import { cleanEntityToSend } from '../../utils/form'
import { capitalize } from '@/utils/filter'

import Vue from 'vue'
import api from '@/libs/axios'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '../index'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getVatDeclarations ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/vatDeclarations'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_VATDECLARATIONS', response.data.results)
          commit('SET_VATDECLARATIONSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })


    // store.commit('app/TOGGLE_LOADING', true)
    //
    // return new Promise((resolve, reject) => {
    //   api.get('/vatDeclarations' + '?' + qs.stringify(payload))
    //     .then(response => {
    //       // console.log(response)
    //       commit('SET_VATDECLARATIONS', response.data.results)
    //       commit('SET_VATDECLARATIONSCONTEXT', response.data.page_context)
    //       store.commit('app/TOGGLE_LOADING', false)
    //
    //       resolve(response.data)
    //     })
    //     .catch(err => {
    //       console.log('Error', err)
    //       // reject(err)
    //     })
    // })

  },
  getVatDeclaration ({ commit, state }, vatDeclarationId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/vatDeclarations/' + vatDeclarationId

      performApiCall(url, vatDeclarationId)
        .then(response => {
          store.commit('vatDeclaration/UPDATE_VATDECLARATION', response)
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  async addVatDeclaration ({ commit, state }, vatDeclaration) {
    return await cleanEntityToSend(vatDeclaration, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/vatDeclarations', clonedEntityToSend)
            .then(response => {
              commit('ADD_VATDECLARATION', response.data)
              commit('UPDATE_VATDECLARATIONSCONTEXTTOTALRESULT', 'add')

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The vatDeclaration has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateVatDeclaration ({ commit, state }, vatDeclaration) {
    delete vatDeclaration.amount
    return await cleanEntityToSend(vatDeclaration, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/vatDeclarations/' + vatDeclaration.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_VATDECLARATION', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The vatDeclaration has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeVatDeclaration ({ commit }, vatDeclaration) {
    return new Promise((resolve, reject) => {
      api.delete('/vatDeclarations/' + vatDeclaration.id)
        .then(response => {
          commit('REMOVE_VATDECLARATION', vatDeclaration)
          commit('UPDATE_VATDECLARATIONSCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The vatDeclaration has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteVatDeclarations ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/vatDeclarations'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEVATDECLARATIONS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
}