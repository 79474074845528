import { cleanEntityToSend } from '../../utils/form'
import { capitalize }        from '@/utils/filter'

import Vue                                          from 'vue'
import api                                          from '@/libs/axios'
import ToastificationContent                        from '@/components/toastification/ToastificationContent.vue'
import i18n                                         from '@/libs/i18n'
import store                                        from '../index'
import useAPI                                       from '../../utils/useAPI'
import qs                                           from 'qs'
import { useMS }                                    from '../../utils/useMS'
import moment                                       from 'moment'
import { clone, getRoute, isEmptyObject, parseUTC } from '../../utils/utils'
import router                                       from '../../router'

const setUtc = (workFlo) => {
  // workFlo.events.forEach(e => {
  //   // e.startDate = moment(e.startDate).format('YYYY-MM-DD HH:mm')
  //   // e.endDate = moment(e.endDate).format('YYYY-MM-DD HH:mm')
  //   // console.log(JSON.parse(JSON.stringify(e.startDate)))
  //   // console.log(JSON.parse(JSON.stringify(e.startDate.substr(e.startDate.length - 4))))
  //
  //   if (e.startDate.substr(e.startDate.length - 4) != "000Z") {
  //     e.startDate = parseUTC(new Date(e.startDate))
  //   }
  //
  //   if (e.endDate.substr(e.endDate.length - 4) != "000Z") {
  //     e.endDate = parseUTC(new Date(e.endDate))
  //   }
  // })
  // workFlo.interventions.forEach(i => {
  //   // console.log(JSON.parse(JSON.stringify(i)))
  //   // i.startDate = moment(i.startDate).format('YYYY-MM-DD HH:mm')
  //   // i.endDate = moment(i.endDate).format('YYYY-MM-DD HH:mm')
  //   i.startDate = parseUTC(new Date(i.startDate))
  //   i.endDate = parseUTC(new Date(i.endDate))
  // })
  // workFlo.workFloCalls.forEach(i => {
  //   i.date = parseUTC(new Date(i.date))
  // })
  workFlo.workFloStatusDates.forEach(wfsd => {
    // console.log(JSON.parse(JSON.stringify(wfsd.date)))
    if (wfsd.date != null) {
      wfsd.date = parseUTC(new Date(wfsd.date))
    }
  })
}

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getWorkFlos ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      if (payload.kanbanSortBy == null) {
        commit('SET_ISKANBANWORKFLOLIST', false)
      } else {
        commit('SET_ISKANBANWORKFLOLIST', true)
      }

      api.get('/workFlos' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)

          if (payload.kanbanSortBy == null) {
            commit('SET_WORKFLOS', response.data.results)
            commit('SET_WORKFLOSCONTEXT', response.data.page_context)
          } else {
            if (
              payload.page == null ||
              payload.page == 1 ||
              state.workFlos.length == 0
            ) {
              // console.log(response)
              commit('SET_WORKFLOS', response.data.results)
              commit('SET_WORKFLOSCONTEXT', response.data.page_context)
            } else {
              commit('ADD_WORKFLOS', response.data.results)
              commit('SET_WORKFLOSCONTEXT', response.data.page_context)
            }
          }

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getWorkFlosAddresses ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {

      api.get('/workFlos/map' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_WORKFLOSADDRESSES', response.data.results)
          commit('SET_WORKFLOSADDRESSESCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getWorkFlo ({ commit, state }, workFloId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/workFlos/' + workFloId

      performApiCall(url, workFloId)
        .then(response => {
// console.log(response)
          // response.workFloCalls.forEach((call, index) => call.duration = call.duration.slice(11, 19))
          // response.data.workFloCalls.forEach((call, index) => {
          //   response.data.workFloCalls[index].duration = call.duration.slice(11, 19)
          // })

          if (state.isKanbanWorkFloList != true) {
            store.commit('workFlo/UPDATE_WORKFLO', response.data)
          }

          store.commit('workFlo/SET_CURRENTWORKFLO', response.data)

          // console.log(response.data)
          // console.log(store.getters['workFlo/getWorkFlo'](response.data.id))

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getWorkFloHistory ({ commit, state }, workFloId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/workFlos/' + workFloId + '/history'

      performApiCall(url, workFloId)
        .then(response => {
          // console.log(response)

          store.commit('workFlo/SET_WORKFLOHISTORY', response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  }, getWorkFloMails ({ commit, state }, payload) {
    const { workFloId, search } = payload

    store.commit('app/ADD_LOADER')
    const { getTokenPopup } = useMS()

    return new Promise((resolve, reject) => {
      let url = '/workFlos/' + workFloId + '/mails?search=' + search

      api.get(url)
        .then(response => {
          // console.log(response.data)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)
        })
        .catch(error => {
          // console.log(error)

          getTokenPopup().then(response => {
            // console.log(response)
            // console.log('New token : ', response.accessToken)

            localStorage.setItem('msAccessToken', response.accessToken)
            // console.log('Saved token : ', localStorage.getItem('msAccessToken'))
            store.commit('app/REMOVE_LOADER')

            store.dispatch('workFlo/getWorkFloMails', payload)
          })

          // resolve(error.response)
        })
    })
  }, async addWorkFlo ({ commit, state }, workFlo) {
    setUtc(workFlo)

    // console.log(JSON.parse(JSON.stringify(workFlo)))

    return await cleanEntityToSend(workFlo, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/workFlos', clonedEntityToSend)
            .then(response => {
              if (state.isKanbanWorkFloList != true) {
                commit('ADD_WORKFLO', response.data)
              } else {
                let columnIndex = state.workFlos.findIndex(c => c.title == response.data.status.name)

                state.workFlos[columnIndex].items.unshift(response.data)
                state.workFlos[columnIndex].amount += parseFloat(response.data.expectedTurnover)
                state.workFlos.splice(state.workFlos.length, 0) // Reactive update the array
              }
              commit('UPDATE_WORKFLOSCONTEXTTOTALRESULT', 'add')

              Vue.$toast({
                component: ToastificationContent, props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workFlo has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  }, async updateWorkFlo ({ commit, state }, workFlo) {
    // console.log(JSON.parse(JSON.stringify(workFlo)))

    setUtc(workFlo)
    delete workFlo.amount
    // console.log(JSON.parse(JSON.stringify(workFlo)))
    let initialWorkFloExpectedTurnover = parseFloat(workFlo.expectedTurnover)
    // console.log(JSON.parse(JSON.stringify(workFlo)))

    return await cleanEntityToSend(workFlo, state.params)
      .then(async clonedEntityToSend => {
        // console.log(JSON.parse(JSON.stringify(clonedEntityToSend)))

        return new Promise((resolve, reject) => {
          api.put('/workFlos/' + workFlo.id, clonedEntityToSend)
            .then(response => {

              // response.data.workFloCalls.forEach((call, index) => {
              //   response.data.workFloCalls[index].duration = call.duration.slice(11, 19)
              // })

              setUtc(response.data)

              // console.log(JSON.parse(JSON.stringify((response.data))))

              // console.log(state.isKanbanWorkFloList)
              if (state.isKanbanWorkFloList != true) {
                commit('UPDATE_WORKFLO', response.data)
              } else {
                let columnIndex = state.workFlos.findIndex(c => c.title == response.data.status.name)

                state.workFlos[columnIndex].amount -= initialWorkFloExpectedTurnover
                state.workFlos[columnIndex].amount += parseFloat(response.data.expectedTurnover)

                commit('UPDATE_WORKFLO_KANBAN', response.data)
              }

              store.commit('workFlo/SET_CURRENTWORKFLO', response.data)

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              Vue.$toast({
                component: ToastificationContent, props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workFlo has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  }, removeWorkFlo ({ commit, state }, workFlo) {
    return new Promise((resolve, reject) => {
      api.delete('/workFlos/' + workFlo.id)
        .then(response => {
          if (state.isKanbanWorkFloList != true) {
            commit('REMOVE_WORKFLO', workFlo)
          } else {
            let columnIndex = state.workFlos.findIndex(c => c.title == workFlo.status.name)
            let columnCardIndex = state.workFlos[columnIndex].items.findIndex(i => i.id == workFlo.id)

            if (columnCardIndex != -1) {
              state.workFlos[columnIndex].items.splice(columnCardIndex, 1)
            }
          }

          commit('UPDATE_WORKFLOSCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent, props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The workFlo has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  }, getAutocompleteWorkFlos ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/workFlos'

      performApiCall(url, payload)
        .then(response => {
          // console.log(response)
          commit('SET_AUTOCOMPLETEWORKFLOS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  }, async sendWorkFloMail ({ commit, state }, mail) {
    let mailLocal = JSON.parse(JSON.stringify(mail))
    if (mailLocal.attachments) {
      mailLocal.attachments.forEach(a => delete a._entity)
    }

    let workFloId = getRoute().params.workFloId

    return new Promise((resolve, reject) => {
      api.post('/workFlos/' + workFloId + '/mail', mailLocal)
        .then(response => {
          // console.log(response)

          if (response.status == 201) {
            Vue.$toast({
              component: ToastificationContent, props: {
                title: capitalize(i18n.t('success')),
                icon: 'check',
                text: i18n.t('The email has been sent'),
                variant: 'success'
              },
            })
          }
          resolve(response)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent, props: {
              title: capitalize(i18n.t('error')),
              icon: 'times',
              text: i18n.t('The message could not be sent'),
              variant: 'danger'
            },
          })

          // console.log(error.response)

          resolve(error.response)
        })
    })
  },
  getDashboard ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/workFlos/dashboard' + '?' + qs.stringify(payload))
        .then(response => {

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  }
}