import moment         from 'moment'
import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'

export default {
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
    {
      field: 'title',
      i18n: capitalize(i18n.t('title')),
      subFields: [],
      type: 'text',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [],
  emptyTask: {
    documents: [],
    priority: 'high',
    steps: []
  },
  fieldFilters: [
    {
      name: 'createdBy',
      type: 'employee',
      multiple: false,
      filter: {}
    },
  ],
  isKanbanWorkFloList: false,
  params: {
    entity: [
      'phase',
      'workflow',
      'documents'
    ],
    collection: [
      'repetition',
      'steps'
    ]
  },
  tasks: [],
  tasksContext: {
    page: 1,
    filters: []
  },
}