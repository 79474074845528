import state from './workFloStatusState';
import mutations from './workFloStatusMutations';
import actions from './workFloStatusActions';
import getters from "./workFloStatusGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}