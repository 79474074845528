import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getOrders ({ commit, state }, payload) {
    // const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/orders2' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_ORDERS', response.data.results)
          commit('SET_ORDERSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

    // return new Promise((resolve, reject) => {
    //   let url = '/orders2'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       commit('SET_ORDERS', response.results)
    //       commit('SET_ORDERSCONTEXT', response.page_context)
    //       store.commit('app/TOGGLE_LOADING', false)
    //       resolve(true)
    //     })
    //
    // })

  },
  getOrder ({ commit, state }, orderId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/orders/'+orderId)
        .then(response => {
          addOrUpdateInList(response.data, state.orders, 'order',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addOrder ({ commit, state }, order) {
    return await cleanEntityToSend(order, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/orders', clonedEntityToSend)
            .then(response => {
              commit('ADD_ORDER', response.data)
              commit('UPDATE_ORDERSTOTALRESULT', true)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateOrder ({ commit, state }, order) {
    // console.log(order)
    return await cleanEntityToSend(order, state.params)
      .then(async clonedEntityToSend => {

        // console.log(clonedEntityToSend)

        return new Promise((resolve, reject) => {
          api.put('/orders/' + order.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_ORDER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The incoming invoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeOrder ({ commit }, order) {
    return new Promise((resolve, reject) => {
      api.delete('/orders/' + order.id)
        .then(response => {
          commit('REMOVE_ORDER', order)
          commit('UPDATE_ORDERSTOTALRESULT', false)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The incoming invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  async validateOrder ({ commit, state }, order) {
    return new Promise((resolve, reject) => {
      api.put('/orders/' + order.id + '/validate')
        .then(response => {
          commit('UPDATE_ORDER', response.data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The order has been ', { crud: i18n.t('edited') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}