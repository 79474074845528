import store from '../index'
import useAPI from '../../utils/useAPI'
import qs from 'qs'
import api from '../../libs/axios'

export default {
  getAutocompletePhases ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // if (payload.kanbanSortBy == null) {
      //   commit('SET_ISKANBANUSERLIST', false)
      // } else {
      //   commit('SET_ISKANBANUSERLIST', true)
      // }

      api.get('/autocomplete/phases' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_AUTOCOMPLETEPHASES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

    // return new Promise((resolve, reject) => {
    //   let url = '/autocomplete/phases'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       commit('SET_AUTOCOMPLETEPHASES', response.results)
    //       store.commit('app/TOGGLE_LOADING', false)
    //       resolve(true)
    //     })
    // })

  },

}