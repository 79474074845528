import { useToast }                       from 'vue-toastification/composition'
import { capitalize }                     from '../../../utils/filter'
import i18n                               from '../../../libs/i18n'
import ToastificationContent              from '../../toastification/ToastificationContent.vue'
import store                                                  from '../../../store'
import { clone, downloadByDocument, isEmptyObject, isObject } from '../../../utils/utils'
import { useMS }                                              from '../../../utils/useMS'
import { utils } from 'vue-file-agent'

export const useEmails = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const { login, selectAccount, logout } = useMS()
  const toast = useToast()

  const newEmailNotification = (email) => {
    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: capitalize(i18n.t('new email')),
    //     icon: 'envelope',
    //     text: capitalize(i18n.t('you have received a new email')),
    //     variant: 'primary',
    //   },
    // })
    // let timeout = false
    toast({
      component: ToastificationContent,
      props: {
        title: email.subject,
        icon: 'envelope',
        text: email.bodyPreview,
        variant: 'primary',
      }
      // }, {
      //   timeout,
    })
  }
  const someEmailsNotification = (number) => {
    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: capitalize(i18n.t('new email')),
    //     icon: 'envelope',
    //     text: capitalize(i18n.t('you have received a new email')),
    //     variant: 'primary',
    //   },
    // })
    toast({
      component: ToastificationContent,
      props: {
        title: capitalize(i18n.t('newEmails')),
        icon: 'envelope',
        text: capitalize(i18n.tc('you have received X new emails', number, { 'number': number })),
        variant: 'primary',
      },
    })
  }
  const sentEmailNotification = () => {
    toast({
      component: ToastificationContent,
      props: {
        title: capitalize(i18n.t('email sent')),
        icon: 'envelope',
        text: capitalize(i18n.t('your email has been sent')),
        variant: 'success',
      },
    })
  }
  const recusiceAddEmail = (mailFolder, email) => {
    mailFolder.subFolders.forEach(mf2 => {
      if (mf2.id == email.folder.id) {
        mf2.emails.push(email)
      } else {
        recusiceAddEmail(mf2, email)
      }
    })
  }

  const refreshEmails = () => {
    console.log('Request refresh')

    return new Promise((resolve, reject) => {
      if (localStorage.getItem('isRefreshEmail') != 'true') {
        console.info('Call refresh')
        localStorage.setItem('isRefreshEmail', true)
        console.log('set refresh email -> true')

        selectAccount()
        if (store.getters['auth/getMsAccount']) {
          refreshLocalEmails().then(refreshLocalEmailsResponse => {
            if (refreshLocalEmailsResponse == false) {
              // console.log('re login')
              login().then(r => {
                refreshLocalEmails().then(response => {
                  console.log('set refresh email -> false')
                  localStorage.setItem('isRefreshEmail', false)

                  resolve(response)
                })
              })
            } else {
              console.log('set refresh email -> false')
              localStorage.setItem('isRefreshEmail', false)

              resolve(refreshLocalEmailsResponse)
            }
          })
        } else {
          // logout()
          login().then(r => {
            // refreshLocalEmails().then(response => {
            //   resolve(response)
            // })
            refreshLocalEmails().then(refreshLocalEmailsResponse => {
              if (refreshLocalEmailsResponse == false) {
                // console.log('re login')
                login().then(r => {
                  refreshLocalEmails().then(response => {
                    console.log('set refresh email -> false')
                    localStorage.setItem('isRefreshEmail', false)

                    resolve(response)
                  })
                })
              } else {
                console.log('set refresh email -> false')
                localStorage.setItem('isRefreshEmail', false)

                resolve(refreshLocalEmailsResponse)
              }
            })
          })
        }
      } else {
        console.info('Refresh pending')
        resolve(false)
      }
    })

    // return new Promise((resolve, reject) => {
    //   resolve(true)
    // })

  }
  const refreshLocalEmails = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('email/getRefreshEmails', {})
        .then(response => {
          // console.log(response)

          // console.log(response.status)
          if (typeof response.status === 'undefined' || response.status == 401) {
            resolve(false)
          } else {
            let emailsToDisplay = response.data.newEmails.filter(e => {
              console.log(e)
              return e.folder != null && !['Éléments envoyés', 'Éléments supprimés'].includes(e.folder.name)
            })

            if (emailsToDisplay.length) {
              if (emailsToDisplay.length >= 5) {
                someEmailsNotification(emailsToDisplay.length)
              } else {
                emailsToDisplay.forEach(e => {
                  newEmailNotification(e)
                })
              }
            }

            resolve(response.data)
          }
        })
        .catch(error => {
          console.log(error)
          refreshEmails()
        })
    })
  }

  const sendEmail = (email) => {
    console.log(clone(email))
    cleanRecipients(email)

    return new Promise((resolve, reject) => {
      store.dispatch('email/addEmail', email)
        .then(response => {
          // console.log(response)
          resolve(response)
        })
    })
  }

  const downloadAttachment = attachment => {
    if ('url' in attachment && attachment.url != null) {
      downloadByDocument(attachment)
    }
  }

  const downloadAllAttachments = email => {
    store.dispatch('email/downloadAllAttachments', email)
      .then(response => {
        // console.log(response)
        resolve(response)
      })
  }

  const cleanRecipients = (email) => {
    ['toRecipients', 'ccRecipients', 'bccRecipients'].forEach(recipient => {
      // console.log(recipient)
      email[recipient].forEach((localRecipient, i) => {
        // console.log(localRecipient)

        if (isObject(localRecipient.emailAddress.address)) {
          if (localRecipient.emailAddress.address.email != null) {
            email[recipient][i].emailAddress.address = localRecipient.emailAddress.address.email
          } else if (toRecipient.emailAddress.address._display != null) {
            email[recipient][i].emailAddress.address = localRecipient.emailAddress.address._display
          }
        }
      })
    })

  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    newEmailNotification,
    someEmailsNotification,
    sentEmailNotification,
    recusiceAddEmail,
    refreshEmails,
    sendEmail,
    downloadAttachment,
    downloadAllAttachments,
  }
}