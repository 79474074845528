import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'
import { clone } from '../../utils/utils'
import router from '../../router'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getOutgoingInvoices ({ commit, state }, payload) {
    // const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/outgoingInvoices2' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_OUTGOINGINVOICES', response.data.results)
          commit('SET_OUTGOINGINVOICESCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

    // return new Promise((resolve, reject) => {
    //   let url = '/outgoingInvoices2'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       commit('SET_OUTGOINGINVOICES', response.results)
    //       commit('SET_OUTGOINGINVOICESCONTEXT', response.page_context)
    //       store.commit('app/TOGGLE_LOADING', false)
    //       resolve(true)
    //     })
    //
    // })

  },
  getOutgoingInvoice ({ commit, state }, outgoingInvoiceId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/outgoingInvoices/'+outgoingInvoiceId)
        .then(response => {
          addOrUpdateInList(response.data, state.outgoingInvoices, 'outgoingInvoice',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return await cleanEntityToSend(outgoingInvoice, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/outgoingInvoices', clonedEntityToSend)
            .then(response => {
              commit('ADD_OUTGOINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return await cleanEntityToSend(outgoingInvoice, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/outgoingInvoices/' + outgoingInvoice.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_OUTGOINGINVOICE', response.data)

              // console.log(store.getters['outgoingInvoice2/getOutgoingInvoice'](response.data.id))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              if (
                router.history.current.name == 'WorkFlo view' &&
                clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
              ) {
                store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
              }

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeOutgoingInvoice ({ commit }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.delete('/outgoingInvoices/' + outgoingInvoice.id)
        .then(response => {
          commit('REMOVE_OUTGOINGINVOICE', outgoingInvoice)
          commit('UPDATE_OUTGOINGINVOICESTOTALRESULT', false)


          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          if (
            router.history.current.name == 'WorkFlo view' &&
            clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
          ) {
            store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
          }

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  async validateOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.put('/outgoingInvoices/' + outgoingInvoice.id + '/validate')
        .then(response => {
          commit('UPDATE_OUTGOINGINVOICE', response.data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('editedE') }),
              variant: 'success'
            },
          })

          if (
            router.history.current.name == 'WorkFlo view' &&
            clone(store.getters['workFlo/getWorkFloHistory']) != { comingSoon: [], history: [] }
          ) {
            store.dispatch('workFlo/getWorkFloHistory', store.getters['workFlo/getCurrentWorkFlo'].id)
          }

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  printOutgoingInvoice ({ commit }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.get('/outgoingInvoices/' + outgoingInvoice.id + '/pdf')
        .then(response => {

          let byteCharacters = atob(response.data)
          let byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          let byteArray = new Uint8Array(byteNumbers)
          let blob = new Blob([byteArray], {
            type: 'application/pdf'
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = capitalize(i18n.tc('invoice')) + ' - ' + (outgoingInvoice.billNumber ? outgoingInvoice.billNumber : i18n.t('draft')) + '.pdf'
          link.click()
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  printDeliveryNote ({ commit }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.get('/outgoingInvoices/' + outgoingInvoice.id + '/deliveryNote')
        .then(response => {

          let byteCharacters = atob(response.data)
          let byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          let byteArray = new Uint8Array(byteNumbers)
          let blob = new Blob([byteArray], {
            type: 'application/pdf'
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = i18n.tc('DeliveryNote') + ' - ' + (outgoingInvoice.billNumber ? outgoingInvoice.billNumber : i18n.t('draft')) + '.pdf'
          link.click()
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}