import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getOutgoingInvoices ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/outgoingInvoices'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_OUTGOINGINVOICES', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'outgoingInvoice', limit, offset, state.outgoingInvoices, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            store.commit('app/REMOVE_LOADER')
            resolve(true)
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getOutgoingInvoice ({ commit, state }, outgoingInvoiceId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/outgoingInvoices/'+outgoingInvoiceId)
        .then(response => {
          addOrUpdateInList(response.data, state.outgoingInvoices, 'outgoingInvoice',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return await cleanEntityToSend(outgoingInvoice, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/outgoingInvoices', clonedEntityToSend)
            .then(response => {
              commit('ADD_OUTGOINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return await cleanEntityToSend(outgoingInvoice, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/outgoingInvoices/' + outgoingInvoice.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_OUTGOINGINVOICE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeOutgoingInvoice ({ commit }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.delete('/outgoingInvoices/' + outgoingInvoice.id)
        .then(response => {
          commit('REMOVE_OUTGOINGINVOICE', outgoingInvoice)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The invoice has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  async validateOutgoingInvoice ({ commit, state }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.put('/outgoingInvoices/' + outgoingInvoice.id + '/validate')
        .then(response => {
          commit('UPDATE_OUTGOINGINVOICE', response.data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The outgoingInvoice has been ', { crud: i18n.t('editedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  printOutgoingInvoice ({ commit }, outgoingInvoice) {
    return new Promise((resolve, reject) => {
      api.get('/outgoingInvoices/' + outgoingInvoice.id + '/pdf')
        .then(response => {

          let byteCharacters = atob(response.data)
          let byteNumbers = new Array(byteCharacters.length)
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          let byteArray = new Uint8Array(byteNumbers)
          let blob = new Blob([byteArray], {
            type: 'application/pdf'
          })

          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = capitalize(i18n.tc('invoice')) + ' - ' + (outgoingInvoice.billNumber ? outgoingInvoice.billNumber : i18n.t('draft')) + '.pdf'
          link.click()
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}