import state from './workflowState';
import mutations from './workflowMutations';
import actions from './workflowActions';
import getters from "./workflowGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}