import i18n from '../../libs/i18n'

const translateTerms = (workFloStatus) => {
  // if ('workFloStatusMethod' in workFloStatus) {
  //   workFloStatus.workFloStatusMethod.name = i18n.t(workFloStatus.workFloStatusMethod.name)
  // }
}

export default {
  SET_WORKFLOSTATUSS (state, workFloStatuss) {
    state.workFloStatuss = workFloStatuss
  },
  ADD_WORKFLOSTATUS (state, workFloStatus) {
    // state.workFloStatuss.push(workFloStatus)
    state.workFloStatuss.unshift(workFloStatus)
  },
  UPDATE_WORKFLOSTATUS (state, workFloStatus) {
    translateTerms(workFloStatus)
    let index = state.workFloStatuss.findIndex(i => i.id === workFloStatus.id)
    state.workFloStatuss.splice(index, 1, workFloStatus)
  },
  REMOVE_WORKFLOSTATUS (state, workFloStatus) {
    state.workFloStatuss = state.workFloStatuss.filter(item => item.id != workFloStatus.id)

    // let index = state.workFloStatuss.findIndex(i => i.id === workFloStatus.id)
    // state.workFloStatuss.splice(index, 1)
  },
  SET_WORKFLOSTATUSCONTEXT (state, workFloStatussContext) {
    state.workFloStatussContext = workFloStatussContext
  },
  UPDATE_WORKFLOSTATUSCONTEXTTOTALRESULT (state, isAddWorkFloStatus) {
    if (isAddWorkFloStatus) {
      state.workFloStatussContext.total_results++
    } else {
      state.workFloStatussContext.total_results--
    }
  },
  SET_AUTOCOMPLETEWORKFLOSTATUSS (state, autocompleteWorkFloStatuss) {
    state.autocompleteWorkFloStatuss = autocompleteWorkFloStatuss
  },
}