import Vue from 'vue'
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('icon', { props: { icon: 'times' }, style: 'padding-left: .25rem' }),
  },
  OpenIndicator: {
    render: h => h('icon', { props: { icon: 'chevron-down' }, class: 'open-indicator' }),
  },
})

Vue.component(vSelect)
