import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  getExports ({ commit, state }, payload) {
    // const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // console.log(payload)
      api.get('/exports' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_EXPORTS', response.data.results)
          commit('SET_EXPORTSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getExport ({ commit, state }, exportId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/exports/'+exportId)
        .then(response => {
          addOrUpdateInList(response.data, state.exports, 'export',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  removeExport ({ commit }, exportId) {
    return new Promise((resolve, reject) => {
      api.delete('/exports/' + exportId)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The export has been ', { crud: i18n.t('deleted') }),
              variant: 'success'
            },
          })

          commit('REMOVE_EXPORT', exportId)
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('UnableToDelete'),
                icon: 'times',
                text: i18n.t('This export is linked'),
                variant: 'danger'
              },
            })
          }
          reject(error)
        })
    })
  }

}