import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getPayments ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/payments' + '?' + qs.stringify(payload))
        .then(response => {

          // if (payload.kanbanSortBy == null) {
          commit('SET_PAYMENTS', response.data.results)
          commit('SET_PAYMENTSCONTEXT', response.data.page_context)
          // } else {
          //   if (payload.page == null || payload.page == 1) {
          //     commit('SET_WORKFLOS', response.data.results)
          //     commit('SET_WORKFLOSCONTEXT', response.data.page_context)
          //   } else {
          //     commit('ADD_WORKFLOS', response.data.results)
          //     commit('SET_WORKFLOSCONTEXT', response.data.page_context)
          //   }
          // }

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

    // return new Promise((resolve, reject) => {
    //   const { performApiCall } = useAPI()
    //   let url = '/payments'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       // console.log(response)
    //       commit('SET_PAYMENTS', response.results)
    //       commit('SET_PAYMENTSCONTEXT', response.page_context)
    //       store.commit('app/TOGGLE_LOADING', false)
    //       resolve(true)
    //     })
    // })

  },
  getPayment ({ commit, state }, paymentId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/payments/' + paymentId

      performApiCall(url, paymentId)
        .then(response => {
          store.commit('payment/UPDATE_PAYMENT', response)
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  async addPayment ({ commit, state }, payment) {
    return await cleanEntityToSend(payment, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/payments', clonedEntityToSend)
            .then(response => {
              commit('ADD_PAYMENT', response.data)
              commit('UPDATE_PAYMENTSCONTEXTTOTALRESULT', 'add')

              response.data.paymentRelations.forEach(pr => {
                if (pr.incomingInvoice != null) {
                  let ii = store.getters['incomingInvoice2/getIncomingInvoice'](pr.incomingInvoice.id)

                  if (ii != null) {
                    ii.paymentRelations.push(response.data)
                    ii._balance -= parseFloat(pr.amount)

                    if (ii._balance == 0) {
                      ii._paymentStatus = 'Paid'
                      store.commit('incomingInvoice2/REMOVE_INCOMINGINVOICE', ii)
                      store.commit('incomingInvoice2/UPDATE_INCOMINGINVOICESTOTALRESULT', false)
                    } else {
                      ii._paymentStatus = 'Partial payment'
                    }
                  }

                }
                if (pr.outgoingInvoice != null) {
                  let oi = store.getters['outgoingInvoice2/getOutgoingInvoice'](pr.outgoingInvoice.id)

                  if (oi != null) {
                    oi.paymentRelations.push(response.data)
                    oi._balance = parseFloat(oi._balance) - parseFloat(pr.amount)
                    // console.log(oi)

                    if (oi._balance == 0) {
                      oi._paymentStatus = 'Paid'
                      store.commit('outgoingInvoice2/REMOVE_OUTGOINGINVOICE', oi)
                      store.commit('outgoingInvoice2/UPDATE_OUTGOINGINVOICESTOTALRESULT', false)
                    } else {
                      oi._paymentStatus = 'Partial payment'
                    }
                  }

                }
                if (pr.vatDeclaration != null) {
                  let vd = store.getters['vatDeclaration/getVatDeclaration'](pr.vatDeclaration.id)

                  // console.log(vd)

                  if (vd != null) {
                    vd.paymentRelations.push(response.data)
                    vd._balance = parseFloat(vd._balance) - parseFloat(pr.amount)

                    if (vd._balance == 0) {
                      vd._paymentStatus = 'Lettered'
                    } else {
                      vd._paymentStatus = 'Partially lettered'
                    }
                  }

                }
                if (pr.socialSecurityContribution != null) {
                  let c = store.getters['socialSecurityContribution2/getSocialSecurityContribution'](pr.socialSecurityContribution.id)

                  // console.log(c)

                  if (c != null) {
                    c.paymentRelations.push(response.data)
                    c._balance = parseFloat(c._balance) - parseFloat(pr.amount)

                    if (c._balance == 0) {
                      c._paymentStatus = 'Lettered'
                    } else {
                      c._paymentStatus = 'Partially lettered'
                    }
                  }
                }
                if (pr.salary != null) {
                  let s = store.getters['salary2/getSalary'](pr.salary.id)

                  // console.log(JSON.parse(JSON.stringify(s)))

                  if (s != null) {
                    s.paymentRelations.push(response.data)
                    s._balance = parseFloat((parseFloat(s._balance) - parseFloat(pr.amount)).toFixed(2))

                    if (s._balance == 0) {
                      s._paymentStatus = 'Lettered'
                    } else {
                      s._paymentStatus = 'Partially lettered'
                    }
                  }

                  // console.log(s)
                }

              })

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The payment has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updatePayment ({ commit, state }, payment) {
    return await cleanEntityToSend(payment, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/payments/' + payment.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_PAYMENT', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The payment has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removePayment ({ commit }, payment) {
    return new Promise((resolve, reject) => {
      api.delete('/payments/' + payment.id)
        .then(response => {
          commit('REMOVE_PAYMENT', payment)
          commit('UPDATE_PAYMENTSCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The payment has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompletePayments ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/payments'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEPAYMENTS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
}