import moment from 'moment'

export default {
  getOutgoingInvoices: (state) => state.outgoingInvoices,
  getOutgoingInvoice: (state) => (id) => {
    return state.outgoingInvoices.find(outgoingInvoice => outgoingInvoice.id === parseInt(id))
  },
  getEmptyOutgoingInvoice: (state) => JSON.parse(JSON.stringify(state.emptyOutgoingInvoice)),
  outgoingInvoicesNotCompletelyPaid: (state) => {
    let ois = state.outgoingInvoices.filter(outgoingInvoice => outgoingInvoice.billNumber != null && outgoingInvoice._paymentStatus != 'Paid')

    ois.sort((a, b) => {return new Date(b.billingDate) - new Date(a.billingDate)})

    return ois

  },
  customerReceivable: (state) => {
    return state.outgoingInvoices.filter(outgoingInvoice => {
      return outgoingInvoice.billNumber != null &&
        outgoingInvoice._paymentStatus != 'Paid' &&
        moment(outgoingInvoice._dueDate) < moment()
    })
  },
  getOutgoingInvoicesBetweenDates: (state) => (dates) => {
    return state.outgoingInvoices.filter(oi => dates.start <= moment(oi.billingDate) && moment(oi.billingDate) <= dates.end)
  }
}