export default {
  getAutocompleteWorkFlos: (state) => state.autocompleteWorkFlos,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getAvailableMapFilters: (state) => state.availableMapFilters,
  getCurrentWorkFlo: (state) => state.currentWorkFlo,
  getFieldFilters: (state) => state.fieldFilters,
  getWorkFlos: (state) => state.workFlos,
  getWorkFlosAddresses: (state) => state.workFlosAddresses,
  getIsKanbanWorkFloList: (state) => state.isKanbanWorkFloList,
  getWorkFlosAddressesContext: (state) => state.workFlosAddressesContext,
  getWorkFlosContext: (state) => state.workFlosContext,
  getWorkFlo: (state) => (id) => {
    return state.workFlos.find(workFlo => workFlo.id === parseInt(id))
  },
  getWorkFloHistory: (state) => {
    return state.workFloHistory
  },
  getEmptyWorkFlo: (state) => JSON.parse(JSON.stringify(state.emptyWorkFlo)),
  getEmptyWorkFloTask: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloTask)),
  getEmptyWorkFloNote: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloNote)),
  getEmptyWorkFloCall: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloCall)),
  getEmptyWorkFloEvent: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloEvent)),
  getEmptyWorkFloIntervention: (state) => JSON.parse(JSON.stringify(state.emptyWorkFloIntervention)),
}