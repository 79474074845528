import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getEvents ({ commit, state }, payload) {
    // const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/events2' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_EVENTS', response.data.results)
          commit('SET_EVENTSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })


      // let url = '/events2'
      //
      // performApiCall(url, payload)
      //   .then(response => {
      //     commit('SET_EVENTS', response.results)
      //     commit('SET_EVENTSCONTEXT', response.page_context)
      //     store.commit('app/TOGGLE_LOADING', false)
      //     resolve(true)
      //   })

    })

  },
  getEvent ({ commit, state }, eventId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/events/'+eventId)
        .then(response => {
          addOrUpdateInList(response.data, state.events, 'event',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  // async addEvent ({ commit, state }, event) {
  //   return await cleanEntityToSend(event, state.params)
  //     .then(async clonedEntityToSend => {
  //       return new Promise((resolve, reject) => {
  //         api.post('/events', clonedEntityToSend)
  //           .then(response => {
  //             commit('ADD_EVENT', response.data)
  //             commit('UPDATE_EVENTSTOTALRESULT', true)
  //
  //             Vue.$toast({
  //               component: ToastificationContent,
  //               props: {
  //                 title: capitalize(i18n.t('success')),
  //                 icon: 'check',
  //                 text: i18n.t('The incoming invoice has been ', { crud: i18n.t('createdE') }),
  //                 variant: 'success'
  //               },
  //             })
  //
  //             resolve(response)
  //           })
  //           .catch(error => {
  //             console.log('Error', error)
  //             reject(error)
  //           })
  //       })
  //
  //     })
  //     .catch(error => {
  //       console.log('Error', error)
  //       reject(error)
  //     })
  // },
  // async updateEvent ({ commit, state }, event) {
  //   // console.log(event)
  //   return await cleanEntityToSend(event, state.params)
  //     .then(async clonedEntityToSend => {
  //
  //       // console.log(clonedEntityToSend)
  //
  //       return new Promise((resolve, reject) => {
  //         api.put('/events/' + event.id, clonedEntityToSend)
  //           .then(response => {
  //             commit('UPDATE_EVENT', response.data)
  //
  //             Vue.$toast({
  //               component: ToastificationContent,
  //               props: {
  //                 title: capitalize(i18n.t('success')),
  //                 icon: 'check',
  //                 text: i18n.t('The incoming invoice has been ', { crud: i18n.t('editedE') }),
  //                 variant: 'success'
  //               },
  //             })
  //
  //             resolve(response)
  //             // resolve(JSON.parse(JSON.stringify(response)))
  //           })
  //           .catch(error => {
  //             console.log('Error', error)
  //             reject(error)
  //           })
  //       })
  //
  //     })
  //     .catch(error => {
  //       console.log('Error', error)
  //       reject(error)
  //     })
  // },
  // removeEvent ({ commit }, event) {
  //   return new Promise((resolve, reject) => {
  //     api.delete('/events/' + event.id)
  //       .then(response => {
  //         commit('REMOVE_EVENT', event)
  //         commit('UPDATE_EVENTSTOTALRESULT', false)
  //
  //         Vue.$toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: capitalize(i18n.t('success')),
  //             icon: 'check',
  //             text: i18n.t('The incoming invoice has been ', { crud: i18n.t('removedE') }),
  //             variant: 'success'
  //           },
  //         })
  //
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log('Error', error)
  //         reject(error)
  //       })
  //   })
  // },

}