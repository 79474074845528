import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'
import qs from 'qs'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getBankTransactions ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)
    store.commit('app/ADD_LOADER')
// console.log(payload)
    return new Promise((resolve, reject) => {
      api.get('/bank_transactions' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_BANKTRANSACTIONS', response.data.results)
          commit('SET_BANKTRANSACTIONSCONTEXT', response.data.page_context)

          store.commit('app/TOGGLE_LOADING', false)
          store.commit('app/REMOVE_LOADER')

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })



    // const { performApiCall } = useAPI()
    // store.commit('app/TOGGLE_LOADING', true)
    //
    // return new Promise((resolve, reject) => {
    //   let url = '/bank_transactions'
    //
    //   performApiCall(url, payload)
    //     .then(response => {
    //       commit('SET_BANKTRANSACTIONS', response.results)
    //       commit('SET_BANKTRANSACTIONSCONTEXT', response.page_context)
    //       store.commit('app/TOGGLE_LOADING', false)
    //       resolve(true)
    //     })
    // })
  },
  getBankTransactionBestMatch ({ commit, state }, bankTransaction) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/bank_transactions/' + bankTransaction.id + '/best_match')
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getBankTransaction ({ commit, state }, bankTransactionId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/bank_transactions/' + bankTransactionId)
        .then(response => {
          addOrUpdateInList(response.data, state.bankTransactions, 'bankTransaction', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addBankTransaction ({ commit, state }, bankTransaction) {
    return await cleanEntityToSend(bankTransaction, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/bank_transactions', clonedEntityToSend)
            .then(response => {
              commit('ADD_BANKTRANSACTION', response.data)
              commit('UPDATE_BANKTRANSACTIONSCONTEXTTOTALRESULT', true)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankTransaction has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateBankTransaction ({ commit, state }, bankTransaction) {
    let bankTransactionCloned = {
      attachment: bankTransaction.attachment,
      attachmentCategory: bankTransaction.attachmentCategory,
      payments: bankTransaction.payments,
      linkedTransaction: bankTransaction.linkedTransaction
    }


    return await cleanEntityToSend(bankTransactionCloned, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/bank_transactions/' + bankTransaction.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_BANKTRANSACTION', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankTransaction has been', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeBankTransaction ({ commit }, bankTransaction) {
    return new Promise((resolve, reject) => {
      api.delete('/bank_transactions/' + bankTransaction.id)
        .then(response => {
          commit('REMOVE_BANKTRANSACTION', bankTransaction)
          commit('UPDATE_BANKTRANSACTIONSCONTEXTTOTALRESULT', false)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The bankTransaction has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}