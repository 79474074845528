import moment from 'moment'

export default {
  getAvailableFilters: (state) => state.availableFilters,
  getOffers: (state) => state.offers,
  getOffersContext: (state) => state.offersContext,
  getAvailableListColumns: (state) => state.availableListColumns,
  getFieldFilters: (state) => state.fieldFilters,
  getOffer: (state) => (id) => {
    return state.offers.find(offer => offer.id === parseInt(id))
  },
  getEmptyOffer: (state) => JSON.parse(JSON.stringify(state.emptyOffer)),

}