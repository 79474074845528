export default {
  SET_WORKFLOUSAGES (state, workFloUsages) {
    state.workFloUsages = workFloUsages
  },

  SET_AUTOCOMPLETEWORKFLOUSAGES (state, autocompleteWorkFloUsages) {
    state.autocompleteWorkFloUsages = autocompleteWorkFloUsages
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEWORKFLOUSAGE (state, payload) {
    state.autocompleteWorkFloUsages.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEWORKFLOUSAGE (state, payload) {
    // console.log(payload)
    let index = state.autocompleteWorkFloUsages.findIndex(i => i.id === payload.workFloUsage.id)
    state.autocompleteWorkFloUsages[index].name = payload.workFloUsage.name
  },
}