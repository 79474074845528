import state from './paymentTermState'
import mutations from './paymentTermMutations'
import actions from './paymentTermActions'
import getters from './paymentTermGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}