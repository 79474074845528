import state from './ticketState';
import mutations from './ticketMutations';
import actions from './ticketActions';
import getters from "./ticketGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}