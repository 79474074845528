import state from './orderState';
import mutations from './orderMutations';
import actions from './orderActions';
import getters from "./orderGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}