import i18n from '../../libs/i18n'

const translateTerms = (paymentTerm) => {
  if ('term' in paymentTerm) {
    paymentTerm.term = i18n.t(paymentTerm.term)
  }
}
export default {
  SET_PAYMENTTERMS (state, paymentTerms) {
    state.paymentTerms = paymentTerms
  },

  SET_AUTOCOMPLETEPAYMENTTERMS (state, autocompletePaymentTerms) {
    autocompletePaymentTerms.forEach(pt => {
      // state.autocompletePaymentTerms.push(translateTerms(pt))
      pt = translateTerms(pt)
    })
    state.autocompletePaymentTerms = autocompletePaymentTerms
  },
  // Payload : bankAccount
  ADD_AUTOCOMPLETEPAYMENTTERM (state, payload) {
    state.autocompletePaymentTerms.push(payload)
  },
  // Payload : bankAccount
  UPDATE_AUTOCOMPLETEPAYMENTTERM (state, payload) {
    // console.log(payload)
    let index = state.autocompletePaymentTerms.findIndex(i => i.id === payload.paymentTerm.id)
    state.autocompletePaymentTerms[index].name = payload.paymentTerm.name
  },
}