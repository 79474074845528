import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import { capitalize } from '@/utils/filter'
import Vue from 'vue'
import api from '@/libs/axios'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import useAppConfig from '../../utils/useAppConfig'
import store from '../index'
import { getUserData } from '../../utils/utils'
import useAPI from '../../utils/useAPI'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getAutocompleteUsers ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/users'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEUSERS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(response.results)
        })
    })

  },
  getAutocompleteEmployees ({ commit, state }) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/users'

      performApiCall(url, {
        searchCriteria: [{
          column_name: 'invitationCode',
          comparator: 'notNull'
        }, {
          column_name: 'roles',
          comparator: '!=',
          search_text: '[]',
        }, {
          column_name: 'password',
          comparator: 'notNull'
        },]
      })
        .then(response => {
          commit('SET_AUTOCOMPLETEEMPLOYEES', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },
  getUsers ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/users'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          // addOrUpdateInList(response.data, state.users, 'user', commit)
          commit('SET_USERS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit

            recursiveCall(url, 'user', limit, offset, state.users, commit, 1).then(() => {

              // return new Promise((resolve, reject) => {
              store.dispatch('user/getUser', getUserData().id).then(() => {
                store.commit('app/REMOVE_LOADER')
                resolve(true)
              })

            })
          } else {
            // store.dispatch('user/getUser', getUserData().id)
            // store.commit('app/REMOVE_LOADER')
            // resolve(true)

            store.dispatch('user/getUser', getUserData().id).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getUser ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/users/' + userId)
        .then(response => {
          addOrUpdateInList(response.data, state.users, 'user', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addUser ({ commit, state }, user) {
    if (!('roles' in user)) {
      user.roles = []
    }

    return await cleanEntityToSend(user, state.params)
      .then(clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/users', clonedEntityToSend)
            .then(response => {
              commit('ADD_USER', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The individual has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateUser ({ commit, state }, user) {
    return await cleanEntityToSend(user, state.params)
      .then(clonedEntityToSend => {
        console.log(clonedEntityToSend)

        return new Promise((resolve, reject) => {
          api.put('/users/' + user.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_USER', response.data)

              let userData = getUserData()
              userData.mailSignature = response.data.mailSignature
              localStorage.setItem('userData', JSON.stringify(userData))

              if (user.id == parseInt(JSON.parse(localStorage.getItem('userData')).id)) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: capitalize(i18n.t('success')),
                    icon: 'check',
                    text: i18n.t('The employee has been ', { crud: i18n.t('edited') }),
                    variant: 'success'
                  },
                })
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: capitalize(i18n.t('success')),
                    icon: 'check',
                    text: i18n.t('The individual has been ', { crud: i18n.t('edited') }),
                    variant: 'success'
                  },
                })
              }

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeUser ({ commit }, user) {
    return new Promise((resolve, reject) => {
      api.delete('/users/' + user.id)
        .then(response => {
          commit('REMOVE_USER', user)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The user has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  async addEmployee ({ commit, state }, employee) {
    if (!('roles' in employee)) {
      employee.roles = []
    }
    return new Promise((resolve, reject) => {
      api.post('/register', employee)
        .then(response => {
          commit('ADD_USER', response.data)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The employee has been ', { crud: i18n.t('created') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })

  },

}