import api from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import moment from 'moment'
import { cleanEntityToSend } from '../../utils/form'
import { eventToCalendarEvent } from '../../utils/utils'

export default {
  async recognizeDocument ({ commit, state }, [document, type = null]) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: capitalize(i18n.t('recognition')),
        icon: 'search',
        text: capitalize(i18n.t('recognition in progress')),
        variant: 'warning'
      },
    })

    return new Promise((resolve, reject) => {
      // console.log(document)
      api.post('/documents/' + document.id + '/recognize', { type: type })
        .then(response => {
          // console.log(response)

          if (type == 'incomingInvoice') {

            if (typeof response.data == 'object' && !Array.isArray(response.data)) {
              if ('paymentMethod' in response.data && response.data.paymentMethod != null) {
                response.data.paymentMethod.name = i18n.t(response.data.paymentMethod.name)
              }

            } else {
              response.data.forEach(item => {
                if ('paymentMethod' in item) {
                  item.paymentMethod.name = i18n.t(item.paymentMethod.name)
                }
              })
            }

          } else if (type == 'bankStatement') {
            // response.data.bankOperations.forEach(item => {
            //   if (item.paymentMethod != null) {
            //     item.paymentMethod.name = i18n.t(item.paymentMethod.name)
            //   }
            // })
          }

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('recognition')),
              icon: 'check',
              text: capitalize(i18n.t('recognition completed')),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          // console.log('Error', error)
          // console.log('Error', error.response)
          // console.log('Error', error.response.data)
          // console.log('Error', error.response.status)

          if (type == 'bankStatement' && error.response.status == 409) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('warning')),
                icon: 'search',
                text: i18n.t('The bankStatement already exist'),
                variant: 'danger'
              },
            })

            resolve(error.response.data)

          } else {
            reject(error)
          }

        })
    })
  },
  generateExcelDetailRevenue ({ commit, state }, monthString) {
    api.post('/indicators/excelDetailRevenue', { month: monthString + '-01' })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.download = moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  generateLiaisonForm ({ commit, state }, payload) {
    let monthString = payload.date
    let type = payload.type

    if (type == 'pdf') {
      api.post('/indicators/pdfLiaisonForm', { month: monthString + '-01' })
        .then(response => {

          let link = document.createElement('a')
          link.href = response.data
          link.download = moment(monthString + '01').format('MMMM YYYY')
          link.click()
        })
        .catch(error => {
          console.log('Error', error)
        })
    } else {
      api.post('/indicators/excelLiaisonForm', { month: monthString + '-01' })
        .then(response => {

          let link = document.createElement('a')
          link.href = response.data
          link.download = moment(monthString + '01').format('MMMM YYYY')
          link.click()
        })
        .catch(error => {
          console.log('Error', error)
        })
    }

  },
  generatePdfDetailPurchases ({ commit, state }, monthString) {
    api.post('/indicators/pdfDetailPurchases', { month: monthString + '-01', awaitingExport: true })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.target = '_blank'
        link.download = capitalize(i18n.t('purchases:')) + ' ' + moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  generatePdfDetailSales ({ commit, state }, monthString) {
    api.post('/indicators/pdfDetailSales', { month: monthString + '-01', awaitingExport: true })
      .then(response => {

        let link = document.createElement('a')
        link.href = response.data
        link.target = '_blank'
        link.download = capitalize(i18n.t('sales:')) + ' ' + moment(monthString + '01').format('MMMM YYYY')
        link.click()
      })
      .catch(error => {
        console.log('Error', error)
      })
  },
  async duplicateDocument ({ commit, state }, document) {

    return new Promise((resolve, reject) => {
      api.post('/documents/' + document.id + '/duplicate')
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })

  },

}