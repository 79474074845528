import { clone } from '../../utils/utils'

export default {
  getAutocompleteDepartments: (state) => state.autocompleteDepartments,
  getAvailableFilters: (state) => state.availableFilters,
  getAvailableListColumns: (state) => state.availableListColumns,
  getDepartments: (state) => state.departments,
  getHierarchicalDepartments: (state) => state.hierarchicalDepartments,
  getDepartmentsContext: (state) => state.departmentsContext,
  getDepartment: (state) => (id) => {
    return state.departments.find(department => department.id === parseInt(id))
  },
  getEmptyDepartment: (state) => clone(state.emptyDepartment),
}