import i18n   from '../../libs/i18n'
import moment from 'moment'

const translateTerms = (email) => {
  // if ('emailMethod' in email) {
  //   email.emailMethod.name = i18n.t(email.emailMethod.name)
  // }
}

export default {
  SET_EMAILS (state, emails) {
    state.emails = emails
  },
  SET_ISKANBANEMAILLIST (state, value) {
    state.isKanbanEmailList = value
  },
  ADD_EMAIL (state, email) {
    // state.emails.push(email)
    // setUtc(email)
    state.emails.unshift(email)
  },
  ADD_EMAILS (state, emails) {
    state.emails = state.emails.concat(emails)
  },
  UPDATE_EMAIL (state, email) {
    translateTerms(email)
    // console.log(JSON.parse(JSON.stringify(email.events[0])))
    // setUtc(email)
    // console.log(JSON.parse(JSON.stringify(email.events[0])))

    let index = state.emails.findIndex(i => i.id === email.id)
    state.emails.splice(index, 1, email)
  },
  UPDATE_EMAIL_KANBAN (state, email) {
    translateTerms(email)
    // setUtc(email)

    state.emails.forEach((column, columnIndex) => {
      let columnCardIndex = column.items.findIndex(i => i.id == email.id)

      if (columnCardIndex != -1) {
        state.emails[columnIndex].items.splice(columnCardIndex, 1, email)
      }
    })
  },
  REMOVE_EMAIL (state, email) {
    state.emails = state.emails.filter(item => item.id != email.id)

    // let index = state.emails.findIndex(i => i.id === email.id)
    // state.emails.splice(index, 1)
  },
  SET_EMAILSCONTEXT (state, emailsContext) {
    // console.log("ok", emailsContext.fields_filters)
    state.emailsContext = emailsContext

    if (!state.fieldFilters.length) {
      emailsContext.fields_filters.forEach(ff => {
        state.fieldFilters.push({
          name: ff.name,
          type: ff.type,
          entity: ff.relation ? ff.relation : null,
          filter: {}
        })
      })
    }

  },
  UPDATE_EMAILSCONTEXTTOTALRESULT (state, isAddEmail) {
    if (isAddEmail) {
      state.emailsContext.total_results++
    } else {
      state.emailsContext.total_results--
    }
  },
  SET_AUTOCOMPLETEEMAILS (state, autocompleteEmails) {
    state.autocompleteEmails = autocompleteEmails
  },
}