import state from './templateState';
import mutations from './templateMutations';
import actions from './templateActions';
import getters from "./templateGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}