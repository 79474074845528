import state from './articleState';
import mutations from './articleMutations';
import actions from './articleActions';
import getters from "./articleGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}