import { computed } from '@vue/composition-api'
import store from '@/store'
import api from '@/libs/axios'
import { addOrUpdateInList} from './form'

export default function useAppConfig () {
  // ------------------------------------------------
  // isNavMenuHidden
  // ------------------------------------------------
  const isNavMenuHidden = computed({
    get: () => store.state.app.isNavMenuHidden,
    set: val => {
      store.commit('app/UPDATE_NAV_MENU_HIDDEN', val)
    },
  })

  const appName = store.state.app.appName

  const recursiveCallIterativeLimit = 500

  const recursiveCall = (url, type, limit, offset, itemsState, commit, index, eventType=false) => {
    let params = new URLSearchParams({ limit: limit, offset: offset, type: eventType })
    return new Promise((resolve, reject) => {
      api.get(url+'?' + params)
        .then(response => {

          response.data.forEach((user, key) => {
            // If next limit+2 > initial limit : no use first limit result
            if (index == 1) {
              if (key >= (offset)) {
                commit('ADD_'+type.toUpperCase(), user)
                // addOrUpdateInList([user], itemsState, type, commit)
              }
            } else {
              commit('ADD_'+type.toUpperCase(), user)
              // addOrUpdateInList([user], itemsState, type, commit)
            }
          })

          if (response.data.length == limit) {
            index++
            offset += limit
            recursiveCall(url, type, limit, offset, itemsState, commit, eventType).then(() => {
              resolve(true)
            })
          } else {
            resolve(true)
          }
        })
    })
  }

  return {
    isNavMenuHidden,
    appName,
    recursiveCallIterativeLimit,
    recursiveCall,
  }
}
