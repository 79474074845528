import state from './accountingLineState';
import mutations from './accountingLineMutations';
import actions from './accountingLineActions';
import getters from "./accountingLineGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}