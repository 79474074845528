import state from './moduleViewState';
import mutations from './moduleViewMutations';
import actions from './moduleViewActions';
import getters from "./moduleViewGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}