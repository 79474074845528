import { capitalize } from '../../utils/filter'
import i18n           from '../../libs/i18n'

export default {
  autocompleteAccountingLines: [],
  availableFilters: [
    {
      field: 'createdAt',
      i18n: capitalize(i18n.t('createdAt')),
      subFields: [],
      type: 'date',
    },
    {
      field: 'createdBy',
      i18n: capitalize(i18n.t('createdBy')),
      subFields: [],
      type: 'employee',
    },
  ].sort((a, b) => a.i18n < b.i18n ? -1 : 1),
  availableListColumns: [
    {
      title: capitalize(i18n.t('createdBy')),
      translateTitle: true,
      key: '_createdBy',
      sortable: true,
      cell: {
        center: false,
        useDisplay: true,
        isDate: false,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },
    {
      title: capitalize(i18n.t('createdAt')),
      translateTitle: true,
      key: '_createdAt',
      sortable: true,
      cell: {
        center: false,
        useDisplay: false,
        isDate: true,
        isDateTime: false,
        translate: false,
        isCurrency: false
      }
    },

  ],
  accountingLines: [],
  hierarchicalAccountingLines: [],
  isKanbanWorkFloList: false,
  accountingLinesContext: {
    page: 1,
    filters: []
  },
  emptyAccountingLine: {},
  params: {
    entity: [],
    collection: []
  }
}