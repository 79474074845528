import { cleanEntityToSend } from '../../utils/form'
import { capitalize } from '@/utils/filter'

import Vue from 'vue'
import api from '@/libs/axios'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '../index'
import useAPI from '../../utils/useAPI'
import qs from 'qs'
import { useMS }                      from '../../utils/useMS'
import { getUserData, isEmptyObject } from '../../utils/utils'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getUsers ({ commit, state }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      if (payload.kanbanSortBy == null) {
        commit('SET_ISKANBANUSERLIST', false)
      } else {
        commit('SET_ISKANBANUSERLIST', true)
      }

      api.get('/users2' + '?' + qs.stringify(payload))
        .then(response => {
          // console.log(response)
          commit('SET_USERS', response.data.results)
          commit('SET_USERSCONTEXT', response.data.page_context)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getUser ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId

      performApiCall(url, userId)
        .then(response => {
          if (state.isKanbanUserList != true) {
            store.commit('user2/UPDATE_USER', response.data)
          }

          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getUserMails ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()
    const { getTokenPopup } = useMS()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId + '/mails'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
        .catch(error => {
          getTokenPopup().then(response => {
            localStorage.setItem('msAccessToken', response.accessToken)
            store.commit('app/REMOVE_LOADER')

            store.dispatch('user/getUserMails', userId)
          })
        })
    })
  },
  getUserLastAnnexations ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId + '/lastAnnexations'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getUserLastPlaces ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId + '/lastPlaces'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getUserLastTrips ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId + '/lastTrips'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  getUserReminders ({ commit, state }, userId) {
    store.commit('app/ADD_LOADER')
    const { performApiCall } = useAPI()

    return new Promise((resolve, reject) => {
      let url = '/users/' + userId + '/reminders'

      performApiCall(url, {})
        .then(response => {
          store.commit('app/REMOVE_LOADER')
          resolve(response)
        })
    })
  },
  async addUser ({ commit, state }, user) {
    return await cleanEntityToSend(user, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/users', clonedEntityToSend)
            .then(response => {
              if (state.isKanbanUserList != true) {
                commit('ADD_USER', response.data)
              } else {
                let columnIndex = state.users.findIndex(c => c.title == response.data.status.name)

                state.users[columnIndex].items.unshift(response.data)
                state.users.splice(state.users.length, 0) // Reactive update the array
              }
              commit('UPDATE_USERSCONTEXTTOTALRESULT', 'add')

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The user has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateUser ({ commit, state }, user) {
    delete user.amount
    return await cleanEntityToSend(user, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/users/' + user.id, clonedEntityToSend)
            .then(response => {
              // console.log(state.isKanbanUserList)
              if (state.isKanbanUserList != true) {
                commit('UPDATE_USER', response.data)
              }

              let userData = getUserData()
              userData.mailSignature = response.data.mailSignature
              userData.roles = response.data.roles
              localStorage.setItem("userData", JSON.stringify(userData))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The user has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeUser ({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      api.delete('/users/' + user.id)
        .then(response => {
          if (state.isKanbanUserList != true) {
            commit('REMOVE_USER', user)
          }
          commit('UPDATE_USERSCONTEXTTOTALRESULT', 'sub')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The user has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteUsers ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      // if (payload.kanbanSortBy == null) {
      //   commit('SET_ISKANBANUSERLIST', false)
      // } else {
      //   commit('SET_ISKANBANUSERLIST', true)
      // }

      api.get('/autocomplete/users' + '?' + qs.stringify(payload))
        .then(response => {
          commit('SET_AUTOCOMPLETEUSERS', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })

  },
  getAutocompleteEmployees ({ commit, state }) {
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      api.get('/autocomplete/users' + '?' + qs.stringify({
        filters: [
          {
            sectionLogicOperator: "AND",
            sectionFilters: [
              {
                logicOperator: "AND",
                field: "invitationCode",
                operator: "is_not_empty",
                value: null
              },
              {
                logicOperator: "AND",
                field: "password",
                operator: "is_not_empty",
                value: null
              },
              {
                logicOperator: "AND",
                field: "roles",
                operator: "not_equal",
                value: "[]"
              },
            ]
          }
        ]
      }))
        .then(response => {
          // console.log(response)
          commit('SET_AUTOCOMPLETEEMPLOYEES', response.data.results)
          store.commit('app/TOGGLE_LOADING', false)

          resolve(response.data)
        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  },
  async sendUserMail ({ commit, state }, mail) {
    let mailLocal = JSON.parse(JSON.stringify(mail))
    if (mailLocal.attachments) {
      mailLocal.attachments.forEach(a => delete a._entity)
    }

    return new Promise((resolve, reject) => {
      api.post('/users/mail', mailLocal)
        .then(response => {
          // console.log(response)

          if (response.status == 201) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('success')),
                icon: 'check',
                text: i18n.t('The email has been sent'),
                variant: 'success'
              },
            })
          }
          resolve(response)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('error')),
              icon: 'times',
              text: i18n.t('The message could not be sent'),
              variant: 'danger'
            },
          })

          // console.log(error.response)

          resolve(error.response)
        })
    })
  },
}